import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { connectAdvanced } from 'react-redux'

import {
  getsupperList as getList
} from '../services/salereturnrefund';

  
export const getsupperList = createAsyncThunk<any, any, any>(
  'salere/post',
  async (params) => {
    return await getList(params)
  }
)

  
const salerefund = createSlice({
  name: 'salere',
  initialState: {
    salere: {}
  },
  reducers: {
  },
  
  extraReducers: {
    [getsupperList.fulfilled as any]: (state, action) => {
      state.salere = action.payload
    }
  }
})


const {
  reducer,
} = salerefund;

export default reducer