import fetch from './index'

export function getActivityList(params): any {
  return fetch.get('/activity/page', { params })
}

export function getActivityLists(params): any {
  return fetch.get('/activity/page', { params })
}

export function getActivityMiniList(params): any {
  return fetch.post('/activity/mini/page', params)
}

export function getActivityId(params): any {
  return fetch.get(`/activity/id`, { params })
}

export function addActivity(params) {
  return fetch.post(`/activity/private`, params)
}

export function addActivityOfficial(params) {
  return fetch.post(`/activity/system`, params)
}

export function stopActivity(id) {
  return fetch.post(`/activity/stop?activityId=${id}`, {})
}

export function editActivity(params) {
  return fetch.put(`/activity`, params)
}

export function deleteActivity(params) {
  return fetch.put(`/activity/status`, params)
}

export function getActivityDeailList(id): any {
  return fetch.get(`/activity/prize/list?activityId=${id}`)
}

export function addActivityPrize(params): any {
  return fetch.post(`/activity/prize`, params)
}

export function addActivityPrizeOfficial(params): any {
  return fetch.post(`/activity/prize/official`, params)
}



export function deleteActivityPrize(params): any {
  return fetch.delete(`/activity/prize`, { params })
}

export function editActivityPrize(params): any {
  return fetch.put(`/activity/prize`, params)
}

export function getActivityCount(): any {
  return fetch.get(`/activity/register/count`)
}

export function setPercentage(params): any {
  return fetch.post(`/activity/prize/consolation`, params)
}

export function getConsolation(params): any {
  return fetch.get(`/activity/prize/consolation`, { params })
}


export function getPercentage(id): any {
  return fetch.get(`/activity/prize/percentage?activityId=${id}`)
}

export function setActivityMax(params) {
  return fetch.post(`/export/records/analytics/output/detail`, params)
}

export function getActivityUser(params) {
  return fetch.get(`/activity/win/lottery/page`, { params })
}


export function getLotteryAward(params) {
  return fetch.post(`/activity/win/lottery/award`,  params)
}

export function getActivitySortList(params) {
  return fetch.get(`/activity/prize/sort/list`, { params })
}

export function setPriceSort(params) {
  return fetch.post(`/activity/prize/sort`,  params)
}

export function setParticipate(id) {
  return fetch.post(`/activity/participate?activityId=${id}`,  {})
}

export function setParticipatCancel(id) {
  return fetch.post(`/activity/participate/cancel?activityId=${id}`, {})
}


