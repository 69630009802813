import fetch from './index'

export function getEntryList(params): any {
  return fetch.post('/analytics/entry/detail', params)
}

export function getOutputList(params) {
  return fetch.post(`/analytics/output/detail`, params)
}

export function getEntryExport(params) {
  return fetch.post(`/export/records/analytics/entry/detail`, params)
}
export function exportEntryDetail(params) {
  return fetch.post(`/export/records/analytics/entry/detail/column`, params)
}

export function getOutputExport(params) {
  return fetch.post(`/export/records/analytics/output/detail`, params)
}
// 订单总表
export function getAnalyticsOutputInfo(params) {
  return fetch.post(`/analytics/output/info`, params)
}
// 订单总表导出
export function exportAnalyticsOutputInfo(params) {
  return fetch.post(`/export/records/analytics/output/info`, params)
}

