import fetch from './index'

// 所有部门信息
export function getAllDeparts(): any {
  return fetch.get('/departs/values')
}

export function getDepartsList(params): any {
  return fetch.get('/departs/list', { params })
}

export function delDepart(id) {
  return fetch.delete(`/departs/${id}`)
}

export function addDepart(params) {
  return fetch.post('/departs', params)
}

export function editDepart(params) {
  return fetch.put('/departs', params)
}