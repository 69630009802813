import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getAllDeparts as getAllDepartsAPI,
} from '../services/departs'

export const getAllDeparts = createAsyncThunk(
  'departs/values',
  async () => {
    const data = await getAllDepartsAPI()
    return data || []
  }
)

const departsSlice = createSlice({
  name: 'departs',
  initialState: {
    allDeparts: []
  },
  reducers: {
  },
  extraReducers: {
    [getAllDeparts.fulfilled as any]: (state, action) => {
      state.allDeparts = action.payload
    },
  }
})

const {
  reducer,
} = departsSlice;

export default reducer