import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getChooseCommodityList as getChooseCommodityListAPI
} from '../services/commodities'

export const getChooseCommodityList = createAsyncThunk<any, any, any>(
  'chooseCommodity/list',
  async (params) => {
    const data = await getChooseCommodityListAPI(params)
    return data || []
  }
)

const chooseCommoditySlice = createSlice({
  name: 'chooseCommodity',
  initialState: {
    chooseCommodities: []
  },
  reducers: {
  },
  extraReducers: {
    [getChooseCommodityList.fulfilled as any]: (state, action) => {
      state.chooseCommodities = action.payload
    },
    [getChooseCommodityList.rejected as any]: (state, action) => {
      state.chooseCommodities = []
    }
  }
})

const {
  reducer
} = chooseCommoditySlice

export default reducer