import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Menu,
} from 'antd'
import {
  useHistory,
  useLocation,
} from 'react-router-dom'
import classnames from 'classnames'

import * as icons from './icons'
import styles from './index.less'

import {
  getMenu,
} from '@/reducers/menu'
import {
  setHistory
} from '@/services/menu'
import {
  exactStrictMatchPath, globalLoading,
} from '@/utils'

function LayoutMenu() {
  const [selectedKeys, setSelectedKeys] = useState([])
  const menus = useSelector<any, any[]>(state => state?.menu?.menus)
  const users = useSelector<any, any>(state => state?.user.userInfo?.trialFlag || false)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    dispatch(getMenu())
  }, [])


  useEffect(() => {
    const menu = menus?.find?.(v => v?.children?.some(v => exactStrictMatchPath(location?.pathname, v?.path)))
    const subMenu = menu?.children?.find?.(v => exactStrictMatchPath(location?.pathname, v?.path))
    setSelectedKeys([menu?.key, subMenu?.key])
  }, [location, menus])

  const handleVisit = globalLoading.prevent(function(parent, page) {
    return () => {
      if (selectedKeys?.[1] === page.key) return 
      history.push(page.link)
      if(users) {
        setHistory([{
          mainMenu: parent.title,
          subMenu: page.title
        }])
      }
    }
  })
  // function handleVisit(parent, page) {
  //   return () => {
  //     if (selectedKeys?.[1] === page.key) return 
  //     history.push(page.link)
  //     if(users) {
  //       setHistory([{
  //         mainMenu: parent.title,
  //         subMenu: page.title
  //       }])
  //     }
  //   }
  // }


  return (
    <Menu selectedKeys={selectedKeys} triggerSubMenuAction="click">
      {
        (menus as any[]).map((menu, index) => {
          const Icon = icons[menu?.icon]
          return (
            <Menu.SubMenu key={menu.key} popupOffset={[4, -10]} title={(
              <div className={classnames(styles.menu, 'menu')}>
                <div className={styles.menuInner}>
                { Icon ? <Icon className={classnames(styles.icon, (menu.icon == 'SETTING' || menu.icon == 'OTHER') ? styles.specialIcon : '', menu.icon == 'RENT_MANGE' ? styles.largeIcon : '')} /> : null }
                  <p className={classnames(styles.name, menu.icon == 'RENT_MANGE' ? styles.largeIconName : '')}>{menu.title}</p>
                </div>
              </div>
            )}>
              {
                menu?.children?.map(child => (
                  !child?.children ? <Menu.Item onClick={handleVisit(menu, child)} className={styles.menuItem} key={child?.key}>
                    {child?.title}
                  </Menu.Item> :  <Menu.SubMenu key={child?.key} title={(
                    <div className={styles.menuItems} >
                      <p>{child?.title}</p>
                    </div>
                  )}>
                    {
                       child?.children?.map(key => (
                        <Menu.Item onClick={handleVisit(menu, key)} className={styles.menuItem} key={key.key}>
                        {key.title}
                      </Menu.Item>
                       ))
                    }
                  </Menu.SubMenu>
                ))
              }
            </Menu.SubMenu>
          )
        })
      }
    </Menu>
  )
}

export default LayoutMenu
