import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { connectAdvanced } from 'react-redux'

import {
  getentriesList as getList,
  getentriesdetailList as getdetailList
} from '../services/entrymanage'

  
export const getentriesList = createAsyncThunk<any, any, any>(
  'entry/post',
  async (params, ) => {
    return await getList(params)
  }
)

export const getentriesdetailList = createAsyncThunk<any, any, any>(
  'designs/get',
  async (params) => {
    return await getdetailList(params)
  }
)

  
const entries = createSlice({
  name: 'entry',
  initialState: {
    entries: {},
    stock: {},
    category: []
  },
  reducers: {
    setCategory(state, action) {
      state.category = action.payload
    },
    setDetailValue(state, action) {
      state.stock = action.payload
    }
  },
  
  extraReducers: {
    [getentriesList.fulfilled as any]: (state, action) => {
      state.entries = action.payload
    },
    [getentriesdetailList.fulfilled as any]: (state, action) => {
      state.stock = action.payload
    },
  }
})

const {
  actions,
  reducer
} = entries;

export const {
  setCategory,
  setDetailValue
} = actions

export default reducer