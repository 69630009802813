import {
  createSlice,
} from '@reduxjs/toolkit'

const preOrderSlice = createSlice({
  name: 'preOrder',
  initialState: {
    order: {},
  },
  reducers: {
    setOrder(state, action) {
      state.order = action.payload
    },
  }
})

const {
  actions,
  reducer,
} = preOrderSlice;

export const {
  setOrder,
} = actions

export default reducer