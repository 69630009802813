import fetch from './index'
import { zbHerfs } from '@/utils';
// 珠宝饰品  列表查询
export function getSellerList(params): any {
  return fetch.post('/trade/orders/choose/seller/list', params)
}

// 商户 主订单取消
export function setOrderCacel(params): any {
  return fetch.post(`/trade/orders/choose/cancel/${params.id}?operatorType=${params.type}`, {})
}

// 商户 订单删除
export function setOrderDelete(params): any {
  return fetch.delete(`/trade/orders/${params.orderNo}/${params.type}`, {})
}

// 商户 子订单取消
export function setSubOrderCacel(params): any {
  return fetch.post(`/trade/orders/choose/detail/cancel/${params.id}?operatorType=${params.type}`, {})
}

// 商户 主订单取消 退款
export function setOrderCancelRefund(id): any {
  return fetch.post(`/trade/refunds/choose/supplier/submit/main/${id}`, {})
}

// 商户 子订单取消 退款
export function setSubOrderCancelRefund(id): any {
  return fetch.post(`/trade/refunds/choose/supplier/submit/detail/${id}`, {})
}

// 商户 确认收款
export function setOrderPayments(id): any {
  return fetch.post(`/trade/payments/choose/confirm/${id}`, {})
}

// 商户 我选择的地址
export function getOrderAddress(id): any {
  return fetch.get(`/trade/orders/choose/supplier/delivery/addresses/${id}`, {})
}

// 商户 提交收货地址
export function setOrderAddress(params): any {
  return fetch.post(`/trade/orders/choose/supplier/delivery/addresses`, params)
}


// 商户 子订单提交退款
export function setSubOrderRefund(id): any {
  return fetch.post(`/trade/refunds/choose/seller/submit/${id}`, {})
}

// 商户 同意/拒绝 退款
export function setOrderRefund(params): any {
  return fetch.put(`/trade/refunds/choose/seller/audit/${params.detailOrderNo}?auditStatus=${params.id}`)
}

// 商户 商户发货到用户
export function setOrderLogistics(params): any {
  return fetch.post(`/trade/logistics`, params)
}

// 商户 查看物流信息
export function getOrderLogistics(params): any {
  return fetch.post(`/trade/logistics/info`, params)
}

// 商户 子订单提交退货
export function setReturnsGoods(id): any {
  return fetch.post(`/trade/returns/choose/seller/submit/${id}`, {})
}

// 商户 同意/拒绝 退货
export function setOrderGoods(params): any {
  return fetch.put(`/trade/returns/choose/seller/audit/${params.detailOrderNo}?auditStatus=${params.id}`)
}

// 商户 确认回货
export function setReturnGoods(id): any {
  return fetch.post(`/trade/returns/choose/seller/confirm/storage/records/${id}`, {})
}

// 商户 取消退货
export function setGoodsCancel(id): any {
  return fetch.put(`/trade/returns/choose/seller/cancel/${id}`)
}



// 珠宝饰品  创建出库单
export function getOutputsList(params): any {
  return fetch.get('/storage/outputs/online', {params})
}

export function getOutList(params): any {
  return fetch.post('/storage/outputs/online', params)
}

// 珠宝饰品  创建销售退货
export function getReturnsList(detailOrderNo): any {
  return fetch.post(`/orders/returns/online/${detailOrderNo}`, {})
}


// GIA订单管理  列表查询
export function getGiaList(params): any {
  return fetch.post('/trade/orders/gia/list', params)
}

// GIA订单管理  查看物流
export function getGiaLogticts(id): any {
  return fetch.post(`/trade/logistics/back/${id}`, {})
}



// 失效操作
export function setOrder(id): any {
  return fetch.post(`/trade/orders/choose/invalidation/${id}?rollbackInventory`, {})
}

// 子单分页
export function setOrderSubPagination(params): any {
  return fetch.get(`/trade/orders/back/details?nextCursor=${params.current}&orderNo=${params.orderNo}&pageSize=${params.pageSize}&tradeStyle=${params.tradeStyle}&operatorType=${params.type}`)
}

// 
export function getOrderCommidityId(id): any {
  return fetch.get(`/storage/outputs/online/mapping?detailOrderNo=${id}`)
}

// 选品数据 商品维度列表
export function getChooseList(params): any {
  return fetch.post(`/choose/commodity/list/analytics`, params)
}

//  商品维度详情  
export function getChooseDetail(params): any {
  return fetch.get(`/choose/commodity/list/analytics/detail`, {params})
}

// 获取选品信息传递给壹佰选品
export function getChooseCommodityInfo(id): any {
  return fetch.get(`/choose/commodity/seller/${id}`)
}

// 添加选品到壹佰商品
export function postChooseCommodityToZb100(params): any {
  return fetch.post('/rent/choose', params, {
    baseURL: zbHerfs
  })
}

// 珠宝饰品订单导出
export function exportStoneOrderList(params): any {
  return fetch.post('/export/records/trade/orders/seller/gem', params)
}

// GIA订单导出
export function exportGiaOrderList(params): any {
  return fetch.post('/export/records/trade/orders/seller/gia', params)
}

// 重新打开
export function setOpenOrder(orderNo): any {
  return fetch.post(`/trade/orders/open/${orderNo}`, {})
}

// 线上支付选品商品全单退款(彩宝)
export function postMainRefund(mainOrderNo): any {
  return fetch.post(`/trade/orders/choose/main/refund/${mainOrderNo}`, {})
}

// 线上支付选品商品子单退款(彩宝)
export function postDetailRefund(detailOrderNo): any {
  return fetch.post(`/trade/orders/choose/detail/refund/${detailOrderNo}`, {})
}

// 线上支付支持商户退款未订货的子订单(gia)
export function postGiaDetailRefund(detailOrderNo): any {
  return fetch.post(`/trade/refunds/gia/detail/refund/${detailOrderNo}`, {})
}

export function editOrderPrice(params): any {
  return fetch.put('/trade/orders/update/price', params)
}

export function getOrderRebate(params): any {
  return fetch.get(`/trade/order/rebate/main/list`, { params })
}

// 子订单(gia) 审核退款
export function putSellerAudit(detailOrderNo, auditStatus): any {
  return fetch.put(`/trade/refunds/choose/seller/audit/${detailOrderNo}?auditStatus=${auditStatus}`, {})
}
