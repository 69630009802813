import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getStaffs as getStaffsAPI,
} from '../services/staffs'

import {
  getAllChannelsList
} from '../services/channels'

export const getStaffs = createAsyncThunk<any, any, any>(
  'staffs/list',
  async (params) => {
    const data = await getStaffsAPI(params)
    return data || []
  }
)

export const getChannelList = createAsyncThunk<any, any, any>(
  'staffs/channelList',
  async (params) => {
    const data = await getAllChannelsList(params);
    return data || []
  }
)

const staffSlice = createSlice({
  name: 'staff',
  initialState: {
    staffs: [],
    channelList: [], // 售出渠道管理
  },
  reducers: {
  },
  extraReducers: {
    [getStaffs.fulfilled as any]: (state, action) => {
      state.staffs = action.payload
    },
    [getStaffs.rejected as any]: (state, action) => {
      state.staffs = []
    },
    [getChannelList.fulfilled as any]: (state, action) => {
      state.channelList = action.payload
    }
  }
})

const {
  reducer,
} = staffSlice;

export default reducer