import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getHistoryLog as getHistoryLogAPI,
} from '../services/historys'

export const getHistory = createAsyncThunk<any, any, any>(
  'history/list',
  async (params) => {
    const data = await getHistoryLogAPI(params)
    return data || []
  }
)

const historySlice = createSlice({
  name: 'history',
  initialState: {
    historyLog: []
  },
  reducers: {
  },
  extraReducers: {
    [getHistory.fulfilled as any]: (state, action) => {
      state.historyLog = action.payload
    },
    [getHistory.rejected as any]: (state, action) => {
      state.historyLog = []
    },
  }
})

const {
  reducer,
} = historySlice;

export default reducer