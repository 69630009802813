import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getDesignDashboard as getDesignDashboardAPI
} from '../services/dashboards'

export const getDesignDashboard = createAsyncThunk<any, any, any>(
  'designDashboard/list',
  async (params) => {
    const data = await getDesignDashboardAPI(params)
    return data || {}
  }
)

const designDashboardSlice = createSlice({
  name: 'designDashboard',
  initialState: {
    designDashboard: {}
  },
  reducers: {
  },
  extraReducers: {
    [getDesignDashboard.fulfilled as any]: (state, action) => {
      state.designDashboard = action.payload
    },
    [getDesignDashboard.rejected as any]: (state, action) => {
      state.designDashboard = {}
    }
  }
})

const {
  reducer
} = designDashboardSlice

export default reducer