import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getList as getTableList,
  getDetailList as getDetail
} from '../services/bill'

export const getList = createAsyncThunk<any, any, any>(
  'bill/list',
  async (params) => {
    const data = await getTableList(params)
    return data || []
  }
)

export const getDetailList = createAsyncThunk<any, any, any>(
  'detail/list',
  async (params) => {
    const data = await getDetail(params)
    return data || []
  }
)
const billList = createSlice({
  name: 'bill',
  initialState: {
    bill: [],
    detailData: {}
  },
  reducers: {
    setDetailbill(state, action) {
      state.bill = action.payload
    },
    setDetailValue(state, action) {
      state.detailData = action.payload
    }
  },
  extraReducers: {
    [getList.fulfilled as any]: (state, action) => {
      state.bill = action.payload
    },

    [getDetailList.fulfilled as any]: (state, action) => {
      state.detailData = action.payload
    }
  }
})

const {
  actions,
  reducer
} = billList


export const {
  setDetailbill,
  setDetailValue
} = actions

export default reducer
