import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getCabinetsList as getCabinetsListAPI
} from '../services/cabinets'

export const getCabinetsList = createAsyncThunk<any, any, any>(
  'cabinets/list',
  async (params) => {
    const data = await getCabinetsListAPI(params)
    return data || []
  }
)

const cabinetSlice = createSlice({
  name: 'cabinet',
  initialState: {
    cabinets: []
  },
  reducers: {
  },
  extraReducers: {
    [getCabinetsList.fulfilled as any]: (state, action) => {
      state.cabinets = action.payload
    },
    [getCabinetsList.rejected as any]: (state, action) => {
      state.cabinets = []
    }
  }
})

const {
  reducer
} = cabinetSlice

export default reducer