import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getFactoriesList as getFactoriesAPI
} from '../services/factories'

export const getFactoriesList = createAsyncThunk<any, any, any>(
  'factories/list',
  async (params) => {
    const data = await getFactoriesAPI(params)
    return data || []
  }
)

const factorySlice = createSlice({
  name: 'factory',
  initialState: {
    factories: []
  },
  reducers: {
  },
  extraReducers: {
    [getFactoriesList.fulfilled as any]: (state, action) => {
      state.factories = action.payload
    },
    [getFactoriesList.rejected as any]: (state, action) => {
      state.factories = []
    }
  }
})

const {
  reducer
} = factorySlice

export default reducer