import {
  createSlice,
} from '@reduxjs/toolkit'

const helpSlice = createSlice({
  name: 'help',
  initialState: {
    expand: false,
  },
  reducers: {
    changeExpand(state, action) {
      state.expand = action.payload
    },
  }
})

const {
  actions,
  reducer,
} = helpSlice;

export const {
  changeExpand,
} = actions

export default reducer