import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getOrderReturnDashboard as getOrderReturnDashboardAPI
} from '../services/dashboards'

export const getOrderReturnDashboard = createAsyncThunk<any, any, any>(
  'orderReturnDashboard/list',
  async (params) => {
    const data = await getOrderReturnDashboardAPI(params)
    return data || {}
  }
)

const orderReturnDashboardSlice = createSlice({
  name: 'orderReturnDashboard',
  initialState: {
    orderReturnDashboard: {}
  },
  reducers: {
  },
  extraReducers: {
    [getOrderReturnDashboard.fulfilled as any]: (state, action) => {
      state.orderReturnDashboard = action.payload
    },
    [getOrderReturnDashboard.rejected as any]: (state, action) => {
      state.orderReturnDashboard = {}
    }
  }
})

const {
  reducer
} = orderReturnDashboardSlice

export default reducer