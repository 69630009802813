import fetch from './index'

export function getKeywordsList(params): any {
  return fetch.get('/designs/keywords', { params })
}

export function delKeyword(id) {
  return fetch.delete(`/designs/keyword/${id}`)
}

export function addKeyword(params) {
  return fetch.post('/designs/keyword', params)
}

export function editKeyword(params) {
  return fetch.put('/designs/keyword', params)
}
export function setMergeKeyword(params) {
  return fetch.put(`/designs/merge/keyword?fromKeywordId=${params?.fromKeywordId}&toKeywordId=${params?.toKeywordId}&delete=${params?.delete}`)
}