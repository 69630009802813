import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getTagsList as getTagsAPI,
} from '../services/tags'

export const getTagsList = createAsyncThunk<any, any, any>(
  'tags/list',
  async (params) => {
    const data = await getTagsAPI(params)
    return data || []
  }
)

const tagSlice = createSlice({
  name: 'tag',
  initialState: {
    tags: []
  },
  reducers: {
  },
  extraReducers: {
    [getTagsList.fulfilled as any]: (state, action) => {
      state.tags = action.payload
    },
    [getTagsList.rejected as any]: (state, action) => {
      state.tags = []
    },
  }
})

const {
  reducer,
} = tagSlice;

export default reducer