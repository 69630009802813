import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getDepartsList as getDepartsAPI
} from '../services/departs'

export const getDepartsList = createAsyncThunk<any, any, any>(
  'departs/list',
  async (params) => {
    const data = await getDepartsAPI(params)
    return data || []
  }
)

const departSlice = createSlice({
  name: 'depart',
  initialState: {
    departs: []
  },
  reducers: {
  },
  extraReducers: {
    [getDepartsList.fulfilled as any]: (state, action) => {
      state.departs = action.payload
    },
    [getDepartsList.rejected as any]: (state, action) => {
      state.departs = []
    }
  }
})

const {
  reducer
} = departSlice

export default reducer