import React, { useEffect, useState } from 'react';
import {
  Switch,
  Route,
  useLocation
} from 'react-router-dom';
import loadable from "@loadable/component";
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import classnames from 'classnames'
import {
  Spin,
} from 'antd'

import PageErrorBoundary from '../PageErrorBoundary'
import PageVisible from '../PageVisible'
import PageNotFound from '../PageNotFound'
import HelpNotFound from '../HelpNotFound'
import HelpErrorBoundary from '../HelpErrorBoundary'
import HelpDefault from '../HelpDefault'
import Loading from '../Loading'
import RouteTransition from '../RouteTransition'

import PageContext from '@/context/PageContext'

import styles from './index.less'

import useHideHelp from '../../hooks/useHideHelp'
import {
  exactStrictMatchPath,
  toPascalCase,
} from '@/utils'
import {
  closeTab,
} from '@/reducers/tab'

const pages = PAGES.reduce((acc, key) => {
  acc[key] = loadable(() => import(`../../pages/${key}/main`), {
    fallback: <Loading size="large" />
  });
  return acc;
}, {})
const helps = PAGES.reduce((acc, key) => {
  acc[key] = loadable(() => import(`../../pages/${key}/help`), {
    fallback: <Loading size="default" />
  });
  return acc;
}, {})

function PageContainer() {
  const expand = useSelector<any>(state => state.help.expand)
  const tabs = useSelector<any, any[]>(state => state?.tab?.tabs)
  const routes = useSelector<any, any[]>(state => state?.route?.routes)
  const loading = useSelector<any, any>(state => state?.global?.loading)
  const isShow = useSelector<any, any[]>(state => state?.menu?.isShow)
  const location = useLocation()
  const hideHelp = useHideHelp()
  const dispatch = useDispatch()

  function isOpen(pageKey) {
    return tabs.some(v => v.key === pageKey && !exactStrictMatchPath(location.pathname, v.path))
  }

  function handleBackHome(pageKey) {
    return () => {
      dispatch(closeTab(pageKey))
    }
  }

  return routes.length ? (
    <div className={styles.container} style={{ paddingTop: isShow ? '69px' : '37px' }}>
      <div className={styles.pageContainer}>
        <div className={styles.page} id="pages" >
          {
            routes.map(route => {
              const Page = pages[route.component || toPascalCase(route.key)]
              const path = route.path
              const visible = exactStrictMatchPath(location.pathname, route.path)
              return (
                <PageContext.Provider key={route.key} value={{
                  visible: !!visible,
                  key: route.key,
                }}>
                  <Route path={isOpen(route.key) ? '*' : path}>
                    <PageVisible
                      visible={visible}
                    >
                      <PageErrorBoundary>
                        {
                          Page ? (
                            <Spin spinning={!!Object.keys(loading).length/* && !!route.showLoading*/} wrapperClassName={path == '/style_center' ? styles.spin : styles.spin}>
                              <RouteTransition
                                location={location}
                                visible={visible}
                              >
                                <Page
                                  title={route.title}
                                  path={route.path}
                                />
                              </RouteTransition>
                            </Spin>
                          ) : (
                            <PageNotFound onBackHome={handleBackHome(route.key)} />
                          )
                        }
                      </PageErrorBoundary>
                    </PageVisible>
                  </Route>
                </PageContext.Provider>
              )
            })
          }
          <Route path="*">
            <PageVisible
              visible={routes.every(route => !exactStrictMatchPath(location.pathname, route.path))}
            >
              <PageNotFound />
            </PageVisible>
          </Route>
        </div>
      </div>
      <Switch>
        {hideHelp}
        <Route path="*">
          <div className={classnames(styles.helpContainer, {
            [styles.expand]: expand
          })}>
            <Switch>
              {
                routes.map(route => {
                  const Help = helps[route.component || toPascalCase(route.key)]

                  return (
                    <Route exact key={route.key} path={route.path}>
                      <HelpErrorBoundary>
                        {
                          route.help === 'default' ? (
                            <HelpDefault />
                          ) : (
                            Help ? (
                              <Help />
                            ) : (
                              <HelpNotFound />
                            )
                          )
                        }
                      </HelpErrorBoundary>
                    </Route>
                  )
                })
              }
              <Route path="*">
                <HelpNotFound />
              </Route>
            </Switch>
          </div>
        </Route>
      </Switch>
    </div>
  ) : null
}

export default PageContainer
