import fetch from './index'

// 调拨单 条件 查询
export function getsupperList(params): any {
  return fetch.get('/eps/warehouses/transfer/list', {
    params
  })
}

// 调拨单 商品 查询
export function getCommodityList(params): any {
  return fetch.get('/eps/warehouses/transfer/commodities/list', {
    params
  })
}

// 调拨单 详情
export function getsupperDetail(params): any {
  return fetch.get('/eps/warehouses/transfer/details', {
    params
  })
}

// 接受或拒绝调拨
export function setChangeRecipient(params): any {
  return fetch.put(`/eps/warehouses/transfer/status?transferNo=${params?.transferNo}&status=${params?.status}`)
}
// 批量接收或拒绝调拨
export function batchReceiveTransfer(transferNoList, status): any {
  return fetch.put(`/eps/warehouses/transfer/status/batch?transferNoList=${transferNoList}&status=${status}`)
}

// 更改接收人
export function setReceiver(params): any {
  return fetch.put(`/eps/warehouses/transfer/receiver?transferNo=${params?.transferNo}&receiverId=${params?.receiverId}`)
}

// 条件调拨导出
export function getcommodityReturnorder(params): any {
  return fetch.post('/export/records/commodity/transfer', params)
}

// 商品调拨导出
export function gettransferReturnorder(params): any {
  return fetch.post('/export/records/commodity/transfer/commodity', params)
}

// 调拨详情导出
export function getSupperexport(params): any {
  return fetch.post(`/export/records/commodity/transfer/detail`, params)
}

export function createTransfer(params) {
  return fetch.post('/eps/warehouses/transfer', params)
}

export function editTransferRemark(epsId, remark, transferNo, isMainTransfer, operatorId) {
  return fetch.put(`/eps/warehouses/transfer/remark?epsId=${epsId}&remark=${remark}&transferNo=${transferNo}&isMainTransfer=${isMainTransfer}&operatorId=${operatorId}`)
}


