import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getWareDashboard as getWareDashboardAPI,
  getCommodityDashboard as getCommodityDashboardAPI
} from '../services/wareanalysis'

export const getWareDashboard = createAsyncThunk<any, any, any>(
  'wareDashboard/list',
  async (params) => {
    const data = await getWareDashboardAPI(params)
    return data || {}
  }
)

export const getCommodityDashboard = createAsyncThunk<any, any, any>(
  'commidityDashboard/list',
  async (params) => {
    const data = await getCommodityDashboardAPI(params)
    return data || {}
  }
)

const wareDashboardSlice = createSlice({
  name: 'wareDashboard',
  initialState: {
    wareDashboard: {},
    commidityboard: {}
  },
  reducers: {
  },
  extraReducers: {
    [getWareDashboard.fulfilled as any]: (state, action) => {
      state.wareDashboard = action.payload
    },
    [getWareDashboard.rejected as any]: (state, action) => {
      state.wareDashboard = {}
    },
    [getCommodityDashboard.fulfilled as any]: (state, action) => {
      state.commidityboard = action.payload
    },
    [getCommodityDashboard.rejected as any]: (state, action) => {
      state.commidityboard = {}
    }
  }
})

const {
  reducer
} = wareDashboardSlice

export default reducer