import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { connectAdvanced } from 'react-redux'

import {
  getReturnsList as getReturnsListAPI,
  getOutList as getOutListAPI
} from '../services/gemstoneorder'

  
export const getReturnsList = createAsyncThunk<any, any, any>(
  'list/post',
  async (params) => {
    return await getReturnsListAPI(params)
  }
)
export const getOutList = createAsyncThunk<any, any, any>(
  'list/post',
  async (params) => {
    return await getOutListAPI(params)
  }
)

  
const gemstores = createSlice({
  name: 'gemstore',
  initialState: {
    inventorydetail: {},
    outdetail: {}
  },
  reducers: {
    setDetailValue(state, action) {
      state.inventorydetail = action.payload
    },
    setOutValue(state, action) {
      state.outdetail = action.payload
    }
  },
  
  extraReducers: {
    [getReturnsList.fulfilled as any]: (state, action) => {
      state.inventorydetail = action.payload
    },
    [getOutList.fulfilled as any]: (state, action) => {
      state.outdetail = action.payload
    },
  }
})

const {
  actions,
  reducer
} = gemstores;


export const {
  setDetailValue,
  setOutValue
} = actions

export default reducer