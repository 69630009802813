import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getWarehousesList as getWarehousesListAPI
} from '../services/warehouses';

export const getWarehousesList = createAsyncThunk<any, any, any>(
  'warehouses/list',
  async (params) => {
    const data = await getWarehousesListAPI(params)
    return data || []
  }
)

const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState: {
    warehouses: {}
  },
  reducers: {
  },
  extraReducers: {
    [getWarehousesList.fulfilled as any]: (state, action) => {
      state.warehouses = action.payload
    },
    [getWarehousesList.rejected as any]: (state, action) => {
      state.warehouses = []
    }
  }
})

const {
  reducer
} = warehouseSlice

export default reducer