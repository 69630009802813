import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getFeatureValues as getFeatureValuesAPI
} from '../services/features'

export const getFeatureValues = createAsyncThunk<any, any, any>(
  'featureValues/list',
  async (params) => {
    const data = await getFeatureValuesAPI(params)
    return data || []
  }
)

const featureValueSlice = createSlice({
  name: 'featureValue',
  initialState: {
    featureValues: []
  },
  reducers: {
  },
  extraReducers: {
    [getFeatureValues.fulfilled as any]: (state, action) => {
      state.featureValues = action.payload
    },
    [getFeatureValues.rejected as any]: (state, action) => {
      state.featureValues = []
    }
  }
})

const {
  reducer
} = featureValueSlice

export default reducer