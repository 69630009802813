import {
  createSlice,
} from '@reduxjs/toolkit'

const globalSlice = createSlice({
  name: 'global',
  initialState: {
    loading: {},
  },
  reducers: {
    addLoading(state, action) {
      state.loading = {
        ...state.loading,
        [action.payload]: true,
      }
    },
    removeLoading(state, action) {
      if (action.payload) {
        const loading = state.loading
        delete loading[action.payload]
        state.loading = loading
      } else {
        state.loading = {}
      }

    }
  }
})

const {
  actions,
  reducer,
} = globalSlice;

export const {
  addLoading,
  removeLoading,
} = actions

export default reducer