import { createSlice } from '@reduxjs/toolkit'

const pageDatas = createSlice({
  name: 'pageData',
  initialState: {},
  reducers: {
    setVagueSearchData(state, action) {
      state[action.payload.dataKey] = action.payload.dataInfo
    },
  }
})

const {
  actions,
  reducer
} = pageDatas;

export const {
  setVagueSearchData
} = actions

export default reducer