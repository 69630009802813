import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getHomeImage as getHomeImageAPI,
} from '../services/welcomes'

export const getHomeImage = createAsyncThunk<any, any, any>(
  'welcome/list',
  async (params) => {
    const data = await getHomeImageAPI(params)
    return data || []
  }
)

const homeImageSlice = createSlice({
  name: 'homeImage',
  initialState: {
    homeImage: []
  },
  reducers: {
  },
  extraReducers: {
    [getHomeImage.fulfilled as any]: (state, action) => {
      state.homeImage = action.payload
    },
    [getHomeImage.rejected as any]: (state, action) => {
      state.homeImage = []
    },
  }
})

const {
  reducer,
} = homeImageSlice;

export default reducer