import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getDesignersList as getDesignersAPI,
} from '../services/designers'

export const getDesignersList = createAsyncThunk<any, any, any>(
  'designers/list',
  async (params) => {
    const data = await getDesignersAPI(params)
    return data || []
  }
)

const designerSlice = createSlice({
  name: 'designer',
  initialState: {
    designers: []
  },
  reducers: {
  },
  extraReducers: {
    [getDesignersList.fulfilled as any]: (state, action) => {
      state.designers = action.payload  
    },
    [getDesignersList.rejected as any]: (state, action) => {
      state.designers = []
    },
  }
})

const {
  reducer,
} = designerSlice;

export default reducer