import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

export const setBrowserUrl = createAsyncThunk<any, any, any>(
  'browserUrl',
  async (url) => {
    return url || []
  }
)

const browserUrlSlice = createSlice({
  name: 'browserUrl',
  initialState: {
    browserUrl: []
  },
  reducers: {
  },
  extraReducers: {
    [setBrowserUrl.fulfilled as any]: (state, action) => {
      state.browserUrl = action.payload
    },
    [setBrowserUrl.rejected as any]: (state, action) => {
      state.browserUrl = []
    }
  }
})

const {
  reducer
} = browserUrlSlice

export default reducer