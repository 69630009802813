import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getAwardScoreDetail as getAwardScoreDetailAPI,
} from '../services/awardscore'

export const getAwardScoreDetail = createAsyncThunk<any, any, any>(
  'scoreLog/list',
  async (params) => {
    const data = await getAwardScoreDetailAPI(params)
    return data || []
  }
)

const scoreLogSlice = createSlice({
  name: 'scoreLog',
  initialState: {
    scoreLog: []
  },
  reducers: {
  },
  extraReducers: {
    [getAwardScoreDetail.fulfilled as any]: (state, action) => {
      state.scoreLog = action.payload
    },
    [getAwardScoreDetail.rejected as any]: (state, action) => {
      state.scoreLog = []
    },
  }
})

const {
  reducer,
} = scoreLogSlice;

export default reducer