import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTagsValues } from "@/services/tags";

const NAMESPACE = "publicReducer";

const initialState = {
  tags: {}, // 不分页
  tagsLoading: false,
};

export const getTagsReducer = createAsyncThunk(
  `${NAMESPACE}/getTags`,
  //发出一个请求，这里用的是axios
  async (params: any, { getState }) => {
    const state: any = getState();
    if (!state.publicReducer.tagsLoading) {
      console.log(state.publicReducer.tagsLoading,"state.publicReducer.tagsLoading")
      const res = await getTagsValues(params);
      return {
        params: params,
        res: res,
      };
    } else {
      return null;
    }
  }
);

const publicReducerSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    tagsLoading(state, action) {
      state.tagsLoading = true;
    },
  },
  // 处理异步请求结果的reducer
  extraReducers: (builder) => {
    builder
      .addCase(getTagsReducer.fulfilled, (state, action) => {
        if(action.payload){
          const res = action.payload.res;
          const params = action.payload.params;
          state.tags[`${params.type}`] = res;
        }else{
          state.tagsLoading = false;
        }
      })
      .addCase(getTagsReducer.rejected, (state, err) => {
        state.tagsLoading = false;
        console.log("🚀 ~ rejected", err);
      });
  },
});

export const { actions, reducer } = publicReducerSlice;

export const { tagsLoading } = actions;

export default reducer;
