import fetch from './index'

export function getSuppliersList(params): any {
  return fetch.get('/eps/suppliers/list', { params })
}

export function delSupplier(id) {
  return fetch.delete(`/eps/suppliers/${id}`)
}

export function addSupplier(params) {
  return fetch.post('/eps/suppliers', params)
}

export function editSupplier(params) {
  return fetch.put('/eps/suppliers', params)
}
// 获取全部供应商
export function getAllSuppliersList(): any {
  return fetch.get('/eps/suppliers/v2/list')
}