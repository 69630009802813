import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getCategoriesList as getCategoriesListAPI,
  getSpecialsList as getSpecialsListAPI
} from '../services/categories'

export const getCategoriesList = createAsyncThunk<any, any, any>(
  'categories/list',
  async (params, { getState }) => {
    // const state: any = getState()
    // const cats = state?.category?.categories?.[params.parentCategoryId]

    // if (cats?.length) return {
    //   [params.parentCategoryId]: cats
    // }
    const data = await getCategoriesListAPI(params)
    return {
      [params.parentCategoryId]: data || []
    }
  }
)
export const getSpecialsList = createAsyncThunk<any, any, any>(
  'price/list',
  async (params, { getState }) => {
    
    const data = await getSpecialsListAPI(params)
    return data || []
  }
)

const categorySlice = createSlice({
  name: 'category',
  initialState: {
    categories: {},
    priceList: {}
  },
  reducers: {
  },
  extraReducers: {
    [getCategoriesList.fulfilled as any]: (state, action) => {
      state.categories = {
        ...state.categories,
        ...action.payload,
      }
    },
    [getSpecialsList.fulfilled as any]: (state, action) => {
      state.priceList = action.payload
    },
  }
})

const {
  reducer
} = categorySlice

export default reducer