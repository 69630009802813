import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getAdminList as getAdminListAPI,
  getOutList as getOutListAPI,
  getGroupList as getGroupListAPI
} from '../services/sellermanage';

export const getAdminList = createAsyncThunk<any, any, any>(
  'admin/list',
  async (params) => {
    const data = await getAdminListAPI(params)
    return data || []
  }
)

export const getOutList = createAsyncThunk<any, any, any>(
  'out/list',
  async (params) => {
    const data = await getOutListAPI(params)
    return data || []
  }
)

export const getGroupList = createAsyncThunk<any, any, any>(
  'group/list',
  async (params) => {
    const data = await getGroupListAPI({...params, parentGroup: 0})
    return data || []
  }
)


const seller = createSlice({
  name: 'seller',
  initialState: {
    list: {},
    outlist: {},
    grouplist: [],
    searchQuery: {},
  },
  reducers: {
    updateSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
  },
  extraReducers: {
    [getAdminList.fulfilled as any]: (state, action) => {
      state.list = action.payload
    },
    [getOutList.fulfilled as any]: (state, action) => {
      state.outlist = action.payload
    },
    [getGroupList.fulfilled as any]: (state, action) => {
      state.grouplist = action.payload
    }
  }
})

const {
  reducer,
  actions
} = seller

export { actions };

export default reducer