import React, { useEffect, useState } from 'react';
import {
  useHistory,
} from 'react-router-dom'
import classNames from 'classnames';
import {
  Input,
  Button,
  message,
  Form
} from 'antd'

import CaptchaButton from '@/components/CaptchaButton'
import styles from './index.less';

import {
  login,
} from '@/services/users'
import {
  set,
} from '@/utils/session'

enum LoginType {
  PASSWORD,
  CAPTCHA,
}

const loginTypes = [{
  type: LoginType.PASSWORD,
  name: '密码登录',
}, {
  type: LoginType.CAPTCHA,
  name: '免密登录'
}]

function Login() {
  const [phoneNum, setPhoneNum] = useState()
  const [captcha, setCaptcha] = useState()
  const [password, setPassword] = useState()
  const [loading, setLoading] = useState(false)
  const [tab, setTab] = useState<number>(LoginType.PASSWORD)
  const regmob = /^1[3-9]\d{9}$/;
  const regpd = /^[0-9A-Za-z]{8,16}$/;
  const regct = /^\d{4}$/;
  const [form] = Form.useForm()
  const history = useHistory()
  const [isMiner, setIsMiner] = useState(false)

  useEffect(() => {
    const logoDom = document.getElementById('logoLink')
    if ((window.location.origin || '').indexOf('https://admin.gemminerp.com') > -1) {
      setIsMiner(true)
      logoDom.setAttribute('href', 'https://img.zb100.com/erpify/gemMiner.png')
    } else {
      setIsMiner(false)
      logoDom.setAttribute('href', 'https://img.hellozm.com/zm/icon.svg')
    }
  }, [])

  function handlePhoneNumChange(e) {
    setPhoneNum(e.target.value.trim())
  }

  function handlePasswordChange(e) {
    setPassword(e.target.value.trim())
  }

  function handleCaptchaChange(e) {
    setCaptcha(e.target.value.trim())
  }

  function handleSubmit() {
    if (phoneNum && !regmob.test(phoneNum) ) {
      message.error('请检查输入的手机号码是否正确');
      return;
    }

    if(tab == LoginType.PASSWORD && password && !regpd.test(password)) {
      message.error('密码格式有误，请重新输入');
      return;
    }

    if (tab == LoginType.CAPTCHA && captcha && !regct.test(captcha)) {
      message.error('请检查输入的验证码是否正确');
      return;
    }

    if(!phoneNum && !password && !captcha){
      return false
    }
    setLoading(true)
    login({
      [tab == LoginType.PASSWORD ? 'password' : 'code']: tab == LoginType.PASSWORD ? password : captcha,
      mobilePhone: phoneNum,
      loginType: tab
    }).then(data => {
      setLoading(false)
      set(data)
      history.replace('/')
    }).catch(() => {
      setLoading(false)
    })
  }
  
  function handleChangeTab(id) {
    setTab(id)
    setPassword(null)
    setCaptcha(null)
  }

  return (
    <div className={styles.container}>
      <div className={classNames(styles.header, isMiner ? styles.minerHeader : '')}>
        {
          isMiner ? <img src="https://img.hellozm.com/erp/minerLogo.png" className={styles.minerLogo} alt="" />
          : <img src="https://img.zb100.com/erpify/logos.svg" className={styles.zmLogo} alt="" />
        }
      </div>
      <div className={classNames(styles.section, isMiner ? styles.minerSection : '')}>
        <div className={styles.sectionpage}>
          {
            isMiner ? <div className={styles.zmBanner} /> : <img className={styles.zmBanner} src="https://img.zb100.com/erpify/banner.png" alt="" />
          }
          <div className={styles.mask}>
            <div className={styles.login}>
              <p className={styles.title}>
                {
                  loginTypes.map(v => (
                    <span key={v.type} className={classNames(tab == v.type ? styles.active : '')} onClick={handleChangeTab.bind(this, v.type)}>{v.name}</span>
                  ))
                }
              </p>
              <Form 
                onFinish={handleSubmit}
                form={form} 
              >
                <Form.Item name="phoneNum" label="" className={styles.searchItem}>
                  <Input
                    className={classNames(styles.mobile, styles.input)}
                    placeholder="请输入手机号"
                    maxLength={11}
                    onChange={handlePhoneNumChange}
                    allowClear
                  />
                </Form.Item> 
                {
                    tab == LoginType.PASSWORD ? <Form.Item name="password" label="" className={styles.searchItem}>
                      <Input.Password
                        className={classNames(styles.mobile, styles.input)}
                        placeholder="请输入密码(8-16位, 英文、数字)"
                        maxLength={16}
                        onChange={handlePasswordChange}
                        allowClear
                      /> 
                   </Form.Item> : null 
                }
                {
                  tab != LoginType.PASSWORD ?   <Form.Item name="captcha" label="" className={styles.searchItem}>
                    <div className={styles.codeLine}>
                      <Input
                        className={classNames(styles.input, styles.code)}
                        placeholder="请输入验证码"
                        maxLength={4}
                        onChange={handleCaptchaChange}
                        suffix={<CaptchaButton phoneNum={phoneNum} className={styles.captchaBtn} />}
                      />
                    </div>
                </Form.Item> : null
                }
                <Form.Item>
                  <Button
                    loading={loading}
                    disabled={!phoneNum  || !(tab == LoginType.PASSWORD ? password : captcha)}
                    type="primary"
                    htmlType="submit"
                    className={styles.btnLogin}
                  >
                    登录
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
      {
        isMiner ? null : (
          <div className={styles.footer}>
            <div className={styles.footerpage}>
              <p>
                <span>技术支持</span>
                <a href="mailto:dev@hellozm.com">dev@hellozm.com</a>
              </p>
              <p>copyright © 2021 <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2021018544号-2</a></p>
            </div>
          </div>
        )
      }
      {
        isMiner ? (
          <div className={styles.minerFoot}>
            <img src="https://img.hellozm.com/erp/minerBg.JPG" className={styles.minerBanner} alt="" />
            <div className={classNames(styles.footer, styles.minerFooter)}>
              <div className={styles.footerpage}>
                <p>
                  <span>技术支持</span>
                  <span>0571- 88732056</span>
                </p>
                <p>copyright © 2021 <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021148472号</a></p>
              </div>
            </div>
          </div>
        ) : null
      }
    </div>
  )
}

export default Login