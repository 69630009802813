import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getSuppliersList as getSuppliersListAPI
} from '../services/suppliers'

export const getSuppliersList = createAsyncThunk<any, any, any>(
  'suppliers/list',
  async (params) => {
    const data = await getSuppliersListAPI(params)
    return data || []
  }
)

const supplierSlice = createSlice({
  name: 'supplier',
  initialState: {
    suppliers: []
  },
  reducers: {
  },
  extraReducers: {
    [getSuppliersList.fulfilled as any]: (state, action) => {
      state.suppliers = action.payload
    },
    [getSuppliersList.rejected as any]: (state, action) => {
      state.suppliers = []
    }
  }
})

const {
  reducer
} = supplierSlice

export default reducer