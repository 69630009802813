import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getCustomersList as getCustomersAPI
} from '../services/customers'

export const getCustomersList = createAsyncThunk<any, any, any>(
  'customers/list',
  async (params) => {
    const data = await getCustomersAPI(params)
    return data || []
  }
)

const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    customers: []
  },
  reducers: {
  },
  extraReducers: {
    [getCustomersList.fulfilled as any]: (state, action) => {
      state.customers = action.payload
    },
    [getCustomersList.rejected as any]: (state, action) => {
      state.customers = []
    }
  }
})

const {
  reducer
} = customerSlice

export default reducer