import fetch from './index'

// 销售退货退款查询
export function getsupperList(params): any {
  return fetch.post('orders/returns/page/refund/list', params)
}

// 主单 导出
export function getmasterReturnorder(params): any {
  return fetch.post('/export/records/orders/returns/refunds', params )
}


