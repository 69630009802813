/**
 * 运营 选品 接口
 */

import fetch from './index'

// 根据父品类id查询品类列表
export function getCategoriesList(params): any {
  return fetch.get('/choose/categories/list', {params})
}

// 新增或更新 选品品类
export function addCategory(params): any {
  return fetch.post('/choose/categories', params)
}

// 获取选品属性名称列表
export function getCategoryFeatures(params): any {
  return fetch.get('/choose/feature/name/value', { params })
}

//分页查询属性值列表
export function getPageFeatures(params): any {
  return fetch.get('/choose/feature/page/value', { params })
}

// 新增属性值
export function addFeature(params): any {
  return fetch.post('/choose/feature', params)
}

// 删除属性值
export function deleteFeature(id): any {
  return fetch.delete(`/choose/feature/${id}`)
}




// 运营 审核列表
export function getSellersList(params): any {
  return fetch.get('/choose/audit/list', { params })
}
// 审核编辑
export function editAudit(params): any {
  return fetch.put('/choose/audit/update', params)
}
// 批量通过
export function setAuditPass(id): any {
  return fetch.put(`/choose/audit/pass?sellerCommodityIds=${id}`)
}
// 批量拒绝
export function setAuditrefuse(id): any {
  return fetch.put(`/choose/audit/refuse?sellerCommodityIds=${id}`)
}



// 运营 审核编辑
export function SetEditList(params): any {
  return fetch.put('/sellers/commodities', params )
}

// 运营 批量提交审核结果
export function SetSellerResult(params): any {
  return fetch.put('/sellers/commodities', params )
}

// 选品商品列表
export function getSellerListHeart(params): any {
  return fetch.post('/choose/commodities/list', params )
}

// 查询运营后台热度
export function getHeartList(params): any {
  return fetch.get('/choose/recommends/priorities', {params} )
}

// 设置运营后台热度
export function setHeartList(params): any {
  return fetch.put('/choose/recommends/priorities', params)
}
