import fetch from './index'

export function addPrint(params): any {
    return fetch.post('/document/print/templates', params)
}

export function editPrint(params): any {
    return fetch.put('/document/print/templates', params)
}

export function getPrintList(params): any {
    return fetch.get('/document/print/templates/page', { params })
}

export function delPrint(id): any {
    return fetch.delete(`/document/print/templates/${id}`)
}

export function setDefaultPrint(params): any {
    return fetch.post(`/document/print/templates/default`, params)
}

export function getTypePrintList(params): any {
    return fetch.get('/document/print/templates/list', { params })
}

export function recordNum(params): any {
    return fetch.post('/print/count', params)
}
