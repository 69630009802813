import fetch from './index'

export function getHomeImage(params): any {
  return fetch.get('/home/welcomes/list', { params })
}

export function addHomeImage(params) {
  return fetch.post('/home/welcomes', params)
}

export function editHomeImage(params) {
  return fetch.put('/home/welcomes', params)
}

export function delHomeImage(id) {
  return fetch.delete(`/home/welcomes/${id}`)
}