import fetch from './index'

// 盘点信息预览
export function getinventoryList(params): any {
  return fetch.get('/eps/inventories/preview', {
    params
  })
}

// 盘点单详情
export function getSupperdetail(params): any {
  return fetch.get("/eps/inventories/details", {
    params
  })
}
// 提交盘点前信息
export function setSubmitpreInventory(params): any {
  return fetch.post("/eps/inventories", params)
}

// // 提交盘点后信息
// export function setSubmitInventory(params): any {
//   return fetch.put(`/eps/inventories/detail?${params}`)
// }

// 提交盘点后信息
export function setSubmitInventory(params): any {
  return fetch.put(`/eps/inventories/detail`, params)
}

// 删除盘点
export function setDeleteInventory(params): any {
  return fetch.delete("/eps/inventories/detail", {
    params
  })
}

// 提交盘点结果
export function getsupperResults(params): any {
  return fetch.put(`/eps/inventories?inventoryNo=${params?.inventoryNo}&isZero=${params?.isZero}`)
}

// 完成盘点
export function setCompleteInventory(params): any {
  return fetch.put(`/eps/inventories/complete?inventoryNo=${params?.inventoryNo}&remark=${params?.remark}`)
}


export function setComplete(params) {
  return fetch.put(`/eps/inventories/audit?inventoryNo=${params?.inventoryNo}&remark=${params?.remark}`)
}

export function setRefuse(params) {
  return fetch.put(`/eps/inventories/refuse?inventoryNo=${params?.inventoryNo}&remark=${params?.remark}`)
}

export function inventoriesStatistic(params): any {
  return fetch.get("/eps/inventories/statistic", {
    params
  })
}

// 查询当前盘点单的员工清单 柴进
export function panStaffList(params): any {
  return fetch.get("/eps/inventories/staff", {
    params
  })
}

// 新增盘点员工
export function addPanStaff(params): any {
  return fetch.post("/eps/inventories/staff", params)
}

// 修改盘点员工
export function putPanStaff(params): any {
  return fetch.put("/eps/inventories/staff", params)
}

// 删除盘点员工
export function deletePanStaff(id): any {
  return fetch.delete(`/eps/inventories/staff/${id}`)
}

export function copyPanStaff(params): any {
  return fetch.post(`/eps/inventories/staff/copy`, params)
}
