import route from '../../../route.config'
import {
  toPascalCase,
} from '../../utils'

export default function(menus = []) {
  function getDefault(v) {
    return {
      help: true,
      component: toPascalCase(v.key),
      path: `/${v.key.toLowerCase()}`,
    }
  }
  let newArr = [];
  function arrOfOneDimension(arr) {
    for (let key of arr) {
      if (key?.children?.length > 0) {
        arrOfOneDimension(key?.children);
      } else {
        newArr.push(key);
      }
    }
  }
  arrOfOneDimension(menus)

  const routes = newArr.map(v => {
    const rc = route.find(r => r.key === v.key)

    return {
      ...getDefault(v),
      ...v,
      ...rc,
    }
  }).concat(route.filter(v => newArr.every(s => s.key !== v.key)).map(v => {
    return {
      ...getDefault(v),
      ...v,
    }
  }))
  return routes
}