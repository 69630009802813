import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { connectAdvanced } from 'react-redux'

import {
  getinventoryList as getinformationList,
  getSupperdetail as getdetaillist
} from '../services/warehouseinventory'

import {
  getSupperlist as getList,
} from '../services/inventorycheck'

export const getinventoryList = createAsyncThunk<any, any, any>(
  'ware/get',
  async (params) => {
    return await getinformationList(params)
  }
)


export const getSupperdetail = createAsyncThunk<any, any, any>(
  'detail/get',
  async (params) => {
    return await getdetaillist(params)
  }
)

export const getSupperlist = createAsyncThunk<any, any, any>(
  'ware/post',
  async (params) => {
    return await getList(params)
  }
)

  
const warehouse = createSlice({
  name: 'warehouse',
  initialState: {
    inventorydata:{},
    waredata: {},
    waredetail: {}
  },
  reducers: {
    setinventoryList(state, action) {
      state.inventorydata = action.payload
    },
    setwareDeatilList(state, action) {
      state.waredetail = action.payload
    },
  },
  
  extraReducers: {
    [getinventoryList.fulfilled as any]: (state, action) => {
      state.inventorydata = action.payload
    },
    [getSupperlist.fulfilled as any]: (state, action) => {
      state.inventorydata = action.payload
    },
    [getSupperdetail.fulfilled as any]: (state, action) => {
      state.waredetail = action.payload
    },
  }
})

const {
  actions,
  reducer
} = warehouse;

export const {
  setinventoryList,
  setwareDeatilList
} = actions
export default reducer