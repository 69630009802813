import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getCategoryFeatures as getCategoryFeaturesAPI
} from '../services/operselection'

export const getCategoryFeatures = createAsyncThunk<any, any, any>(
  'featuresChoose/list',
  async (params) => {
    const data = await getCategoryFeaturesAPI(params)

    return {
      [params.commonCatIds]: data || {}
    }
  }
)

const featureSlice = createSlice({
  name: 'featureChoose',
  initialState: {
    features: {}
  },
  reducers: {
  },
  extraReducers: {
    [getCategoryFeatures.fulfilled as any]: (state, action) => {
      state.features = {
        ...state.features,
        ...action.payload
      }
    },
  }
})

const {
  reducer
} = featureSlice

export default reducer