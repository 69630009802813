import menus from './menus'
import routes from './routes'
import precision from './precision'

const adapters = {}

function register(key, transformer) {
  adapters[key] = transformer
}

register('menus', menus)
register('routes', routes)
register('precision', precision)

export default function(key, origin) {
  return adapters[key](origin)
}