import React, { Component } from "react";
import { DragSource } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { Tooltip } from 'antd';
import classNames from "classnames";
import S from "./index.less";

class DragField extends Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    const { connectDragSource, isDragging, RenderDom, dragClassName, list } = this.props;
    return connectDragSource(
      <div
        className={classNames(
          dragClassName,
          isDragging ? S.dragging : "",
        )}
      >
        {RenderDom()}
      </div>
    );
  }
}

// https://blog.csdn.net/zgd826237710/article/details/89326588

export default DragSource(
  "DragAddMenu",
  {
    beginDrag(props: any, monitor, component) {
      // 必填。当拖动开始时，beginDrag 被调用
      // 返回的内容会被放置到 monitor.getItem() 获取到的对象中
      return {
        ...props,
        val: props.val,
        onDrop: props.onDrop
      };
    },
    canDrag(props, monitor) {
      return !((props?.list || []).some(v => v.key === props?.val?.key));
    },
    endDrag(props, monitor, component) {
      // // 可选的。当拖动停止时，endDrag 被调用
      // const item = monitor.getItem();
      // // 拖拽元素放下时，drop 结果
      // const dropResult = monitor.getDropResult();
      // // console.log(dropResult,"dropResult");
    },
  },
  (connect, monitor) => {
    // 返回对象注入你组件的props中去
    return {
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging(),
    };
  }
)(DragField);
