import Cookies from 'js-cookie'

export function set(session) {
  Cookies.set('X-Time', session?.tokenTime, { expires: 365 })
  Cookies.set('X-Token', session?.userToken, { expires: 365 })
  Cookies.set('X-UID', session?.userId, { expires: 365 })
  Cookies.set('X-SID', session?.epsId, { expires: 365 })
}

export function get(key: string): string;
export function get(): [string, string][];
export function get(key?) {
  return key ? Cookies.get(key) : [
    ['X-Time', Cookies.get('X-Time')],
    ['X-Token', Cookies.get('X-Token')],
    ['X-UID', Cookies.get('X-UID')],
    ['X-SID', Cookies.get('X-SID')]
  ]
}

export function clear() {
  Cookies.remove('X-Time');
  Cookies.remove('X-Token');
  Cookies.remove('X-UID');
  Cookies.remove('X-SID');
}