import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getCategoryFeatures as getCategoryFeaturesAPI
} from '../services/features'

export const getCategoryFeatures = createAsyncThunk<any, any, any>(
  'features/list',
  async (params, { getState }) => {
    // const state: any = getState()
    // const features = state?.feature?.features?.[params.categoryId]

    // if (features) return {
    //   [params.categoryId]: features
    // }
    const data = await getCategoryFeaturesAPI(params)
    return {
      [params.categoryId]: data || {}
    }
  }
)

const featureSlice = createSlice({
  name: 'feature',
  initialState: {
    features: {}
  },
  reducers: {
  },
  extraReducers: {
    [getCategoryFeatures.fulfilled as any]: (state, action) => {
      state.features = {
        ...state.features,
        ...action.payload
      }
    },
  }
})

const {
  reducer
} = featureSlice

export default reducer