import fetch from './index'
// 全部消息数，未读消息数
export function getUserMsgCount(msgStatus): any {
  return fetch.get(`/user/msg/count/${msgStatus}`)
}
// 消息列表
export function getUserMsgPage(params): any {
  return fetch.post('/user/msg/page', params)
}
// 标记已读
export function putUserMsgRead(params): any {
  return fetch.put('/user/msg/read', params)
}
// 删除消息
export function delUserMsgDelete(params): any {
  return fetch.post('/user/msg/delete', params)
}
