import fetch from './index'

// 入库分析
export function getWareDashboard(params): any {
  return fetch.post('/analytics/entry/dashboard', params)
}

// 库存分析
export function getCommodityDashboard(params): any {
  return fetch.get('/analytics/eps/commodity/dashboard', { params })
}

