import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getCategoriesList as getCategoriesListAPI,
} from '../services/operselection'

export const getCategoriesList = createAsyncThunk<any, any, any>(
  'categoriesChoose/list',
  async (params) => {
    const data = await getCategoriesListAPI(params)
    return {
      [params.parentCategoryId]: data || []
    }
  }
)

const categorySlice = createSlice({
  name: 'categoryChoose',
  initialState: {
    categories: {},
    priceList: {}
  },
  reducers: {
  },
  extraReducers: {
    [getCategoriesList.fulfilled as any]: (state, action) => {
      state.categories = {
        ...state.categories,
        ...action.payload,
      }
    },
  }
})

const {
  reducer
} = categorySlice

export default reducer