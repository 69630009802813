import fetch from './index'

// 企业权限  企业角色列表
export function getRoleList(params): any {
  return fetch.get('/eps/system/role/list', { params })
}

//  权限树
export function getAuthList(): any {
  return fetch.get('/eps/system/role/auths/values')
}

//  编辑系统角色
export function getEditRole(params): any {
  return fetch.put('/eps/system/role', params)
}


// 企业管理 列表查询
export function getEpsList(params): any {
  return fetch.post('/eps/list' , params)
}

//  新增
export function AddEps(params): any {
  return fetch.post('/eps' ,  params )
}

//  编辑
export function EditEps(params): any {
  return fetch.put('/eps', params )
}

// 删除企业
export function deleteEps(id): any {
  return fetch.delete(`/eps/${id}`)
}

//  设置初始选品信息
export function setPriceRate(params): any {
  return fetch.put('/eps/choose/global/price/rate' ,  params )
}

//  查询企业全局选品中心加点
export function getPriceRate(params): any {
  return fetch.get('/eps/choose/global/price/rate', {params} )
}


// 批量选择跟进人
export function setFollowUser(params): any {
  return fetch.put('/eps/batch/follow/userId' ,  params )
}

//  批量设置选品加点
export function setBatchPriceRate(params): any {
  return fetch.put('/eps/batch/choose/price/rate', params )
}

// 更改加点值是否作用于已选商品
export function setCommoditiesPriceRate(params): any {
  return fetch.put(`/sellers/commodities/price/rate?epsId=${params.epsId}&priceRate=${params.priceRate}`)
}

// 查看沟通记录
export function getLooksRecords(id): any {
  return fetch.get(`/eps/chat/records/list?epsId=${id}` )
}

// 填写沟通记录
export function getFillRecords(params): any {
  return fetch.post('/eps/chat/records' ,  params )
}


// 企业订单  列表查询
export function getOrderList(params): any {
  return fetch.get('/platform/trade/orders/eps/list' , { params })
}

// 用户管理
export function getUserList(params): any {
  return fetch.post('/users/platform/list', params)
}

export function setResetList(id): any {
  return fetch.get(`/sellers/reset/cat/init/flag?epsId=${id}`)
}

export function getAppletCode(id): any {
  return fetch.get(`/mini/back/seller/qr/code?epsId=${id}`)
}

// 运营-企业-选品用户-编辑邀请人
export function editChooseInviter(userId, inviterUserId): any {
  return fetch.put(`/users/invites/${userId}/${inviterUserId}`)
}
// 运营-企业-选品用户-提交沟通记录
export function addInviterChat(params): any {
  return fetch.post('/eps/chat/records/choose/user', params)
}
// 运营-企业-选品用户-查看沟通记录
export function getInviterChat(id): any {
  return fetch.get(`/eps/chat/records/choose/user/list?userId=${id}`)
}

// 查看员工试用记录
export function getStaffHistory(params): any {
  return fetch.post(`/staff/browsing/history/list`, params)
}

// 创建缴费记录
export function setApplyBack(params): any {
  return fetch.post(`/trade/orders/sellers/apply/back`, params)
}

// 查询缴费记录
export function getApplyList(params): any {
  return fetch.get(`/trade/orders/sellers/apply/list`, { params })
}

// 获取小程序商户列表(发布日常版用)
export function getDailySellerList() {
  return fetch.get('/sellers/value')
}

// 商户小程序体验版更新
export function postWechatCommit(params) {
  return fetch.post('/api/wechat/commit', params)
}

// 获取发布模版
export function getWechatTemplates() {
  return fetch.get('https://api.miaoerp.com/api/wechat/templates')
}

// 小程序发布新版本
export function putWechatBackRelease(params) {
  return fetch.put('https://api.miaoerp.com/api/wechat/back/release', params)
}

// 隐私设置
export function setWechartPricacy(id) {
  return fetch.post(`/api/wechat/privacy?enterpriseId=${id}`, {})
}

