import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getCommoditiesRoute as getCommoditiesRouteAPI
} from '../services/commodities'

export const getCommoditiesRoute = createAsyncThunk<any, any, any>(
  'commodities/route',
  async (params) => {
    const data = await getCommoditiesRouteAPI(params)
    return data || []
  }
)

const commodityRouteSlice = createSlice({
  name: 'commodityRoute',
  initialState: {
    commoditiesRoute: []
  },
  reducers: {
  },
  extraReducers: {
    [getCommoditiesRoute.fulfilled as any]: (state, action) => {
      state.commoditiesRoute = action.payload
    },
    [getCommoditiesRoute.rejected as any]: (state, action) => {
      state.commoditiesRoute = []
    }
  }
})

const {
  reducer
} = commodityRouteSlice

export default reducer