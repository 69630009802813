import fetch from './index'

//  商品管理 列表
export function getAdminList(params): any {
  return fetch.post('/sellers/commodities/admin/list', params)
}
export function getOutList(params): any {
  return fetch.post('/sellers/commodities/admin/list', params)
}

// 商品详情
export function getDetail(id): any {
  return fetch.get(`/sellers/commodities/${id}`)
}

// 批量上下架
export function setUpper(params): any {
  return fetch.put(`/sellers/commodities/status?status=${params?.status}&sellerCommodityIds=${params.sellerCommodityIds}`)
}

// 批量删除
export function setDelete(params): any {
  return fetch.delete('/sellers/commodities', {params})
}

// 编辑自有商品
export function editCommdity(params): any {
  return fetch.put('/sellers/commodities', params)
}

// 编辑选品商品
export function editCommdities(params): any {
  return fetch.put('/sellers/commodities', params)
}

// 撤销
export function setUndo(params): any {
  return fetch.delete('/sellers/commodities/audit', { params })
}

// 撤销
export function setReview(id): any {
  return fetch.put(`/sellers/commodities/audit?sellerCommodityIds=${id}`)
}
// 提交到选品中心
export function setSubmitAudit(params): any {
  return fetch.post('/sellers/commodities/audit', params)
}

// 商品新增
export function addCommodities(params): any {
  return fetch.post('/sellers/commodities', params)
}

// 商品分组 增
export function addGroup(params): any {
  return fetch.post('/sellers/commodities/group', params)
}

// 商品导入
export function importAddCommodities(params): any {
  return fetch.post('/sellers/commodities/import', params)
}

// 商品分组 改
export function editGroup(params): any {
  return fetch.put('/sellers/commodities/group', params)
}
// 商品分组 删
export function deleteGroup(id): any {
  return fetch.delete(`/sellers/commodities/group/${id}`)
}
// 商品分组 查
export function getGroupList(params): any {
  return fetch.get('/sellers/commodities/group/list', { params })
}

// 商品分组 所有
export function getGroupAllList(params): any {
  return fetch.get('/sellers/commodities/group/all', { params })
}

// 商品分组 显隐
export function displayGroup(params): any {
  return fetch.put('/sellers/commodities/group/display', params)
}
// 商品分组 上下移
export function moveGroup(params): any {
  return fetch.put('/sellers/commodities/group/move', params)
}
// 商品管理 外部商品 置顶
export function putCommodityTop(id): any {
  return fetch.put(`/sellers/commodities/top?sellerCommodityId=${id}`)
}

// 商品管理 外部商品 取消置顶
export function putCommodityBack(id): any {
  return fetch.put(`/sellers/commodities/back?sellerCommodityId=${id}`)
}
 
// 批量更改商户分组
export function setChangeGroup(params): any {
  return fetch.put(`/sellers/commodities/change/group?sellerCommodityIds=${params?.sellerCommodityIds}&sellerCommodityGroupIds=${params?.sellerCommodityGroupIds}`)
}


// 商品分组 映射
export function getMappingList(params): any {
  return fetch.get('/choose/categories/mapping', { params})
}

// 小程序商品导出
export function exportSellerCommodity(params): any {
  return fetch.post('/export/records/sellers/commodities/admin/list', params)
}






