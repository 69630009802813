import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getParentCategories as getParentCategoriesAPI,
} from '../services/categories'

interface ParamsType {
  selectAll: boolean;
}

export const getParentCategories = createAsyncThunk<any, void | ParamsType, any>(
  'parentCategories/values',
  async (params) => {
    const data = await getParentCategoriesAPI(params)
    return data || []
  }
)

const parentCategorySlice = createSlice({
  name: 'parentCategory',
  initialState: {
    parentCategories: []
  },
  reducers: {
  },
  extraReducers: {
    [getParentCategories.fulfilled as any]: (state, action) => {
      state.parentCategories = action.payload
    },
    [getParentCategories.rejected as any]: (state, action) => {
      state.parentCategories = []
    }
  }
})

const {
  reducer,
} = parentCategorySlice;

export default reducer