import React, { useEffect, RefObject, useState, useMemo, useLayoutEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Dropdown,
  Menu,
} from 'antd'
import classnames from 'classnames'

import Icon from '@/components/Icon'
import RecentVisitTab from '../RecentVisitTab'
import styles from './index.less'

import { setTabs } from '@/reducers/tab'
import { exactStrictMatchPath, globalLoading } from '@/utils'
import { getUserAllMsgCount, getUserNoReadMsgCount } from '@/reducers/newsCenter'
import { setBrowserUrl } from '@/reducers/browserUrl'

const homeKey = 'HOME'

function RecentVisit() {
  const ref: RefObject<HTMLDivElement> = React.createRef()
  const moreRef: RefObject<HTMLSpanElement> = React.createRef()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [width, setWidth] = useState(0)
  const [mountedTabs, setMountedTabs] = useState([])
  const [tabsWidth, setTabsWidth] = useState({})
  const [hiddenTabs, setHiddenTabs] = useState([])
  const routes = useSelector<any, any[]>(state => state?.route?.routes)
  const tabs = useSelector<any, any[]>(state => state?.tab?.tabs)
  const homeRoute = routes.find(v => v.key === homeKey)
  const home = homeRoute ? {
    ...homeRoute,
    active: true,
    unclosable: true,
  } : null

  function syncPages(pages) {
    if (JSON.stringify(tabs) !== JSON.stringify(pages)) {
      dispatch(setTabs(pages))
      globalLoading.prevent(() => {
        dispatch(getUserAllMsgCount(0))
        dispatch(getUserNoReadMsgCount(1))
        dispatch(setBrowserUrl(document.location.href))
      })()
    }
  }

  function syncHiddenTabs(newHiddenTabs) {
    if (JSON.stringify(newHiddenTabs) !== JSON.stringify(hiddenTabs)) {
      setHiddenTabs(newHiddenTabs)
    }
  }
  useLayoutEffect(() => {
    
    let pages = [...tabs]

    if (!pages.find(v => v.key === homeKey) && home) {
      pages.unshift(home)
    }

    if (pages.every(v => !exactStrictMatchPath(location.pathname, v.path)) && routes.some(r => exactStrictMatchPath(location.pathname, r.path))) {
      pages = [
        ...pages.map(v => ({
          ...v,
          active: false,
        })),
        {
          ...routes.find(r => exactStrictMatchPath(location.pathname, r.path)),
          link: location.pathname,
          active: true,
        }
      ]
    } else {
      pages = [
        ...pages.map(v => {
          const active = exactStrictMatchPath(location.pathname, v.path)

          return {
            ...v,
            link: active ? location.pathname : v.link,
            active,
          }
        })
      ]
    }

    syncPages(pages)
    syncHiddenTabs(hiddenTabs.filter(v => !pages.find(p => p.key === v).active))
  }, [location, routes])

  useEffect(() => {
    const ro = new window.ResizeObserver((entries) => {
      for (let entry of entries) {
        const target = entry.target;
        const {
          width,
        } = target.getBoundingClientRect()

        setWidth(width);
      }
    })

    ro.observe(ref.current);

    return () => {
      ro.disconnect();
    }
  }, [])

  useLayoutEffect(() => {
    let totalWidth = mountedTabs.reduce((acc, v) => {
      return acc + tabsWidth[v]
    }, 0)
    const availableWidth = ref.current.getBoundingClientRect().width - moreRef.current.getBoundingClientRect().width;

    if (totalWidth > availableWidth) {
      let data = [...hiddenTabs]
      const normalTabs = mountedTabs.filter(v => !tabs.find(vv => vv.key === v).active)

      for (let i = normalTabs.length - 1; i >= 0; i--) {
        const t = normalTabs[i]
        const w = tabsWidth[t]
        if (totalWidth <= availableWidth) break;
        data = [...data, t]
        totalWidth -= w
      }

      syncHiddenTabs(data)
    } else if (hiddenTabs.length > 0) {
      let data = [...hiddenTabs]

      for (let i = hiddenTabs.length - 1; i >= 0; i--) {
        const t = hiddenTabs[i]
        const w = tabsWidth[t]
        if (totalWidth + w > availableWidth) break;
        data = data.filter(v => v !== t)
        totalWidth += w
      }

      syncHiddenTabs(data)
    }
  }, [width, mountedTabs])

  function handleVisit(link) {
    history.push(link)
  }

  function handleClose(key, active) {
    const remainPages = tabs.filter(v => v.key !== key)
    syncPages(remainPages)
    syncHiddenTabs(hiddenTabs.filter(v => v !== key))
    if (active) {
      history.push(remainPages[remainPages.length - 1].link)
    }
  }
  
  function handleUnmount(key) {
    setMountedTabs(preMountedTabs => {
      return preMountedTabs.filter(v => v !== key)
    })
  }

  function handleMount(key, width) {
    setMountedTabs(preMountedTabs => {
      return [
        ...preMountedTabs,
        key,
      ]
    })
    setTabsWidth(preTabsWidth => ({
      ...preTabsWidth,
      [key]: width,
    }))
  }

  return (
    <div className={styles.container} ref={ref}>
      {
        useMemo(() => (tabs as any[]).filter(v => hiddenTabs.indexOf(v.key) === -1).map(page => {
          return (
            <RecentVisitTab
              key={page.key}
              pageKey={page.key}
              active={page.active}
              name={page.title}
              link={page.link}
              unclosable={page.unclosable}
              onVisit={handleVisit}
              onClose={handleClose}
              onMount={handleMount}
              onUnmount={handleUnmount}
            />
          )
        }), [tabs, hiddenTabs]) 
      }
      <Dropdown overlayClassName={styles.menu} overlay={(
        <Menu>
          {
            useMemo(() => tabs.filter(v => hiddenTabs.indexOf(v.key) > -1).map(page => (
              <Menu.Item key={page.key}>
                <RecentVisitTab
                  pageKey={page.key}
                  inMenu
                  link={page.link}
                  active={page.active}
                  name={page.title}
                  unclosable={page.unclosable}
                  onVisit={handleVisit}
                  onClose={handleClose}
                />
              </Menu.Item>
            )), [tabs, hiddenTabs])
          }
        </Menu>
      )} trigger={['click']}>
        <span ref={moreRef} className={classnames(styles.more, {
          [styles.hidden]: hiddenTabs.length === 0
        })}>
          更多
          <Icon type='SORT' className={styles.moreIcon} />
        </span>
      </Dropdown>
    </div>
  )
}

export default RecentVisit
