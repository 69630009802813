import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getExportColumns as getExportColumnsAPI
} from '../services/exports'

export const getExportColumns = createAsyncThunk<any, any, any>(
  'export/columns',
  async (params) => {
    const data = await getExportColumnsAPI(params)
    return {
      ['a' + params.type]: data || []
    }
  }
)

const exportColumnsSlice = createSlice({
  name: 'exportColumns',
  initialState: {
    exportColumns: {}
  },
  reducers: {
  },
  extraReducers: {
    [getExportColumns.fulfilled as any]: (state, action) => {
      state.exportColumns = {
        ...state.exportColumns,
        ...action.payload
      }
    },
  }
})

const {
  reducer
} = exportColumnsSlice

export default reducer