import fetch from './index'

// 借出报表
export function getLendList(params): any {
    return fetch.post('/commodities/rent/statement', params)
}

// 借入报表
export function getBorrowList(params): any {
    return fetch.post('/analytics/rent/entry/detail', params)
}


// 借出导出
export function exportLendList(params): any {
    return fetch.post('/export/records/commodities/rent/statement', params)
}

// 借入导出
export function exportBorrowList(params): any {
    return fetch.post('/export/records/analytics/rent/entry/detail', params)
}