import fetch from './index'

export function getStaffs(params): any {
  return fetch.get('/staffs/list', { params })
}

export function delStaff(id) {
  return fetch.delete(`/staffs/${id}`)
}

export function addStaff(params) {
  return fetch.post('/staffs', params)
}

export function editStaff(params) {
  return fetch.put('/staffs', params)
}

export function putAuthStaff(id, flag) {
  return fetch.get(`/staffs/authorize/choose/${id}/${flag}`)
}

export function getStaffRepeat(params): any {
  let url = ''
  if (params.id) {
    url = `/staffs/name/repeat?id=${params.id}&staffName=${params.staffName}`
  } else {
    url = `/staffs/name/repeat?staffName=${params.staffName}`
  }
  return fetch.get(url)
}

export function setPassword(params) {
  return fetch.put('/staffs/password', params)
}

export function setConfirmPwd(params): any {
  return fetch.put('/staffs/password/admin', params)
}