import fetch from './index'

export function getExportsList(params): any {
  return fetch.get('/export/records', { params })
}
// 请求导出字段信息
export function getExportColumns(params): any {
  return fetch.get('/export/columns', { params })
}
// 配置导出字段信息
export function postExportColumns(params): any {
  return fetch.post('/export/columns', params)
}

export function exportCommodityRouteInfos(params): any {
  return fetch.post('/export/records/eps/commodities/record', params)
}