import routes from '../../../route.config'

export default function(origin = []) {
  function Menus(arr, level) {
    let datas = []
    arr.forEach((item, index) => {
      let obj: any = {}
      if(level == 0) {
        obj.icon = item.menuCode;
      }
      obj.key = item.menuCode
      obj.title = item.menuName
      if (item.childrenMenus && item.childrenMenus.length > 0) {
        obj.children = Menus(item.childrenMenus, level + 1);
      } else {
        const route = routes.find(r => r.key === item.menuCode)
        obj.path = route?.path || `/${item.menuCode.toLowerCase()}`
        obj.link = route?.link || `/${item.menuCode.toLowerCase()}`
      }
      datas.push(obj)
    });
    return datas;
  }

  return Menus(origin, 0)
}