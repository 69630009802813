import fetch from './index'

// 客户标签 列表
export function getTagsList(params): any {
  return fetch.get('/tags/seller/customer/page', { params })
}

// 用户 列表
export function getuserList(params): any {
  return fetch.post(`/users/list`, params)
}

export function editUser(params): any {
  return fetch.put(`/users`, params)
}

// 取消分销员
export function setCancel(id): any {
  return fetch.delete(`/distributors/${id}`)
}

// 批量上级设置
export function setBatchParent(params): any {
  return fetch.post(`/distributors/batch/parent`, params)
}

// 批量添加分销商
export function setBatchUser(params): any {
  return fetch.post(`/distributors/batch`, params)
}

// 批量设置折扣
export function setBatchDiscount(params): any {
  return fetch.post(`/users/batch/discount`, params)
}

// GIA 加点
export function getGiaList(params): any {
  return fetch.get(`/sellers/rate/gia`, {params})
}

// GIA 加点
export function setGia(params): any {
  return fetch.put(`sellers/rate/gia?id=${params?.id}&rate=${params?.rate}`)
}

// 珠宝饰品金额查看详情列表
export function getTradeList(params): any {
  return fetch.get(`/trade/orders/list/${params?.userId}?nextCursor=${params?.nextCursor}&pageSize=${params?.pageSize}`)
}

// 分销列表
export function getDistrbutorsList(params): any {
  return fetch.get(`/distributors/list`, {params})
}

// 分销详情
export function getDistrbutorsDetail(params): any {
  return fetch.get(`/distributors/detail/list`, {params})
}

// 保存分销
export function setDistrbutorsSave(params): any {
  return fetch.post(`/distributors/save/setting`, params)
}

// 保存分销
export function getDistrbutors(): any {
  return fetch.get(`/distributors/setting`)
}

// 编辑Vip
export function putUserVip(params): any {
  return fetch.put('/user/member/update/vip', params)
}
 
// 用户信息导出
export function exportSellerUser(params): any {
  return fetch.post('/export/records/users/list', params)
}


