import fetch from './index'

export function getMenu() {
  return fetch.get('auths/menu')
}

export function setHistory(params: any) {
  return fetch.post('/staff/browsing/history', params)
}

export function getShortcutMenu() {
  return fetch.get('/user/shortcut/menu')
}

export function setShortcutMenu(params: any) {
  return fetch.post('/user/shortcut/menu', params)
}
