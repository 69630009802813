import React from 'react'
import { Result, Button } from 'antd';
import {
  Link,
} from 'react-router-dom'

class PageErrorBoundary extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Result
          title="网络出错，请重连"
          subTitle="很抱歉，您访问的页面出现了故障"
          status="error"
          extra={
            <Link to="/">
              <Button type="primary">返回首页</Button>
            </Link>
          }
        />
      )
    }

    return this.props.children; 
  }
}

export default PageErrorBoundary