import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getRoles as getRolesAPI,
} from '../services/roles'

export const getRoles = createAsyncThunk<any, any, any>(
  'roles/list',
  async (params) => {
    const data = await getRolesAPI(params)
    return data || []
  }
)

const roleSlice = createSlice({
  name: 'role',
  initialState: {
    roles: []
  },
  reducers: {
  },
  extraReducers: {
    [getRoles.fulfilled as any]: (state, action) => {
      state.roles = action.payload
    },
    [getRoles.rejected as any]: (state, action) => {
      state.roles = []
    },
  }
})

const {
  reducer,
} = roleSlice;

export default reducer