import help from './help'
import menu from './menu'
import user from './user'
import tab from './tab'
import route from './route'
import role from './role'
import power from './power'
import staff from './staff'
import history from './history'
import series from './series'
import designer from './designer'
import keyword from './keyword'
import fashion from './fashion'
import depart from './depart'
import factory from './factory'
import supplier from './supplier'
import channel from './channel'
import customer from './customer'

import allRoles from './allRole'
import allDeparts from './allDepart'
import entries from './entrymanage'
import supplement from './supplierquery'
import goldPrice from './goldPrice'
import warehouse from './warehouse'
import cabinet from './cabinet'
import parentCategory from './parentCategory'
import category from './category'
import categoryChoose from './categoryChoose'
import feature from './feature'
import featureChoose from './featureChoose'
import featureValue from './featureValue'
import storagequery from './storagequery'
import salereturnquery from './ordereturnquery'
import storagetransferquery from './commoditytransferquery.ts'
import inventorycheck from './inventorycheck'
import salereturnrefund from './salereturnrefund'
import homeImage from './welcome'
import commodity from './commodity'

import warehouseinventory from './warehouseinventory'
import exportList from './exportList'
import commodityRoute from './commodityRoute'
import bill from './bill'
import global from './global'
import exportColumns from './exportColumns'
import tag from './tag'
import analysis from './analysis'
import borrowLend from './borrowLend'
import printTemplate from './printTemplate'

import outputDashboard from './dashboardOutput'
import designDashboard from './dashboardDesign'
import supplierReturnDashboard from './dashboardSupplierReturn'
import orderReturnDashboard from './dashboardOrderReturn'
import wareanalysis from './wareanalysis'
import selleruser from './selleruser'
import sellermanage from './sellermanage'
import enterprise from './enterprise'
import coupon from './coupon'
import gemstoreOrder from './gemstoneorder'
import operselection from './operselection'
import preOrder from './preOrder'
import warehouseRule from './warehouseRule'
import chooseCommodity from './chooseCommodity'
import scoreLog from './scoreLog'
import activity from './activity'

import pageParams from './pageParam'
import pageData from './pageData'
import news from './newsCenter'
import browserUrl from './browserUrl'

import publicReducer from './publicReducer'
import vagueSearch from './vagueSearch'

export default {
  help,
  menu,
  user,
  tab,
  route,
  role,
  power,
  staff,
  history,
  series,
  designer,
  keyword,
  fashion,
  allRoles,
  allDeparts,
  entries,
  supplement,
  depart,
  factory,
  goldPrice,
  supplier,
  channel,
  customer,
  warehouse,
  cabinet,
  parentCategory,
  category,
  categoryChoose,
  feature,
  featureChoose,
  featureValue,
  storagequery,
  salereturnquery,
  storagetransferquery,
  inventorycheck,
  salereturnrefund,
  homeImage,
  commodity,
  warehouseinventory,
  exportList,
  commodityRoute,
  bill,
  global,
  exportColumns,
  tag,
  outputDashboard,
  designDashboard,
  supplierReturnDashboard,
  orderReturnDashboard,
  analysis,
  borrowLend,
  wareanalysis,
  selleruser,
  sellermanage,
  enterprise,
  coupon,
  gemstoreOrder,
  operselection,
  preOrder,
  warehouseRule,
  chooseCommodity,
  scoreLog,
  activity,
  printTemplate,
  pageParams,
  pageData,
  news,
  browserUrl,
  publicReducer,
  vagueSearch,
}