import React, { Component } from "react";
import { DragSource, DropTarget } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import S from "./index.less";
import classNames from "classnames";

const Type = "DragMenuSort";

class DragItems extends Component<any> {
  dropDOM: HTMLDivElement | any;
  constructor(props: any) {
    super(props);
    this.state = {
      dropDOM: null
    };
  }

  getBoundingClientRect = () => {
    return this.dropDOM.getBoundingClientRect();
  };

  refCallback = (dom: any) => {
    const { connectDropTarget } = this.props;
    this.dropDOM = dom;
    connectDropTarget(dom);
  };

  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage());
  }

  render() {
    const { connectDragSource, connectDropTarget, connectDragPreview, isDragging, RenderDom, moveIcon, dropClassName = '', dragClassName = '', refValueBol } = this.props;
    return connectDragPreview(connectDropTarget(
      <div className={dropClassName} ref={this.refCallback}>
        {connectDragSource(<div className={dragClassName}>{RenderDom()}</div>)}
      </div>
    ));
  }
}

const DragSourceContainer = DragSource(
  Type,
  {
    beginDrag(props: any, monitor, component) {
      // 必填。当拖动开始时，beginDrag 被调用
      // 返回的内容会被放置到 monitor.getItem() 获取到的对象中
      // const { data } = props;
      return {
        ...props,
        index: props.index,
      };
    },
    canDrag(props, monitor) {
      return true;
    },
    endDrag(props, monitor, component) {
      if (!monitor.didDrop()) {
        props.onDelete && props.onDelete(props.index)
      }
      // // 可选的。当拖动停止时，endDrag 被调用
      // const item = monitor.getItem();
      // // 拖拽元素放下时，drop 结果
      // const dropResult = monitor.getDropResult();
      // // console.log(dropResult,"dropResult");
    },
  },
  (connect, monitor) => {
    // 返回对象注入你组件的props中去
    return {
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging(),
    };
  }
);


const DropTargetContainer = DropTarget(
  [Type, 'DragAddMenu'],
  {
    drop(props: any, monitor: any, component: any) {
      if (monitor.getItemType() === 'DragAddMenu') {
        const hoverIndex = props.index;
        const item = monitor.getItem();
        props.refValueBol.current = true;
        props.onMove && props.onMove(undefined, hoverIndex, item.val);
      } else {
        const item = monitor.getItem();
        const dragIndex = item.index;
        const hoverIndex = props.index;
        if (dragIndex !== hoverIndex) {
          const hoverBoundingRect = component.getBoundingClientRect();
          let t = hoverBoundingRect.top;
          let r = hoverBoundingRect.right;
          let b = hoverBoundingRect.bottom;
          let l = hoverBoundingRect.left;
          const { x, y } = monitor.getClientOffset();
          if (y > t && y < b || x > l && x < r) {
            props.onMove && props.onMove(dragIndex, hoverIndex);
          }
        }
      }
    },
    // hover(props: any, monitor: any, component: any) {
    //   const item = monitor.getItem();
    //   const dragIndex = item.index;
    //   const hoverIndex = props.index;
    //   if (dragIndex !== hoverIndex) {
    //     const hoverBoundingRect = component.getBoundingClientRect();
    //     let t = hoverBoundingRect.top;
    //     let r = hoverBoundingRect.right;
    //     let b = hoverBoundingRect.bottom;
    //     let l = hoverBoundingRect.left;
    //     const { x, y } = monitor.getClientOffset();
    //     if (y > t && y < b || x > l && x < r) {
    //       props.onMove && props.onMove(dragIndex, hoverIndex);
    //       item.index = hoverIndex;
    //     }
    //   }
    // }
  },
  (connect) => {
    return {
      connectDropTarget: connect.dropTarget(),
    };
  }
);

export default DragSourceContainer(DropTargetContainer(DragItems));