import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getKeywordsList as getKeywordsAPI,
} from '../services/keywords'

export const getKeywordsList = createAsyncThunk<any, any, any>(
  'keywords/list',
  async (params) => {
    const data = await getKeywordsAPI(params)
    return data || []
  }
)

const keywordSlice = createSlice({
  name: 'keyword',
  initialState: {
    keywords: []
  },
  reducers: {
  },
  extraReducers: {
    [getKeywordsList.fulfilled as any]: (state, action) => {
      state.keywords = action.payload
    },
    [getKeywordsList.rejected as any]: (state, action) => {
      state.keywords = []
    },
  }
})

const {
  reducer,
} = keywordSlice;

export default reducer