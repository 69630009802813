import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getEntryList as getTableList,
  getOutputList as getDetail
} from '../services/analysis'

export const getEntryList = createAsyncThunk<any, any, any>(
  'entry/list',
  async (params) => {
    const data = await getTableList(params)
    return data || []
  }
)

export const getOutputList = createAsyncThunk<any, any, any>(
  'output/list',
  async (params) => {
    const data = await getDetail(params)
    return data || []
  }
)
const ananList = createSlice({
  name: 'anal',
  initialState: {
    entry: [],
    outpput: {}
  },
  reducers: {
  },
  extraReducers: {
    [getEntryList.fulfilled as any]: (state, action) => {
      state.entry = action.payload
    },

    [getOutputList.fulfilled as any]: (state, action) => {
      state.outpput = action.payload
    }
  }
})

const {
  reducer
} = ananList


export default reducer
