import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getLendList, getBorrowList } from '../services/borrowLend';

const NAMESPACE = "borrowLend";

const initialState = {
  lendList: [],
  borrowList: []
};

export const getLendListReducer = createAsyncThunk(
  `${NAMESPACE}/getLendList`,
  //发出一个请求，这里用的是axios
  async (params: any) => await getLendList(params),
)

export const getBorrowListReducer = createAsyncThunk(
  `${NAMESPACE}/getBorrowList`,
  //发出一个请求，这里用的是axios
  async (params: any) => await getBorrowList(params),
)

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    updateLendList(state, action) {
      state.lendList = action.payload;
    },
    updateBorrowList(state, action) {
      state.borrowList = action.payload;
    }
  },
  // 处理异步请求结果的reducer
  extraReducers: (builder) => {
    builder.addCase(getLendListReducer.fulfilled, (state, action) => {
      // data为接口返回的data字段
      const data = action.payload;
      state.lendList = data;
    }),
    builder.addCase(getBorrowListReducer.fulfilled, (state, action) => {
      // data为接口返回的data字段
      const data = action.payload;
      state.borrowList = data;
    })
  },
});


export const { actions, reducer } = slice;

export default reducer;