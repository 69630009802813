import {
  message,
} from 'antd'
import { matchPath } from 'react-router-dom'
import Big from 'big.js'
import moment from 'moment';
import Viewer from 'viewerjs';
import {
logout,
} from '../reducers/user'
import {
  setNewsFactoryOrderNo,
  setNewsRepairCommodityNo,
  setNewsCommodityTransferNo,
} from '@/reducers/pageParam'
import { delEmpty } from './methods'

const sessionOutOfDateErrorHandler = (function() {
  let isHandling = false

  return () => {
    if (isHandling) return
    isHandling = true

    message.error('登录已失效，请重新登录', 4.5, () => {
      isHandling = false
    })
    import('./store').then(module => {
      module.default.dispatch(logout())
    })
  }
}())

export function errorHandler(e, status?) {
  if (status === 6506) {
    sessionOutOfDateErrorHandler()

    return;
  }

  let msg = ''

  try {
    let status = e?.response?.status;
    let statusText = e?.response?.statusText;
    if (status && statusText) {
      msg = `status ${status}: ${statusText }`
    }
  } catch(e) {
    msg = ''
  }

  if (e?.code !== "B0-001-02-0305" && e?.code !== 'B0-002-02-0515') {
    message.error(e.msg || msg || e.message || '服务器开小差了，请稍后重试', 4.5)
  }
  
  if (e?.code === 'B0-000-01-0004') {
    import('./store').then(module => {
      module.default.dispatch(logout())
    })
  }
}

export function toPascalCase(str, split = '_') {
  return str.split(split).map(v => v.toLowerCase()).map(v => `${v.charAt(0).toUpperCase()}${v.substr(1)}`).join('')
}

export function toHyphenated(str, hyphen = '_') {
  return str.split(/(?=[A-Z])/).join(hyphen).toLowerCase()
}

export function exactStrictMatchPath(pathname, path) {
  return matchPath(pathname, {
    path,
    exact: true,
    strict: true
  })
}

export function extname(filename) {
  return filename.slice(filename.lastIndexOf('.'));
}

export function departValueFromEventTarget(fn) {
  return function(e) {
    return fn(e.target.value)
  }
}

export function departValueFromEventChecked(fn) {
  return function(e) {
    return fn(e.target.checked)
  }
}

export const Jewelrytype = [
  {name: "主戒", type: 1},{name: "副戒", type: 2},
  {name: "套戒", type: 3},{name: "项链", type: 4},
  {name: "吊坠", type: 12},{name: "耳饰", type: 6},
  {name: "手链", type: 7},{name: "手镯", type: 8},
  {name: "胸针", type: 9},{name: "发箍", type: 10},
  {name: "配件", type: 11},{name: "套装", type: 14},
  {name: "其他", type: 13}
]
export const hrefs = {
  local: 'https://daily.api.miaoerp.com',
  // local: 'http://192.168.31.140:8088',
  daily: 'https://daily.api.miaoerp.com',
  // prod: 'https://api.miaoerp.com'
  prod: '/saas-api'
}[STAGE]

export function isChrome () {
  return /Chrome/.test(navigator.userAgent) &&
    /Google Inc/.test(navigator.vendor) &&
    !/QQBrowser/.test(navigator.userAgent) &&
    !/Edg/.test(navigator.userAgent) &&
    !/SE 2.X/.test(navigator.userAgent);
}

declare const window: any;

export function isMobile () {
  return /Mobile/.test(navigator.userAgent) && !/Pad/.test(navigator.userAgent)
}

export function isNumber(v) {
  return /^(?:[0-9]\.|[1-9][0-9]+\.|\.[0-9]|[0-9])[0-9]*$/.test(v)
}

function isEmptyObject(v) {
  const keys = Object.keys(v);

  return keys.every(k => isEmptyInput(v[k]));
}

export function isEmptyInput(v) {
  if (!v) return true

  if (typeof v === 'object') return isEmptyObject(v);
  return false
}

export function trim(query) {
  const result = {}

  for (let key in query) {
    const v = query[key]

    result[key] = typeof v === 'string' ? v.trim().replace(/\0/g,'') : v;
  }

  return result
}

export function filterEmptyStr(query) {
  const result = {}

  for (let key in query) {
    const v = query[key]

    result[key] = v === '' ? undefined : v;
  }

  return result
}

export function formatSize(record, scale = 100) {
  if (record?.diameter) return new Big(record?.diameter).div(scale).toJSON()
  if(record?.min || record?.max){
    const size = [
      record?.min ? new Big(record?.min).div(scale).toJSON() : '',
      record?.max ? new Big(record?.max).div(scale).toJSON() : ''
    ];
    return size.filter(v => !!v).length ? size.join('-') : ''
  }
  if(record?.minSize || record?.maxSize){
    const size = [
      record?.minSize ? new Big(record?.minSize).div(scale).toJSON() : '',
      record?.maxSize ? new Big(record?.maxSize).div(scale).toJSON() : ''
    ];
    return size.filter(v => !!v).length ? size.join('-') : ''
  }
  const size = [];
  if (record?.length) {
    size.push(new Big(record?.length).div(scale).toJSON())
  }
  if (record?.width) {
    size.push(new Big(record?.width).div(scale).toJSON())
  }
  if (record?.thickness) {
    size.push(new Big(record?.thickness).div(scale).toJSON())
  }

  return size.filter(v => !!v).length ? size.join('*') : ''
}
export function formatAllSize(record, scale = 100) {
  if (record?.diameter) return new Big(record?.diameter).div(scale).toJSON()
  const size = [];
  if (record?.length) {
    size.push(new Big(record?.length).div(scale).toJSON())
  }
  if (record?.width) {
    size.push(new Big(record?.width).div(scale).toJSON())
  }
  if (record?.thickness) {
    size.push(new Big(record?.thickness).div(scale).toJSON())
  }

  return size.filter(v => !!v).length ? size.join('*') : ''
}

export function amountTreatment(val, num?:number){
  return val && typeof val == 'number' ? new Big(val).div(num || 100).toJSON() : null;
}

export function amounttimesTreatment(val, num?:number){
  return val && typeof val == 'number' ? new Big(val).times(num || 100).toJSON() : null;
}
// 时间特殊处理
export function timeSpecialProcessing(text) {
  return text ? moment(text).format('YYYYMMDD').slice(2, 8) : null
}
// 时间处理
export function timeProcessing(text) {
  return text ? moment(text).format('YYYY.MM.DD HH:mm:ss') : null
}

// 时间处理
export function timesProcessing(text) {
  return text ? moment(text).format('YYYY.MM.DD') : null
}

export function dateProcessing(text) {
  return text ? moment(text).format('MM.DD') : null
}

export function formatWarehouse(warehouse) {
  const warehouseName = warehouse?.[0]?.name;
  const cabinetName = warehouse?.[1]?.name
  const items = [];

  if (warehouseName) {
    items.push(warehouseName)
  }

  if (cabinetName) {
    items.push(cabinetName)
  }

  return items.join('-')
}

export function setTextStyle(context, props) {
  const {
    fontSize,
    fontStyle,
    fontFamily,
    scale,
  } = props
  context.textBaseline = 'top';
  context.textAlign = 'start';
  context.font = `${(fontStyle || []).indexOf('bold') > -1 ? 'bold' : 'normal'} ${scale(fontSize)} ${fontFamily || 'auto'}`;
}

export function measureText(props) {
  const padding = 8
  const offscreenCanvas = document.createElement('canvas');
  const offscreenContext = offscreenCanvas.getContext('2d');

  setTextStyle(offscreenContext, props)

  const {
    width,
    actualBoundingBoxDescent,
    actualBoundingBoxAscent,
  } = offscreenContext.measureText(props.value);

  return {
    width: width + padding,
    height: padding + actualBoundingBoxDescent - actualBoundingBoxAscent,
    x: padding / 2,
    y: padding / 2,
  }
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const globalLoading = (() => {
  let show = true;

  return {
    prevent(fn) {
      return (...argu) => {
        let result
        show = false;
        result = fn(...argu)
        Promise.resolve().then(() => {
          show = true;
        })
        return result
      }
    },
    get show() {
      return show
    }
  }
})()

export function staticTime(startTime, endTime) {
  return {
    [startTime]: moment().startOf('month').format('YYYY-MM-DD'), 
    [endTime]: moment().format('YYYY-MM-DD')
  }
}

export function getTimeStamp(state, time) {
  return moment()?.month(moment().month())?.[state](time)?.valueOf()
}

export function getAccurateTime(state, time) {
  return moment()?.month(moment().month())?.[state](time)
}

//  Echarts 数据处理
export function setDataGroup(data, val) {
  let datas = []
  const operatorCounts = data ? data.concat() : []
    operatorCounts.sort((a, b) => b[val] - a[val])
    if (operatorCounts.length == 0) {
      return datas
    } else {
      let datas =  operatorCounts.map((v, i) => {
        if (i <= 11) {
          return {...v, isSet: 1}
        } else {
          return {...v, isSet: 2}
        }
      })
      return datas       
    }
}

export function sortDownDate(arr, arg) {
  return arr.sort(function(a, b) {
    return Date.parse(b[arg]) - Date.parse(a[arg]);
  })
}

export function setSurveyData(data, arr) {
  let datas = [...arr]
  arr?.map((item, index) => {
    let obj = {}
    for(var key in data) {
      if(item.key == key) {
        obj = {
          ...item,
          children: sortDownDate(data[key], 'time')
        }
       datas[index] = obj
      }
    }
  })
  return datas
}

// 审核状态
export function auditStatus(id) {
  switch(id) {
    case 0:
      return '审核中'
      break;
    case 1:
      return '通过'
      break;
    case 2:
      return '拒绝'
      break;
    case 3:
      return '撤销'
      break;
    default:
      return ''
      break;
  }
}

// 商品状态
export function commodityStatus(id) {
  switch(id) {
    case 0:
      return '待上架'
      break;
    case 1:
      return '上架'
      break;
    case 2:
      return '下架'
      break;
    case 3:
      return '删除'
      break;
    case 4:
      return '锁定'
      break;
    default:
      return ''
      break;
  }
}

const roles = [{key: 0, name: '系统管理员'},{key: 1, name: '商户'},{key: 2, name: '供应商'},{key: 3, name: '进销存'},{key: 4, name: '运营'}]
const types = [{key: 0, name: '进销存'},{key: 1, name: '小程序'}]
export function roleType(id,arr) {
  const data = id == 1 ? roles : types;
  let str = '';
  data && data?.map(item => {
    if(arr?.indexOf(item.key) > -1){
      if (item.key == 2 && arr?.indexOf(5) > -1) {
        str += item?.name + '(仅供壹佰),'
      } else {
        str += item?.name + ','
      }
    }
  })
  
  return str.substring(0,str.length-1)
}

const userDemandsTypes = [{key: 0, name: '进销存'},{key: 1, name: '小程序'}]
export function demandType(arr) {
  let str = '';
  userDemandsTypes.map(item => {
    if(arr?.indexOf(item.key) > -1){
      str += item?.name + ','
    }
  })
  
  return str.substring(0,str.length-1)
}

export const systemRole = [{key: 0, name: '系统管理员'},{key: 1, name: '商户'},{key: 2, name: '供应商'}, {key: 5, name: '仅供壹佰'},{key: 3, name: '进销存'},{key: 4, name: '运营'}]
export const epsSource = [{key: 1, name: '展会'},{key: 2, name: '渠道'},{key: 3, name: '运营中心'},{key: 4, name: '业务自拓'},{key: 5, name: 'AI外呼'},{key: 6, name: '其他'}]
export const followUp = [{key: 1, name: '初步沟通'},{key: 2, name: '已演示系统'},{key: 3, name: '已报价'},{key: 4, name: '合作洽谈'},{key: 5, name: '合同签订'},{key: 6, name: '回款'}]
export const shop = [{key: 0, name: '门店'},{key: 1, name: '工厂'},{key: 2, name: '微商'},{key: 3, name: '电商'},{key: 4, name: '工作室'},{key: 5, name: '其他'}]
export const discount = ['0.70', '0.69', '0.68', '0.67','0.66', '0.65', '0.64', '0.63', '0.62', '0.61', '0.60']
export const rebate = ['1.07', '1.06', '1.04', '1.03','1.01', '1', '0.98', '0.96', '0.95', '0.93', '0.92']
export function handleSpecialEnlarge(src) {
  var image = new Image(); 
  image.src = src;
  var viewer = new Viewer(image, {
    title: function () {
      return ''
    },
    hidden: function () {
      viewer.destroy();
    },
    // viewed: function () {
    //   viewer.zoomTo(0.50, false);
    // },
    navbar: false,
    movable: true,
    minZoomRatio: 0.30,
    maxZoomRatio: 1,
    toolbar: {
      zoomIn: 4,
      zoomOut: 4,
      oneToOne: 4,
      reset: 4,
      rotateLeft: 4,
      rotateRight: 4,
    },
  });
  viewer.show();
}

export function handleEnlarge(src) {
  var image = new Image(); 
  image.src = src;
  var viewer = new Viewer(image, {
    title: function () {
      return ''
    },
    hidden: function () {
      viewer.destroy();
    },
    navbar: false,
    movable: false,
    minZoomRatio: 0.50,
    maxZoomRatio: 1,
    toolbar: {
      zoomIn: 4,
      zoomOut: 4,
      oneToOne: 4,
      reset: 4,
      rotateLeft: 4,
      rotateRight: 4
    }
  });
  viewer.show();
}

export function departValueFromDate(fn) {
  return function(e) {
    return fn(e.format('YYYY-MM-DD'))
  }
}

// 计算成本
export function computeCost(v, numKey?, weightKey?) {
  let totalCost

  if (typeof v?.grainCost === 'number') {
    if (v?.[numKey || 'outputNum']) {
      totalCost = new Big(v?.grainCost).div(100).mul(v?.[numKey || 'outputNum']).toFixed(2)
    }
    if (v?.[weightKey || 'outputWeight']) {
      totalCost = new Big(v?.grainCost).div(100).mul(v?.[weightKey || 'outputWeight']).toFixed(2)
    }
  }

  return totalCost
}
// 计算价格
export function computePrice(v, numKey?, weightKey?) {
  let totalPrice

  if (v?.price) {
    if (v?.[numKey || 'outputNum']) {
      totalPrice = new Big(v?.price).mul(v?.[numKey || 'outputNum']).toFixed(2)
    }
    if (v?.[weightKey || 'outputWeight']) {
      totalPrice = new Big(v?.price).mul(v?.[weightKey || 'outputWeight']).toFixed(2)
    }
  }

  return totalPrice
}
// 计算总数量
export function getTotalNum(commodities, numKey?) {
  const items = commodities.filter(v => !isNaN(Number(v?.[numKey || 'outputNum'])))

  return items.length ? items.reduce((acc, v) => new Big(v[numKey || 'outputNum']).add(acc).toJSON(), 0) : undefined
}
// 计算总重量
export function getTotalWeight(commodities, weightKey?) {
  const items = commodities.filter(v => !isNaN(Number(v?.[weightKey || 'outputWeight'])))

  return items.length ? items.reduce((acc, v) => new Big(v[weightKey || 'outputWeight']).add(acc).toJSON(), 0) : undefined
}
// 计算总成本
export function getTotalCost(commodities, numKey?, weightKey?) {
  const items = commodities.filter(v => !isNaN(Number(v?.grainCost)) && !isNaN(Number(v?.[numKey || 'outputNum'] || v?.[weightKey || 'outputWeight'])))

  return items.length ? items.reduce((acc, v) => new Big(v.grainCost).div(100).mul(v[numKey || 'outputNum'] || v[weightKey || 'outputWeight']).add(acc).toJSON(), 0) : undefined
}
// 计算总价格
export function getTotalPrice(commodities, numKey?, weightKey?) {
  const items = commodities.filter(v => !isNaN(Number(v?.price)) && !isNaN(Number(v?.[numKey || 'outputNum'] || v?.[weightKey || 'outputWeight'])))

  return items.length ? items.reduce((acc, v) => new Big(v.price).mul(v[numKey || 'outputNum'] || v[weightKey || 'outputWeight']).add(acc).toJSON(), 0) : undefined
}

// 计算剩余待还
export function computedSurplus(record) {
  if (record?.rentNum) {
    return (record.rentNum || 0) - (record?.returnNum || 0) - (record?.outputNum || 0)
  } else if (record?.rentWeight) {
    return new Big((record.rentWeight || 0) - (record?.returnWeight || 0) - (record?.outputWeight || 0)).div(10000).toJSON() + 'ct'
  }
}

export function accAdd(arg1,arg2){
  var r1,r2,m;
  try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0};
  try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0};
  m=Math.pow(10,Math.max(r1,r2));
  return (arg1*m+arg2*m)/m;
}

export function accMul(arg1,arg2) {
  var m=0,s1=arg1.toString(),s2=arg2.toString();
  try{m+=s1.split(".")[1].length}catch(e){};
  try{m+=s2.split(".")[1].length}catch(e){};
  return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m);
}




export function formatDuring(mss:any) {
  var days = parseInt(mss / (1000 * 60 * 60 * 24));
  var hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = ((mss % (1000 * 60)) / 1000).toFixed(0);
  return days + "天" + hours + "时" + minutes + "分" + seconds + "秒";
}

export function handleColumns(myColumns, columnData, pageType?, lastMinWidth?) {
  return myColumns?.map((item, index) => {
    if (lastMinWidth) {
      return {
        ...item,
        hidden: columnData.find(v => v.dataIndex === item.dataIndex)?.hidden || item.hidden,
        width: item.dataIndex === columnData?.[columnData.length - 1]?.dataIndex && Number((columnData.find(v => v.dataIndex === item.dataIndex)?.width || item.width)) < Number(lastMinWidth) ? lastMinWidth : (
          columnData.find(v => v.dataIndex === item.dataIndex)?.width || item.width
        ),
        fixed: columnData.find(v => v.dataIndex === item.dataIndex)?.fixed || item.fixed,
      }
    } else {
      return {
        ...item,
        hidden: columnData.find(v => v.dataIndex === item.dataIndex)?.hidden || item.hidden,
        width: columnData.find(v => v.dataIndex === item.dataIndex)?.width || item.width,
        fixed: columnData.find(v => v.dataIndex === item.dataIndex)?.fixed || item.fixed,
      }
    }
  }).sort((a, b) => columnData.findIndex(v => v.dataIndex === a.dataIndex) - columnData.findIndex(v => v.dataIndex === b.dataIndex))
}

export const myCustomizeNameInfo = {
  stone: {
    options: [
      { label: '商品品类', value: 'catName', example: '钻石' },
      { label: '形状', value: 'shape', example: '心形' },
      { label: '颜色', value: 'color', example: '粉色' },
      { label: '净度', value: 'clarity', example: 'FL/IF' },
      { label: '切工', value: 'cutting', example: 'EX' },
      { label: '优化', value: 'optimize', example: '无烧' },
      { label: '抛光', value: 'polishing', example: 'EX' },
      { label: '对称', value: 'symmetry', example: 'EX' },
      { label: '荧光', value: 'fluorescent', example: 'N' },
      { label: '产地', value: 'origin', example: '哥伦比亚' },
      { label: '证书', value: 'cert', example: 'GIA' },
      { label: '重量', value: 'weight', example: '0.88ct' },
      { label: '尺寸', value: 'size', example: '3.2*1.5*0.8' },
    ],
    keys: [],
    switch: false,
  },
  pearl: {
    options: [
      { label: '商品品类', value: 'catName', example: '淡水珍珠' },
      { label: '珠色', value: 'pearlColor', example: '纯白色' },
      { label: '形状', value: 'shape', example: '圆形' },
      { label: '圆度', value: 'roundness', example: '正圆A1' },
      { label: '珠层厚度', value: 'beadThickness', example: 'C' },
      { label: '证书', value: 'cert', example: 'NGTC' },
      { label: '重量', value: 'weight', example: '2.88g' },
    ],
    keys: [],
    switch: false,
  },
  product: {
    options: [
      { label: '商品品类', value: 'catName', example: '戒指' },
      { label: '规格', value: 'standard', example: '15' },
      { label: '金属颜色', value: 'metalColor', example: '18k玫瑰金' },
      { label: '金属材质', value: 'layerMetalColor', example: '黄金' },
      { label: '证书', value: 'cert', example: 'GDTC' },
      { label: '主石品类', value: 'masterCatName', example: '红宝石' },
      { label: '主石形状', value: 'masterShape', example: '圆形' },
      { label: '主石颜色', value: 'masterColor', example: '鸽血红' },
      { label: '主石净度', value: 'masterClarity', example: 'FL/IF' },
      { label: '主石切工', value: 'masterCutting', example: 'EX' },
      { label: '主石优化', value: 'masterOptimize', example: '无烧' },
      { label: '主石抛光', value: 'masterPolishing', example: 'EX' },
      { label: '主石对称', value: 'masterSymmetry', example: 'EX' },
      { label: '主石荧光', value: 'masterFluorescent', example: 'N' },
      { label: '主石产地', value: 'masterOrigin', example: '缅甸' },
      { label: '主石珠色', value: 'masterPearlColor', example: '纯白色' },
      { label: '主石圆度', value: 'masterRoundness', example: '正圆A1' },
      { label: '主石珠层厚度', value: 'masterBeadThickness', example: 'B' },
      { label: '主石证书', value: 'masterCert', example: 'NGTC' },
      { label: '主石重量', value: 'masterWeight', example: '0.88ct' },
    ],
    keys: [],
    switch: false,
  },
  basic: {
    options: [
      { label: '商品品类', value: 'catName', example: '蓝宝石' },
      { label: '形状', value: 'shape', example: '公主方' },
      { label: '颜色', value: 'color', example: '1#' },
      { label: '净度', value: 'clarity', example: 'A+' },
      { label: '切工', value: 'cutting', example: 'EX' },
      { label: '产地', value: 'origin', example: '斯里兰卡' },
      { label: '重量', value: 'weight', example: '18.88ct' },
      { label: '尺寸', value: 'size', example: '0.88~2.88' },
    ],
    keys: [],
    switch: false,
  }
}

export const factoryOrderPerliteInfo = {
  master: {
    options: [
      { label: '品类', value: 'catName', example: '钻石' },
      { label: '形状', value: 'shape', example: '心形' },
      { label: '颜色', value: 'color', example: '黑色' },
      { label: '尺寸', value: 'size', example: '0.5*0.5' },
      { label: '重量', value: 'weight', example: '0.88ct' },
      { label: '产地', value: 'origin', example: '哥伦比亚' },
      { label: '净度', value: 'clarity', example: 'FL/IF' },
      { label: '切工', value: 'cutting', example: 'EX' },
      { label: '优化', value: 'optimize', example: '无烧' },
      { label: '抛光', value: 'polishing', example: 'EX' },
      { label: '对称', value: 'symmetry', example: 'EX' },
      { label: '荧光', value: 'fluorescent', example: 'N' },
      { label: '珠色', value: 'pearlColor', example: '纯白色' },
      { label: '圆度', value: 'roundness', example: '正圆A1' },
      { label: '珠层厚度', value: 'beadThickness', example: 'B' },
      { label: '证书', value: 'cert', example: 'GIA' },
      { label: '来源', value: 'stoneStatus', example: '未配-客来石' },
    ]
  },
  slave: {
    options: [
      { label: '品类', value: 'catName', example: '钻石' },
      { label: '形状', value: 'shape', example: '心形' },
      { label: '颜色', value: 'color', example: '黑色' },
      { label: '尺寸', value: 'size', example: '0.5*0.5' },
      { label: '重量', value: 'weight', example: '0.88ct' },
      { label: '产地', value: 'origin', example: '哥伦比亚' },
      { label: '净度', value: 'clarity', example: 'FL/IF' },
      { label: '切工', value: 'cutting', example: 'EX' },
      { label: '优化', value: 'optimize', example: '无烧' },
      { label: '抛光', value: 'polishing', example: 'EX' },
      { label: '对称', value: 'symmetry', example: 'EX' },
      { label: '荧光', value: 'fluorescent', example: 'N' },
      { label: '珠色', value: 'pearlColor', example: '纯白色' },
      { label: '圆度', value: 'roundness', example: '正圆A1' },
      { label: '珠层厚度', value: 'beadThickness', example: 'B' },
      { label: '证书', value: 'cert', example: 'GIA' },
      { label: '来源', value: 'stoneStatus', example: '未配-客来石' },
    ]
  }
}

export function getPerliteName(perlite, composeRule = [], masterType) {
  const myPerliteName = [masterType === 'master' ? '主石：' : '副石：']
  composeRule.forEach(v => {
    if (v === 'catName') {
      myPerliteName.push(perlite?.catName || '')
    } else if (v === 'size') {
      if (perlite?.diameter) {
        myPerliteName.push(new Big(perlite?.diameter).div(100).toJSON())
      } else if (perlite?.minSize || perlite?.maxSize) {
        const mySize = []
        perlite?.minSize && mySize.push(new Big(perlite?.minSize).div(100).toJSON())
        perlite?.maxSize && mySize.push(new Big(perlite?.maxSize).div(100).toJSON())
        myPerliteName.push(mySize.join('~'))
      } else if (perlite?.length || perlite?.width || perlite?.thickness) {
        const mySize = []
        perlite?.length && mySize.push(new Big(perlite?.length).div(100).toJSON())
        perlite?.width && mySize.push(new Big(perlite?.width).div(100).toJSON())
        perlite?.thickness && mySize.push(new Big(perlite?.thickness).div(100).toJSON())
        myPerliteName.push(mySize.join('*'))
      }
    } else if (v === 'weight') {
      // 单位因为数据没有告诉是裸石还是裸珠无法加上
      perlite?.weight && myPerliteName.push(new Big(perlite?.weight).div(10000).toJSON() + (perlite?.epsCommodityType == 0 ? 'ct' : 'g'))
    } else if (v === 'stoneStatus') {
      (perlite?.stoneStatus || perlite?.stoneStatus == 0) && myPerliteName.push(['已配-客来石', '未配-客来石', '厂配']?.[perlite?.stoneStatus])
    } else if (v === 'pearlColor') {
      perlite?.detailFeatures?.pearlColor && myPerliteName.push(perlite?.detailFeatures?.pearlColor)
    } else if (v === 'beadThickness') {
      perlite?.detailFeatures?.beadThickness && myPerliteName.push(perlite?.detailFeatures?.beadThickness)
    } else {
      perlite?.detailFeatures?.[v] && myPerliteName.push(perlite?.detailFeatures?.[v])
    }
  })

  return myPerliteName.join(' ')
}

export const msgStatus = [
  { name: '全部消息', value: 0, num: 0 },
  { name: '未读消息', value: 1, num: 0 },
  { name: '已读消息', value: 2, num: 0 },
]

export const newsTypes = [
  { name: '全部类型消息', value: -1, num: 0 },
  { name: '工单消息', value: 0, num: 0, pageName: '工单进度' },
  { name: '维修消息', value: 1, num: 0, pageName: '维修进度' },
  { name: '调拨消息', value: 2, nun: 0, pageName: '调拨进度' }
]

export const newsRouteReducer = [
  { routeUrl: '/factory_schedule', pageParam: setNewsFactoryOrderNo },
  { routeUrl: '/factory_repair_ticket', pageParam: setNewsRepairCommodityNo },
  { routeUrl: '/commodity_transfer_query', pageParam: setNewsCommodityTransferNo },
]

export function computeDataList(data = [], current, pageSize, nameKey = 'name', name = '') {
  const startIndex = (current - 1) * pageSize
  const endIndex = current * pageSize

  if (name) {
    return data.filter(v => v?.[nameKey]?.indexOf(name) > -1).slice(startIndex, endIndex)
  } else {
    return data.slice(startIndex, endIndex)    
  }
}

export function commoditiesTransZb100Print(commodities, userInfo) {
  const printCommodities = commodities.map((v, index) => {
    // 只需要支持裸石商品打印(跨境只有裸石和成品，壹佰入库实际无成品入库)
    // 很多字段没有，但打印好像也不需要，待同喜验证
    const statusDesc = ['下架', '正常', '返场', '盘亏', '借出']
    const myCaratCost = v.grainCost && v.weight ? new Big(v.grainCost).div(v.weight).times(10000).toJSON() : v.caratCost
    const myCaratPrice = v.labelPrice && v.weight ? new Big(v.labelPrice).div(v.weight).times(10000).toJSON() : v.caratPrice
    return delEmpty({
      ...v,
      canMerge: false,
      caratCost: myCaratCost,
      caratPrice: myCaratPrice,
      catId: v.catId,
      catName: v.catName,
      clarityId: v?.detailFeatures?.clarityId,
      colorId: v?.detailFeatures?.colorId,
      commodityCost: '', //待确定
      commodityId: v?.epsCommodityNo || v?.epsCommodityId, //待确定是epsCommodityId 还是 epsCommodityNo
      commodityWeight: v?.weight,
      cost: v.grainCost,
      cuttingId: v?.detailFeatures?.cuttingId,
      detailFeatures: delEmpty({
        ...(v?.detailFeatures || {}),
        color: v?.detailFeatures?.color,
        shape: v?.detailFeatures?.shape,
        clarity: v?.detailFeatures?.clarity,
        origin: v?.detailFeatures?.origin,
        minWeight: v.weight, //裸石无最小重量
        diameter: v.diameter,
        length: v.length,
        thickness: v.thickness,
        cutting: v?.detailFeatures?.cutting,
        originAlias: v?.detailFeatures?.origin, //无别名
        width: v.width,
        colorAlias: v?.detailFeatures?.color, //无别名
        shapeAlias: v?.detailFeatures?.shape, //无别名
        maxWeight: v.weight,  //裸石无最大重量
      }),
      detailNo: v.detailEntryNo, //无入库单号
      entryNo: v.entryNo, //无入库单号
      id: v.id || v.epsCommodityId,
      name: v.epsCommodityName,
      originId: v?.detailFeatures?.originId,
      printFeatures: delEmpty({
          caratCost: myCaratCost,
          caratPrice: myCaratPrice,
          catEngName: '', //无英文名
          catName: v.catName,
          clarity: v?.detailFeatures?.clarity,
          color: v?.detailFeatures?.color,
          colorAlias: v?.detailFeatures?.color, //无别名
          commodityId: v?.epsCommodityNo || v?.epsCommodityId, //待确定是epsCommodityId 还是 epsCommodityNo
          cutting: v?.detailFeatures?.cutting,
          diameter: v.diameter,
          length: v.length,
          thickness: v.thickness,
          origin: v?.detailFeatures?.origin,
          originAlias: v?.detailFeatures?.origin, //无别名
          shape: v?.detailFeatures?.shape,
          shapeAlias: v?.detailFeatures?.shape, //无别名
          width: v.width,
          editedPrice: '', //待确定
          price: myCaratPrice ? '¥' + myCaratPrice : myCaratPrice,
          priceUnit: "/CT",
          size: v.length || v.width ? [v.length || '', v.width || ''].join('*') : '',
          grainWeight: v.weight,
      }),
      remainQuantity: '', //待确定
      remark: v.remark,
      shapeId: v?.detailFeatures?.shapeId,
      staffId: userInfo.userId,
      staffName: userInfo.staffName,
      status: v.status,
      statusDesc: statusDesc[v.status],
      stoneNo: '', //无
      supplierId: v.epsSupplierId,
      supplierName: v.epsSupplierName,
      type: "裸石入库",
      units: 0,
      utcCreate: v.utcCreate, //无utcCreate
      warehouseId: v?.epsWarehouseCommodities?.[0]?.epsWarehouseId,
      warehouseName: v?.epsWarehouseCommodities?.[0]?.epsWarehouseName,
      weight: v.weight,
      color: v?.detailFeatures?.color,
      shape: v?.detailFeatures?.shape,
      clarity: v?.detailFeatures?.clarity,
      origin: v?.detailFeatures?.origin,
      minWeight: v.weight, //无minWeight
      diameter: v.diameter,
      length: v.length,
      thickness: v.thickness,
      cutting: v?.detailFeatures?.cutting,
      originAlias: v?.detailFeatures?.origin, //无别名
      width: v.width,
      colorAlias: v?.detailFeatures?.color, //无别名
      shapeAlias: v?.detailFeatures?.shape, //无别名
      maxWeight: v.weight, //无maxWeight
    })
  })

  return printCommodities
}

export const zbHerfs = {
  local: 'https://daily.admin.zb100.com/api',
  daily: 'https://daily.admin.zb100.com/api',
  prod: '/puregold-api'
}[STAGE]



