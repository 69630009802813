import fetch from './index'

export function getDesignersList(params): any {
  return fetch.get('/designs/designers', { params })
}

export function delDesigner(id) {
  return fetch.delete(`/designs/designer/${id}`)
}

export function addDesigner(params) {
  return fetch.post('/designs/designer', params)
}

export function editDesigner(params) {
  return fetch.put('/designs/designer', params)
}