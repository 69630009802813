import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getExportsList as getExportsListAPI
} from '../services/exports'

export const getExportsList = createAsyncThunk<any, any, any>(
  'exports/list',
  async (params) => {
    const data = await getExportsListAPI(params)
    return data || []
  }
)

const exportSlice = createSlice({
  name: 'export',
  initialState: {
    exports: []
  },
  reducers: {
  },
  extraReducers: {
    [getExportsList.fulfilled as any]: (state, action) => {
      state.exports = action.payload
    },
    [getExportsList.rejected as any]: (state, action) => {
      state.exports = []
    }
  }
})

const {
  reducer
} = exportSlice

export default reducer