import fetch from './index'
// 采购退货列表
export function getsupperList(params): any {
  return fetch.post('/supplier/returns/list', params )
}

// 退货单详情
export function getsupperDetail(params): any {
  return fetch.get('/supplier/returns/detail/list', {
    params
  })
}

// 主退货单导出
export function getmasterReturnorder(params): any {
  return fetch.post('/export/records/supplier/returns', params)
}

//  子退货单导出
export function getSupperexport(params): any {
  return fetch.get(`/export/records/supplier/returns/detail`, {
    params
  })
}

export function getReturnsCommodities(params): any {
  return fetch.post('/supplier/returns/commodities', params)
}

export function postReturns(params) {
  return fetch.post('/supplier/returns', params)
}

export function batchReturn(params) {
  return fetch.post('/supplier/returns/batch/return', params)
}
