import fetch from './index'

// 主入库单表
export function getentriesList(params): any {
  return fetch.post('/storage/entries/list', params)
}

// 子入库单表
export function getentriesdetailList(params): any {
  return fetch.get('/storage/entries/detail/list', {
    params
  })
}

// 子入库单编辑
export function getstockEditing(params): any {
  return fetch.put('/storage/entries/detail', params)
}

// 主入库单导出
export function getmasterOrder(params): any {
  return fetch.post("/export/records/storage/entries", params)
}

// 子入库单导出
export function getWarehouse(params): any {
  return fetch.get(`/export/records/storage/entries/detail?entryNo=${params?.entryNo}&type=${params?.type}`)
}
// 获取审核配置
export function getAuditSetting(): any {
  return fetch.get('/eps/audit/setting')
}
// 保存审核配置
export function editAuditSetting(params): any {
  return fetch.post('/eps/audit/setting', params)
}
// 获取审核人列表
export function getStaffEntryReviewer(): any {
  return fetch.get('/staffs/input/entry/reviewer')
}
// 编辑主入库单
export function postStorageEntryUpdate(params): any {
  return fetch.post('/storage/entries/update', params)
}
export function getStorageEntryUpdate(params): any {
  return fetch.get('/storage/entries/update', { params })
}
// 审核主入库单
export function postStorageEntryAudit(entryNo, audit): any {
  return fetch.post(`/storage/entries/audit?entryNo=${entryNo}&audit=${audit}`, {})
}
// 撤销主入库单
export function deleteStorageEntryAudit(entryNo): any {
  return fetch.delete(`/storage/entries/delete?entryNo=${entryNo}`)
}
// 编辑入库单主单备注
export function editEntryMainRemark(params): any {
  return fetch.put('/storage/entries/main/remark', params)
}


