import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { connectAdvanced } from 'react-redux'

import {
  getsupperList as getList,
  getsupperDetail as getdetailList,
  getsupperListOrder as getsupperOrderList
} from '../services/ordereturnquery'

  
export const getsupperList = createAsyncThunk<any, any, any>(
  'order/post',
  async (params, {dispatch}) => {
    dispatch(setOrderlValue({}))
    return await getList(params)
  }
)

export const getsupperListOrder = createAsyncThunk<any, any, any>(
  'order/post',
  async (params, {dispatch}) => {
    dispatch(setOrderlValue({}))
    return await getsupperOrderList(params)
  }
)

export const getsupperDetail = createAsyncThunk<any, any, any>(
  'order/get',
  async (params) => {
    return await getdetailList(params)
  }
)

  
const orderquery = createSlice({
  name: 'order',
  initialState: {
    order: {},
    orderdetail: {},
    category: []
  },
  reducers: {
    setCategory(state, action) {
      state.category = action.payload
    },
    setDetailValue(state, action) {
      state.orderdetail = action.payload
    },
    setOrderlValue(state, action) {
      state.order = action.payload
    }
  },
  
  extraReducers: {
    [getsupperList.fulfilled as any]: (state, action) => {
      state.order = action.payload
    },
    [getsupperListOrder.fulfilled as any]: (state, action) => {
      state.order = action.payload
    },
    [getsupperDetail.fulfilled as any]: (state, action) => {
      state.orderdetail = action.payload
    },
  }
})


const {
  actions,
  reducer
} = orderquery;

export const {
  setCategory,
  setDetailValue,
  setOrderlValue
} = actions

export default reducer