import fetch from './index'

// 销售退货列表
export function getsupperList(params): any {
  return fetch.post('/orders/returns/page/list', params)
}

export function getsupperListOrder(params):any {
  return fetch.post('/orders/returns/page/detail/list/new', params)
}

// 销售退货详情
export function getsupperDetail(params): any {
  return fetch.get(`/orders/returns/page/detail/list`, {
    params
  })
}

// 销售退货详情新列表
export function getSaleOutputDetailList(params): any {
  return fetch.get("/storage/outputs/detail/list/return", {
    params
  })
}

// 主退货单导出
export function getmasterReturnorder(params): any {
  return fetch.post('/export/records/orders/returns', params)
}

//  子退货单导出
export function getSupperexport(params): any {
  return fetch.post(`/export/records/orders/returns/detail`, params)
}

// 主单确认退款
export function setConfirmRefund(params, type): any {
  return fetch.put(`/orders/returns/main/refund/${params}?type=${type}`)
}

// 子单确认退款
export function setBulletConfirmation(params, type): any {
  return fetch.put(`/orders/returns/detail/refund/${params}?type=${type}`)
}

export function postOrderReturns(params) {
  return fetch.post('/orders/returns', params)
}

// 查询单个商品的详情
export function getProductDetails(id) {
  return fetch.get(`/eps/commodities/detail/${id}`)
}

// 批量查询商品详情
export function getCommoditiesDetails(params) {
  return fetch.post('/eps/commodities/detail/ids', params)
}

export function getOrderReturnInfo(params): any {
  return fetch.post(`/orders/returns/out/info`, params)
}

// 销售退货特殊备注查询
export function getSpecialRemark(params): any {
  return fetch.get('/order/return/special/remark', { params })
}
// 销售退货特殊备注新增
export function addSpecialRemark(params) {
  return fetch.post('/order/return/special/remark', params)
}
// 销售退货特殊备注编辑
export function editSpecialRemark(params) {
  return fetch.put('/order/return/special/remark', params)
}
// 销售退货特殊备注删除
export function delSpecialRemark(id) {
  return fetch.delete(`/order/return/special/remark?id=${id}`)
}
// 批量导入销售退货
export function importOrderReturn(params): any {
  return fetch.post('/orders/returns/import', params)
}
// 销售退货主子单取消退货
export function cancelOrderReturn(no, type): any {
  return fetch.put(`/orders/returns/cancellation/order/${no}?type=${type}`)
}
// 销售退货子单编辑
export function editOrderReturnDetail(params) {
  return fetch.put('/orders/returns/update/detailRefund', params)
}
// 批量取消退货退款
export function batchReturnMainRefund(params): any {
  return fetch.post('/orders/returns/main/refunds', params)
}
// 销售退货子单编辑
export function editOrderReturnDetailAmount(params) {
  return fetch.put('/orders/returns/update/return/Price', params)
}