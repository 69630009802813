import fetch from './index'

// 获取属性列表
export function getCategoryFeatures(params): any {
  return fetch.get('/category/features', { params })
}

// 获取属性值列表
export function getFeatureValues(params): any {
  return fetch.get('/category/features/view', { params })
}

export function addFeature(params) {
  return fetch.post('/category/features', params)
}

export function editFeature(params) {
  return fetch.put('/category/features', params)
}

export function delFeature(id) {
  return fetch.delete(`/category/features/${id}`)
}

//校验属性值是否已关联货品，编辑时用
export function checkFeature(id) {
  return fetch.get(`/category/features/check?featureValueId=${id}`)
}

// 调整属性值顺序
export function putSortCategoryFeature(params) {
  return fetch.put('/category/features/sort', params)
}

// 获取默认属性值
export function getDefaultFeatureValues(params): any {
  return fetch.get('/category/features/default/value', { params })
}