import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getUserInfo as getUserInfoAPI,
} from '../services/users'

import {
  clear,
} from '../utils/session'
import history from '../utils/history'

export const getUserInfo = createAsyncThunk<any>(
  'user/info',
  async () => {
    const userInfo: any = await getUserInfoAPI()
    return userInfo
  }
)

export const logout = createAsyncThunk(
  'users/logout',
  async (...[, { dispatch }]) => {
    clear()
    history.push('/login')
    dispatch({
      type: 'USER_LOGOUT'
    })
  }
)

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userInfo: {},
  },
  reducers: {
  },
  extraReducers: {
    [getUserInfo.fulfilled as any]: (state, action) => {
      state.userInfo = action.payload
    },
  }
})

const {
  reducer,
} = userSlice;

export default reducer