import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getTagsList as getTagsListAPI,
  getuserList as getuserListAPI
} from '../services/selleruser'

export const getTagsList = createAsyncThunk<any, any, any>(
  'seller/tags',
  async ( params ) => {
    const data = await getTagsListAPI(params)
    return data || []
  }
)

export const getuserList = createAsyncThunk<any, any, any>(
  'user/tags',
  async ( params ) => {
    const data = await getuserListAPI(params)
    return data || []
  }
)


const tags = createSlice({
  name: 'tag',
  initialState: {
    taglist: {},
    userlist: {}
  },
  reducers: {
  },
  extraReducers: {
    [getTagsList.fulfilled as any]: (state, action) => {
      state.taglist = action.payload
    },
    [getuserList.fulfilled as any]: (state, action) => {
      state.userlist = action.payload
    }
  }
})

const {
  reducer
} = tags

export default reducer