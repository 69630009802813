import Big from 'big.js'
import { formatSize } from './index'
import ecStat from 'echarts-stat'
import moment from 'moment'

export function delEmpty(query, current?, pageSize?) {
  const params:any = {}
  if ( current || pageSize ) {
    params['nextCursor'] = current - 1
    params['pageSize'] = pageSize  
  }

  for (let key in query) {
    if (query[key] !== undefined && query[key] !== "" && query[key] !== null) {
      params[key] = query[key]
    }
  }
  return params
}

export function filterParams(params, keys = []) {
  const newParams:any = {}
  keys.forEach(v => {
    newParams[v] = params?.[v]
  })
  return delEmpty(newParams)
}

export function filterQuery(query, params, paramsKeys = []) {
  const newParams:any = {}
  paramsKeys.forEach(v => {
    newParams[v] = params?.[v]
  })

  return delEmpty({
    ...query,
    ...newParams,
  })
}

export function delSideTrim(query) {
  const params:any = {}
  for (let key in query) {
    if (query[key] !== undefined && query[key] !== "" && query[key] !== null) {
      if (typeof query[key] === 'string') {
        params[key] = query[key].trim()
      } else {
        params[key] = query[key]
      }
    }
  }
  return params
}

export function  delObjEmpty(query) {
  var obj = query;
  for (var i in obj) {
    var value = obj[i];
    if (typeof value === 'object') {
      if (Array.isArray(value)) {
          if (value.length == 0) {
              delete obj[i];
              continue;
          }
      }
      delObjEmpty(value);
    } else {
        if (value === '' || value === null || value === undefined) {
            delete obj[i];
        }
    }
  }
  return obj;
}

export function codeStr(text) {
  text = text.replace(/[\/]/g, 'abc')
  text = text.replace(/[\\]/g, 'def')
  return text
}

export function decodeStr(text) {
  text = text.replace(/abc/g, '/')
  text = text.replace(/def/g, '\\')
  return text
}

export function treeData(data) {
  return data.map(v => {
    v.key = v.id
    v.title = v.name
    return v
  })
}
// 处理长宽高
export function handleSize(length, width, thickness) {
  let sizeArr = []

  if (length) {
    sizeArr.push(new Big(length).div(100)) 
  }
  if (width) {
    sizeArr.push(new Big(width).div(100)) 
  }
  if (thickness) {
    sizeArr.push(new Big(thickness).div(100)) 
  }

  return sizeArr.join('*')
}
// 处理尺寸区间
export function handleSizeSection(minValue, maxValue) {
  if (minValue && maxValue) {
    return new Big(minValue).div(100) + '~' + new Big(maxValue).div(100)
  } else if (minValue) {
    return new Big(minValue).div(100) + '~'
  } else if (maxValue) {
    return '~' + new Big(maxValue).div(100)
  } else {
    return null
  }
}
// 处理重量区间
export function handleWeightSection(minValue, maxValue) {
  if (minValue && maxValue) {
    return new Big(minValue).div(10000) + '~' + new Big(maxValue).div(10000)
  } else if (minValue) {
    return new Big(minValue).div(10000) + '~'
  } else if (maxValue) {
    return '~' + new Big(maxValue).div(10000)
  } else {
    return null
  }
}

// export function computedMaxCurrent(pageSize, totalNum) {
//   return Math.ceil(totalNum/pageSize)
// }

export function handleExportInfos(options) {
  const myOptions = []
  for (let i in options) {
    myOptions.push({
      label: options[i],
      value: i
    })
  }
  return myOptions
}

export function handleProductPerlites(data = [], name, type, ratio?) {
  let text = ''

  for (let i in data) {
    if (data[i].masterSlaveType == type) {
      if (name == 'size') {
        text = formatSize(data[i])
      } else {
        text = data[i][name]
      }
    } 
    if (text) {
      break
    }
  }

  if (ratio && text) {
    text = new Big(text).div(ratio).toJSON()
  }

  return text
}

export function handleGetSlaveInfo(data = [], name, type) {
  let maxSort = 1
  let text = ''

  for (let i in data) {
    if (data[i].masterSlaveType == 1) {
      if (maxSort >= type && data[i][name]) {
        text = data[i][name]
      } else {
        maxSort += 1
      }
    }
    if (text || maxSort > type) {
      break
    }
  }

  return text
}

export function handleSlaveStone(data = [], name, sort, ratio?) {
  let maxSort = 1
  let text = ''

  for (let i in data) {
    if (data[i].masterSlaveType == 1) {
      if (maxSort >= sort && data[i][name]) {
        text = data[i][name]
      } else if (maxSort >= sort && name == 'size') {
        text = formatSize(data[i])
      } else {
        maxSort += 1
      }
    }
    if (text || maxSort > sort) {
      break
    }
  }

  if (ratio && text) {
    text = new Big(text).div(ratio).toJSON()
  }

  return text
}

export function handleMasterNum(data = []) {
  let num = 0

  for (let i in data) {
    if (data[i].masterSlaveType == 0) {
      num += 1
    }
  }

  return num
}

export function handleSlaveNum(data = []) {
  let num = 0

  for (let i in data) {
    if (data[i].masterSlaveType == 1) {
      num += 1
    }
  }

  return num
}

export function handleSkuList(data, name) {
  for (let i in data) {
    if (data[i]?.feature?.featureEngName == name) {
      return data[i]?.feature?.featureValues || []
    }
  }
  return []
}

export function handleMasterInfos(obj, name, value, option?) {
  let commodity = JSON.parse(JSON.stringify(obj))
  const sizeTypes = ['diameter', 'minSize', 'maxSize', 'length', 'width', 'thickness']

  for (let i in commodity.perlites) {
    if (commodity.perlites[i].masterSlaveType == 0) {
      if (name == 'perliteWeight') {
        commodity.perlites[i][name] = value ? new Big(value).times(10000).toJSON() : ''
      } else if (name == 'certNo') {
        commodity.perlites[i][name] = value
      } else if (name == 'cat') {
        commodity.perlites[i][name + 'Id'] = value
        commodity.perlites[i][name + 'Name'] = option.children
      } else if (sizeTypes.indexOf(name) != -1) {
        commodity.perlites[i][name] = value ? new Big(value).times(100).toJSON() : ''
      } else {
        commodity.perlites[i][name + 'Id'] = value
        commodity.perlites[i][name] = option.children
      }
    }
  }

  return commodity
}

export function handleSlaveInfos(obj, name, value, sort, option?) {
  let num = 1
  let commodity = JSON.parse(JSON.stringify(obj))

  if (name == 'perliteWeight') {
    for (let i in commodity.perlites) {
      if (commodity.perlites[i].masterSlaveType == 1) {
        if (num >= sort && (!isNaN(value) || value == '')) {
          commodity.perlites[i][name] = value ? new Big(value).times(10000).toJSON() : ''
          break
        } else {
          num += 1
        }
      }
    }
  } else if (name == 'cat') {
    for (let i in commodity.perlites) {
      if (commodity.perlites[i].masterSlaveType == 1) {
        if (num >= sort) {
          commodity.perlites[i][name + 'Id'] = value
          commodity.perlites[i][name + 'Name'] = option.children
          break
        } else {
          num += 1
        }
      }
    }
  } else if (name == 'certNo') {
    for (let i in commodity.perlites) {
      if (commodity.perlites[i].masterSlaveType == 1) {
        if (num >= sort) {
          commodity.perlites[i][name] = value
          break
        } else {
          num += 1
        }
      }
    }
  } else if (name == 'size') {
    for (let i in commodity.perlites) {
      if (commodity.perlites[i].masterSlaveType == 1) {
        if (num >= sort && (!isNaN(value) || value == '')) {
          commodity.perlites[i][name] = value ? new Big(value).times(100).toJSON() : ''
          break
        } else {
          num += 1
        }
      }
    }
  } else {
    for (let i in commodity.perlites) {
      if (commodity.perlites[i].masterSlaveType == 1) {
        if (num >= sort) {
          commodity.perlites[i][name + 'Id'] = value
          commodity.perlites[i][name] = option.children
          break
        } else {
          num += 1
        }
      }
    }
  }

  return commodity
}

export function getNearWeekTimes() {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  now.setHours(23);
  now.setMinutes(59);
  now.setSeconds(59);
  now.setMilliseconds(999);

  const endTime = now.getTime()

  return {
    startTime: endTime - 7 * oneDay + 1,
    endTime: endTime,
  }
}

export function getLineEchartOption(titleText, xAxisData, seriesData, seriesName, formatter) {
  return {
    title: {
      text: titleText,
      textStyle: {
        fontSize: 14,
        fontWeight: 500,
        color: '#131026'          
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer : {     // 坐标轴指示器，坐标轴触发有效
        type : 'line',    // 默认为直线，可选为：'line' | 'shadow'
        lineStyle: {
          color: '#1890ff',
          type: 'solid',
        }
      },
      formatter: formatter,
      textStyle: {
        border: 'none',
        fontSize: 12,
      }
    },
    legend: {
      data: ['']
    },
    grid: {
      top: '45px',   
      left: '5px', 
      right: '50px',
      bottom: '0',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      axisTick: {
        alignWithLabel: true,
        interval: 0,
      },
      axisLine :{
        lineStyle:{
          color: 'dcdee0'
        }
      }
    },
    yAxis: {},
    series: [{
      name: seriesName,
      type: 'line',
      symbol: 'circle',  // 拐点设置为实心
      symbolSize: 5,      // 拐点大小
      animation: true,
      itemStyle:{
        color: '#18caff',         //拐点颜色
        borderColor: '#1890ff',   //拐点边框颜色
        borderWidth: 1,           //拐点边框大小
      },
      emphasis: {
        itemStyle: {
          color: '#18caff',         //hover拐点颜色定义
          borderColor: '#1890ff',   //hover拐点边框颜色
          borderWidth: 1,
        }
      },
      data: seriesData,
    }]
  };
}

export function getLineEchartOption1(titleText, xAxisData, seriesData,seriesData1, seriesName, seriesName1) {
  return {
    title: {
      text: titleText,
      textStyle: {
        fontSize: 14,
        fontWeight: 500,
        color: '#131026'          
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer : {     // 坐标轴指示器，坐标轴触发有效
        type : 'line',    // 默认为直线，可选为：'line' | 'shadow'
        lineStyle: {
          color: '#1890ff',
          type: 'solid',
        }
      },
      // formatter: formatter,
      textStyle: {
        border: 'none',
        fontSize: 12,
      }
    },
    legend: {
      data: ['']
    },
    grid: {
      top: '45px',   
      left: '5px', 
      right: '50px',
      bottom: '0',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      axisTick: {
        alignWithLabel: true,
        interval: 0,
      },
      axisLine :{
        lineStyle:{
          color: 'dcdee0'
        }
      }
    },
    yAxis: {},
    series: [{
      name: seriesName,
      type: 'line',
      symbol: 'circle',  // 拐点设置为实心
      symbolSize: 5,      // 拐点大小
      animation: true,
      itemStyle:{
        color: '#18caff',         //拐点颜色
        borderColor: '#1890ff',   //拐点边框颜色
        borderWidth: 1,           //拐点边框大小
      },
      emphasis: {
        itemStyle: {
          color: '#18caff',         //hover拐点颜色定义
          borderColor: '#1890ff',   //hover拐点边框颜色
          borderWidth: 1,
        }
      },
      data: seriesData,
    },{
      name: seriesName1,
      type: 'line',
      symbol: 'circle',  // 拐点设置为实心
      symbolSize: 5,      // 拐点大小
      animation: true,
      itemStyle:{
        color: '#18caff',         //拐点颜色
        borderColor: '#1890ff',   //拐点边框颜色
        borderWidth: 1,           //拐点边框大小
      },
      emphasis: {
        itemStyle: {
          color: '#18caff',         //hover拐点颜色定义
          borderColor: '#1890ff',   //hover拐点边框颜色
          borderWidth: 1,
        }
      },
      lineStyle: {
        color: '#18CAFF',
        width: 2,
        type: 'dashed'
      },
      data: seriesData1,
    }]
  };
}

export function getNewTrendSeries(series) {
  const trendSeriesArr = []
  series.forEach(v => {
    trendSeriesArr.push(v)
    const mySalesData = v?.seriesData?.map((v, index) => {
      return [index, Number(v) || 0]
    })
    const regressionDemo = ecStat.regression('linear', mySalesData, 1)
    trendSeriesArr.push({
      seriesName: '',
      seriesData: regressionDemo.points,
      color: v?.color,
      border: v?.border,
      noStyle: true,
    })
  })
  return trendSeriesArr
}

export function getAccurateLineOption(titleText, xAxisData, seriesArr = [], formatter, myLegend = [], yAxisInfo?, legendInfo?, labelShow?) {
  return {
    title: {
      text: titleText,
      textStyle: {
        fontSize: 14,
        fontWeight: 500,
        color: '#131026'          
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer : {     // 坐标轴指示器，坐标轴触发有效
        type : 'line',    // 默认为直线，可选为：'line' | 'shadow'
        lineStyle: {
          color: '#1890ff',
          type: 'solid',
        }
      },
      formatter: (params) => {
        let res = `<h3 style="font-size:12px;line-height:12px;font-weight:400;color:#666666;">${params[0]?.name}</h3>`
        for ( let item of params ) {
          if ( item.seriesName !== '' ) {
            res += `<h3 style="font-size:12px;line-height:12px;margin-top:10px;color:#666666;display: flex;align-items: center;">
                      <span style="width:12px;height:12px;border-radius:6px;background:${item.color};margin: 1px 5px 0 0;display: inline-block;"></span>
                      <span style="flex: 1;display: flex;justify-content: space-between;"><span style="margin-right: 15px">${item.seriesName}</span><span>${item.value}</span></span>
                    </h3>`
          } else {
            res += ''
          }
        }
        return res
      },
      textStyle: {
        border: 'none',
        fontSize: 12,
      }
    },
    legend: {
      type: legendInfo?.scroll ? 'scroll' : 'plain',
      data: myLegend?.length ? myLegend : [''],
      width: legendInfo?.width || 'auto',
    },
    grid: {
      top: '45px',   
      left: '5px', 
      right: '50px',
      bottom: '0',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      axisTick: {
        alignWithLabel: true,
        interval: 0,
      },
      axisLine :{
        lineStyle:{
          color: 'dcdee0'
        }
      }
    },
    yAxis: {
      ...yAxisInfo,
      minInterval: yAxisInfo?.interval,
      maxInterval: yAxisInfo?.interval,
    },
    series: seriesArr.map((v, index) => {
      if (v?.noStyle) {
        return {
          name: v.seriesName,
          type: 'line',
          symbol: 'none',  // 拐点设置为实心
          symbolSize: 0,      // 拐点大小
          animation: true,
          itemStyle:{
            color: v.color,     // 趋势线颜色
            normal: {
              lineStyle: {
                width: 2,
                color: v.color, 
                type: 'dotted',   //'dotted'虚线 'solid'实线
              }
            }
          },
          data: v.seriesData,
          emphasis: {
            disabled: true,
            scale: false,
          }
        }
      } else {
        return {
          name: v.seriesName,
          type: 'line',
          symbol: 'circle',  // 拐点设置为实心
          symbolSize: 6,      // 拐点大小
          animation: true,
          itemStyle:{
            color: v.color,         //拐点颜色
            borderColor: v.borderColor,   //拐点边框颜色
            borderWidth: 1,           //拐点边框大小
          },
          emphasis: {
            itemStyle: {
              color: v.color,         //hover拐点颜色定义
              borderColor: v.borderColor,   //hover拐点边框颜色
              borderWidth: 1,
            },
          },
          data: v.seriesData,
          label: {
            show: labelShow && index == 0,
            position: 'top',
            formatter: (params) => {
              const myIndex = params?.dataIndex || 0
              const myValue = params?.value || params?.value == 0 ? params?.value : ''
              const myLength = v.seriesData?.length || 0
              const ceilNum = Math.ceil(myLength/16)

              if (myIndex%ceilNum == 0) {
                return myValue != 0 ? myValue : ''
              } else {
                return ''
              }
            },
          }
        }        
      }
    })
  };
}

export function getAccurateBarOption(titleText, xAxisData, seriesArr = [], formatter, myLegend = []) {
  return {
    title: {
      text: titleText,
      textStyle: {
        fontSize: 14,
        fontWeight: 500,
        color: '#131026'          
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer : {     // 坐标轴指示器，坐标轴触发有效
        type : 'shadow',    // 默认为直线，可选为：'line' | 'shadow'
        shadowStyle: {
          color: 'rgba(24, 149, 255, 0.2)'
        }
      },
      // formatter: formatter,
      textStyle: {
        border: 'none',
        fontSize: 12,
      }
    },
    legend: {
      data: myLegend?.length ? myLegend : [''],
      width: 400
    },
    grid: {
      top: '45px',   
      left: '5px', 
      right: '50px',
      bottom: '0',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      axisTick: {
        alignWithLabel: true,
        interval: 0,
      },
      axisLine :{
        lineStyle:{
          color: 'dcdee0'
        }
      }
    },
    yAxis: {},
    series: seriesArr.map(v => {
      return {
        name: v.seriesName,
        type: 'bar',
        animation: true,
        data: v.seriesData,
        itemStyle:{
          color: v.color,  //柱子颜色
        },
      }
    })
  };
}


export function getBarEchartOption(titleText, xAxisData, seriesData, seriesName, formatter) {
  return {
    title: {
      text: titleText,
      textStyle: {
        fontSize: 14,
        fontWeight: 500,
        color: '#131026'          
      },
    },
    tooltip: {
      extraCssText: 'max-width:400px;overflow:hidden;white-space:normal;word-break:break-all',
      trigger: 'axis',
      axisPointer : {     // 坐标轴指示器，坐标轴触发有效
        type : 'shadow',    // 默认为直线，可选为：'line' | 'shadow'
        shadowStyle: {
          color: 'rgba(24, 149, 255, 0.2)'
        }
      },
      formatter: formatter,
      textStyle: {
        border: 'none'
      }
    },
    legend: {
      data: ['']
    },
    grid: {
      top: '45px',   
      left: '5px', 
      right: '50px',
      bottom: '0',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      axisTick: {
        alignWithLabel: true,
        interval: 0,
      },
      axisLabel: {
        interval: 0,
        formatter: function(value) {    // 暂不知道怎么计算宽度，先写死
          var res = value;
          const maxIndex = xAxisData.length ? Math.floor(360/(xAxisData.length)/12) : 100
          if(res.length > maxIndex) {
            res = res.substring(0, maxIndex) + "..";
          }
          return res;
        },
      },
      axisLine :{
        lineStyle:{
          color: 'dcdee0'
        }
      }
    },
    yAxis: {},
    series: [{
      name: seriesName,
      type: 'bar',
      animation: true,
      data: seriesData,
      itemStyle: {
        color: '#1890ff'
      }
    }]
  };
}
export function getBarEchartOption1(titleText, xAxisData, seriesData,seriesData1, seriesName, seriesName1) {
  return {
    title: {
      text: titleText,
      textStyle: {
        fontSize: 14,
        fontWeight: 500,
        color: '#131026'          
      },
    },
    tooltip: {
      extraCssText: 'max-width:400px;overflow:hidden;white-space:normal;word-break:break-all',
      trigger: 'axis',
      axisPointer : {     // 坐标轴指示器，坐标轴触发有效
        type : 'shadow',    // 默认为直线，可选为：'line' | 'shadow'
        shadowStyle: {
          color: 'rgba(24, 149, 255, 0.2)'
        }
      },
      // formatter: formatter,
      textStyle: {
        border: 'none'
      }
    },
    legend: {
      data: ['']
    },
    grid: {
      top: '45px',   
      left: '5px', 
      right: '50px',
      bottom: '0',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: xAxisData,
      axisTick: {
        alignWithLabel: true,
        interval: 0,
      },
      axisLabel: {
        interval: 0,
        formatter: function(value) {    // 暂不知道怎么计算宽度，先写死
          var res = value;
          const maxIndex = xAxisData.length ? Math.floor(360/(xAxisData.length)/12) : 100
          if(res.length > maxIndex) {
            res = res.substring(0, maxIndex) + "..";
          }
          return res;
        },
      },
      axisLine :{
        lineStyle:{
          color: 'dcdee0'
        }
      }
    },
    yAxis: {},
    series: [{
      name: seriesName,
      type: 'bar',
      animation: true,
      data: seriesData,
      itemStyle: {
        color: '#1890ff'
      }
    },{
      name: seriesName1,
      type: 'bar',
      animation: true,
      data: seriesData1,
      itemStyle: {
        color: '#52C41A'
      }
    }]
  };
}

export function setDashBoardInfos(dashboardData, xAxisData, seriesData, keyOne, keyTwo, keyThree?:any, seriesData1?:any) {
  if (dashboardData.length == 0) {
    xAxisData.push('')
    seriesData.push(0)
    if(keyThree) {
      seriesData1?.push(0)
    }
    return []
  } else {
    return dashboardData.map((v, i) => {
      if (i <= 11) {
        xAxisData.push(v[keyOne])
        seriesData.push( keyTwo == 'price' ? new Big(v[keyTwo]).div(100).toJSON() : v[keyTwo])
        if(keyThree) {
          seriesData1?.push( keyThree == 'netPrice' ? new Big(v[keyThree]).div(100).toJSON() : v[keyThree])
        }
        return {...v, isSet: 1}
      } else {
        return {...v, isSet: 2}
      }
    })        
  }
}

export function computeInputWidth(fn) {
  return function(e) {
    const widthDiv = document.getElementById('computedInputWidth')
    widthDiv.innerHTML = e.target.value
    const inputWidth = widthDiv.offsetWidth
    const inputDom = e.target
    if (inputWidth < 145) {
      inputDom.style.width = 156 + 'px'
    } else {
      inputDom.style.width = inputWidth + 10 + 'px'
    }
    return fn(e.target.value)
  }
}

export function transGroupCanSeeVips(invisibleVips) {
  let canSeeVips = []
  const vips = [1, 2, 3, 4, 5]

  if (!invisibleVips.length) {
    canSeeVips.push('所有人可见')
  } else {
    vips.forEach(v => {
      if (invisibleVips.indexOf(v - 1) === -1) {
        canSeeVips.push('VIP' + v)
      }
    })
  }

  return canSeeVips.join(',')
}

export function transDetailFeatures(features = {}) {
  const myFeatures = {}

  for (let key in features) {
    if (key.indexOf('Id') === -1) {
      myFeatures[key] = { id: features[key + 'Id'], name: features[key] }
    }
  }

  return myFeatures
}

export function transAuditDetailFeatures(itemFeatureValues = []) {
  const myFeatures = {}

  itemFeatureValues.forEach(v => {
    myFeatures[v?.featureTitle] = { id: v?.id, name: v?.name }
  })

  return myFeatures
}

export function handleJudgePagePower(menu = [], pageUrl) {
  const allMenu = []

  function handleDeepPageUrl(myMenu = []) {
    myMenu.forEach(v => {
      if (v?.children?.length) {
        handleDeepPageUrl(v?.children)
      } else {
        allMenu.push(v?.key?.toLowerCase())
      }
    })
  }
  handleDeepPageUrl(menu)

  return allMenu.some(v => v === pageUrl)
}
// 加减乘除精度计算自定义计算器
const that:any = this

function tosingle(a) {
  var b = [];
  var c = 0;
  for (var i = 0; i < a.length; i++) {
    b.push((a[i] + c) % 10);
    c = parseInt(that.numDivFun((a[i] + c), 10, 2))
  }
  while (c > 9) {
    b.push(c % 10);
    c = parseInt(that.numDivFun(c, 10, 2))
  }
  if (c != 0) {
    b.push(c)
  }
  return b
}

function arrplus(a) {
  var b = [];
  var c = 0;
  var d = a.length;
  var e = 0;
  while (e < d) {
    var f = 0;
    e = 0;
    for (var i = 0; i < d; i++) {
      if (!a[i][c] && a[i][c] != 0) {
        e++
      }
      f = that.numPlusFun(f, a[i][c] ? Number(a[i][c]) : 0)
    }
    if (e < d) {
      b.push(f)
    }
    c++
  }
  return b
}

function tosingle2(a) {
  var b = [];
  var c = 0;
  for (var i = 0; i < a.length; i++) {
    var d = a[i] + c;
    if (d >= 0) {
      b.push(d % 10);
      c = parseInt(that.numDivFun(d, 10, 2))
    } else {
      if (d % 10 == 0) {
        b.push(d % 10);
        c = parseInt(that.numDivFun(d, 10, 2))
      } else {
        b.push(10 + (d % 10));
        c = parseInt(that.numMinusFun(that.numDivFun(d, 10, 2), 1))
      }
    }
  }
  while (c > 9 || c < -9) {
    var d = a[i] + c;
    if (d >= 0) {
      b.push(d % 10);
      c = parseInt(that.numDivFun(d, 10, 2))
    } else {
      if (d % 10 == 0) {
        b.push(d % 10);
        c = parseInt(that.numDivFun(d, 10, 2))
      } else {
        b.push(10 + (d % 10));
        c = parseInt(that.numMinusFun(that.numDivFun(d, 10, 2), 1))
      }
    }
  }
  if (c != 0) b.push(c);
    while (b[b.length - 1] == 0) {
    b.pop()
  }
  return b
}

function arrminute(c, d) {
  var e = [];
  var f = c.length > d.length ? c.length : d.length;
  for (var i = 0; i < f; i++) {
    if (!c[i] && c[i] != 0) {
      var a = 0
    } else {
      var a = Number(c[i])
    }
    if (!d[i] && d[i] != 0) {
      var b = 0
    } else {
      var b = Number(d[i])
    }
    e.push(a - b)
  }
  return tosingle2(e)
}

function contrast(a, b) {
  var c = a.length;
  var d = b.length;
  if (c < d) {
    return false
  } else if (c == d) {
    for (var i = c - 1; i > -1; i--) {
      if (Number(a[i]) < Number(b[i])) {
        return false
      } else if (Number(a[i]) > Number(b[i])) {
        return true
      }
    }
  }
  return true
}

export function numPlusFun(a, b) {
  a = '' + a;
  b = '' + b;
  if (a.charAt(0) == '-' && b.charAt(0) != '-') {
    a = a.substring(1);
    return that.numMinusFun(b, a)
  } else if (a.charAt(0) == '-' && b.charAt(0) == '-') {
    a = a.substring(1);
    b = b.substring(1);
    return '-' + that.numPlusFun(a, b);
  } else if (a.charAt(0) != '-' && b.charAt(0) == '-') {
    b = b.substring(1);
    return that.numMinusFun(a, b)
  } else if (a.charAt(0) != '-' && b.charAt(0) != '-') {
    var c = 0;
    var d = a.indexOf('.');
    var e = b.indexOf('.');
    var f = a.split('').reverse();
    var g = b.split('').reverse();
    if (d == -1 && e == -1) { } else if (d != -1 && e == -1) {
      var h = c = a.length - d - 1;
      f.splice(h, 1);
      for (var i = 0; i < c; i++) {
        g.unshift(0)
      }
    } else if (d == -1 && e != -1) {
      var j = c = b.length - e - 1;
      g.splice(j, 1);
      for (var i = 0; i < c; i++) {
        f.unshift(0)
      }
    } else if (d != -1 && e != -1) {
      var h = a.length - d - 1;
      var j = b.length - e - 1;
      f.splice(h, 1);
      g.splice(j, 1);
      if (h > j) {
        c = h;
        for (var i = 0; i < h - j; i++) {
          g.unshift(0)
        }
      } else {
        c = j;
        for (var i = 0; i < j - h; i++) {
          f.unshift(0)
        }
      }
    }
    var k = tosingle(arrplus([f, g]));
    var l = c - k.length + 1;
    if (l > 0) {
      for (var i = 0; i < l; i++) {
        k.push(0)
        }
    }
    if (c) {
      k.splice(c, 0, '.');
      var t = 0;
      for (var i = 0; i < c; i++) {
        if (!k[0]) {
          k.shift();
          t++
        } else {
          break
        }
      }
      if (t == c) {
        k.shift()
      }
    }
    k.reverse();
    let result = k.join('');
    if (result == '') {
      return 0
    } else {
      return Number(result)
    }
  }
}

export function numMinusFun(a, b) {
  a = '' + a;
  b = '' + b;
  if (a.charAt(0) == '-' && b.charAt(0) != '-') {
    a = a.substring(1);
    return '-' + that.numPlusFun(a, b)
  } else if (a.charAt(0) == '-' && b.charAt(0) == '-') {
    a = a.substring(1);
    b = b.substring(1);
    return that.numMinusFun(b, a)
  } else if (a.charAt(0) != '-' && b.charAt(0) == '-') {
    b = b.substring(1);
    return that.numPlusFun(a, b)
  } else if (a.charAt(0) != '-' && b.charAt(0) != '-') {
    var c = 0;
    var d = a.indexOf('.');
    var e = b.indexOf('.');
    var f = a.split('').reverse();
    var g = b.split('').reverse();
    if (d == -1 && e == -1) { } else if (d != -1 && e == -1) {
      var h = c = a.length - d - 1;
      f.splice(h, 1);
      for (var i = 0; i < c; i++) {
        g.unshift(0)
      }
    } else if (d == -1 && e != -1) {
      var j = c = b.length - e - 1;
      g.splice(j, 1);
      for (var i = 0; i < c; i++) {
        f.unshift(0)
      }
    } else if (d != -1 && e != -1) {
      var h = a.length - d - 1;
      var j = b.length - e - 1;
      f.splice(h, 1);
      g.splice(j, 1);
      if (h > j) {
        c = h;
        for (var i = 0; i < h - j; i++) {
          g.unshift(0)
        }
      } else {
        c = j;
        for (var i = 0; i < j - h; i++) {
          f.unshift(0)
        }
      }
    }
    var k;
    var z = contrast(f, g);
    if (z) {
      k = arrminute(f, g)
    } else {
      k = arrminute(g, f)
    }
    var l = c - k.length + 1;
    if (l > 0) {
      for (var i = 0; i < l; i++) {
        k.push(0)
      }
    }
    if (c) {
      k.splice(c, 0, '.');
      var t = 0;
      for (var i = 0; i < c; i++) {
        if (!k[0]) {
          k.shift();
          t++
        } else {
          break
        }
      }
      if (t == c) {
        k.shift()
      }
    }
    k.reverse();
    let result = k.join('');
    if (!z) {
      result = '-' + result
    }
    if (result == '') {
      return 0
    } else {
      return Number(result)
    }
  }
}

export function numTimesFun(a, b) {
  a = '' + a;
  b = '' + b;
  var c = 1;
  if (a.charAt(0) == '-') {
    a = a.substring(1);
    c *= -1
  }
  if (b.charAt(0) == '-') {
    b = b.substring(1);
    c *= -1
  }
  var d = 0;
  var e = a.indexOf('.');
  var f = b.indexOf('.');
  var g = a.split('').reverse();
  var h = b.split('').reverse();
  if (e != -1) {
    var k = a.length - e - 1;
    d = that.numPlusFun(d, k);
    g.splice(k, 1)
  }
  if (f != -1) {
    var l = b.length - f - 1;
    d = that.numPlusFun(d, l);
    h.splice(l, 1)
  }
  var m = [];
  for (var i = 0; i < h.length; i++) {
    var n = [];
    for (var j = 0; j < i; j++) {
      n.push(0)
    }
    for (var j = 0; j < g.length; j++) {
      n.push(h[i] * g[j])
    }
    m.push(tosingle(n))
  }
  var o = tosingle(arrplus(m));
  var p = d - o.length + 1;
  if (p > 0) {
    for (var i = 0; i < p; i++) {
      o.push(0)
    }
  }
  if (d) {
    o.splice(d, 0, '.');
    var t = 0;
    for (var i = 0; i < d; i++) {
      if (!o[0]) {
        o.shift();
        t++
      } else {
        break
      }
    }
    if (t == d) {
      o.shift()
    }
  }
  o.reverse();
  let result = o.join('');
  if (c == -1) {
    result = '-' + result
  }
  if (result == '') {
    return 0
  } else {
    return Number(result)
  }
}

export function numDivFun(a, b, c) {
  a = '' + a;
  b = '' + b;
  var d = 1;
  if (a.charAt(0) == '-') {
    a = a.substring(1);
    d *= -1
  }
  if (b.charAt(0) == '-') {
    b = b.substring(1);
    d *= -1
  }
  var e = 0;
  var f = a.indexOf('.');
  var g = b.indexOf('.');
  var h = a.split('').reverse();
  var j = b.split('').reverse();
  if (f != -1) {
    var k = a.length - f - 1;
    e += k;
    h.splice(k, 1)
  }
  if (g != -1) {
    var l = b.length - g - 1;
    e -= l;
    j.splice(l, 1)
  }
  var m = [];
  var n = c || Number(c) == 0 ? Number(c) : 10;
  var fixtab = e >= n ? 0 : n - e;
  e += fixtab;
  for (var i = 0; i < fixtab; i++) {
    h.unshift(0)
  }
  var o = true;
  while (o) {
    var p = h.length;
    var q = j.length;
    if (p > q) {
      var t = p - q;
      var r = [];
      if (Number(h[p - 1]) > Number(j[q - 1])) {
        for (var i = 0; i < t; i++) {
          r.push(0)
        }
        r.push(1)
      } else {
        for (var i = 0; i < t - 1; i++) {
          r.push(0)
        }
        r.push(1)
      }
      m.push(r);
      var s = r.slice(0, r.length - 1).concat(j);
      h = tosingle2(arrminute(h, s))
    } else if (p == q) {
      var u = 0;
      for (var i = 0; i < p; i++) {
        if (h[p - 1 - i] > j[q - 1 - i]) {
          m.push([1]);
          h = tosingle2(arrminute(h, j));
          break
        } else if (h[p - 1 - i] == j[q - 1 - i]) {
          u++
        } else if (h[p - 1 - i] < j[q - 1 - i]) {
          o = false;
          m.push([0]);
          break
        }
      }
      if (u == p) {
        m.push([1]);
        h = tosingle2(arrminute(h, j));
        o = false
      }
    } else if (p < q) {
      m.push([0]);
      o = false
    }
  }
  var v = tosingle2(arrplus(m));
  var w = e - v.length + 1;
  if (w > 0) {
    for (var i = 0; i < w; i++) {
      v.push(0)
    }
  }
  if (e) {
    v.splice(e, 0, '.');
    var t = 0;
    for (var i = 0; i < e; i++) {
      if (!v[0]) {
        v.shift();
        t++
      } else {
        break
      }
    }
    if (t == e) {
      v.shift()
    }
  }
  v.reverse();
  let result = v.join('');
  if (d == -1) {
    result = '-' + result
  }
  if (result == '') {
    return 0
  } else {
    return Number(result)
  }
}
