import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { connectAdvanced } from 'react-redux'

import {
  getsupperList as getList,
  getCommodityList as getlistCommodity,
  getsupperDetail as getdetailList
} from '../services/commoditytransferquery'

  
export const getsupperList = createAsyncThunk<any, any, any>(
  'commodity/post',
  async (params) => {
    return await getList(params)
  }
)

export const getCommodityList = createAsyncThunk<any, any, any>(
  'commoditys/post',
  async (params) => {
    return await getlistCommodity(params)
  }
)

export const getsupperDetail = createAsyncThunk<any, any, any>(
  'commodity/get',
  async (params) => {
    return await getdetailList(params)
  }
)

  
const commodity = createSlice({
  name: 'commodity',
  initialState: {
    storage: {},
    storagedetail: {},
    category: []
  },
  reducers: {
    setCategory(state, action) {
      state.category = action.payload
    },
    setDetailValue(state, action) {
      state.storagedetail = action.payload
    }
  },
  
  extraReducers: {
    [getsupperList.fulfilled as any]: (state, action) => {
      state.storage = action.payload
    },
    [getCommodityList.fulfilled as any]: (state, action) => {
      state.storage = action.payload
    },
    [getsupperDetail.fulfilled as any]: (state, action) => {
      state.storagedetail = action.payload
    },
  }
})


const {
  actions,
  reducer,
} = commodity;

export const {
  setCategory,
  setDetailValue
} = actions

export default reducer