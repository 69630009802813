import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { connectAdvanced } from 'react-redux'

import {
  getRoleList as getRoleListAPI,
  getEpsList as getEpsListAPI,
  getOrderList as getOrderListAPI,
  getUserList as getUserListAPI,
} from '../services/enterprise';


export const getRoleList = createAsyncThunk<any, any, any>(
  'role/list',
  async (params) => {
    return await getRoleListAPI(params)
  }
)

export const getEpsList = createAsyncThunk<any, any, any>(
  'eps/list',
  async (params) => {
    return await getEpsListAPI(params)
  }
)

export const getOrderList = createAsyncThunk<any, any, any>(
  'order/list',
  async (params) => {
    return await getOrderListAPI(params)
  }
)

export const getUserList = createAsyncThunk<any, any, any>(
  'user/list',
  async (params) => {
    return await getUserListAPI(params)
  }
)
  
const orders = createSlice({
  name: 'order',
  initialState: {
    roleList: [],
    epsList: {},
    orderList: {},
    userList: {}
  },
  reducers: {
  },
  
  extraReducers: {
    [getRoleList.fulfilled as any]: (state, action) => {
      state.roleList = action.payload
    },
    [getEpsList.fulfilled as any]: (state, action) => {
      state.epsList = action.payload
    },
    [getOrderList.fulfilled as any]: (state, action) => {
      state.orderList = action.payload
    },
    [getUserList.fulfilled as any]: (state, action) => {
      state.userList = action.payload
    }
  }
})

const {
  reducer
} = orders;


export default reducer