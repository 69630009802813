import fetch from './index'

export function getCabinetsList(params): any {
  return fetch.get('/eps/cabinets/list', { params })
}

export function delCabinet(id) {
  return fetch.delete(`/eps/cabinets/${id}`)
}

export function addCabinet(params) {
  return fetch.post('/eps/cabinets', params)
}

export function editCabinet(params) {
  return fetch.put('/eps/cabinets', params)
}

export function lockCabinet(id, type) {
  return fetch.put(`/eps/cabinets/locked/${id}?type=${type}`)
}