import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { connectAdvanced } from 'react-redux'

import {
  getsupperList as getList,
  getsupperDetail as getdetailList
} from '../services/supplierquery'

  
export const getsupperList = createAsyncThunk<any, any, any>(
  'supper/post',
  async (params) => {
    return await getList(params)
  }
)

export const getsupperDetail = createAsyncThunk<any, any, any>(
  'supper/get',
  async (params) => {
    return await getdetailList(params)
  }
)

  
const supplier = createSlice({
  name: 'supper',
  initialState: {
    supplier: {},
    supplierdetail: {},
    category: []
  },
  reducers: {
    setCategory(state, action) {
      state.category = action.payload
    },
    setDetailValue(state, action) {
      state.supplierdetail = action.payload
    }
  },
  
  extraReducers: {
    [getsupperList.fulfilled as any]: (state, action) => {
      state.supplier = action.payload
    },
    [getsupperDetail.fulfilled as any]: (state, action) => {
      state.supplierdetail = action.payload
    },
  }
})


const {
  actions,
  reducer,
} = supplier;

export const {
  setCategory,
  setDetailValue
} = actions

export default reducer