import { createSlice } from '@reduxjs/toolkit'

const pageDatas = createSlice({
  name: 'pageData',
  initialState: {
    infos: {
      channels: {},
      factory: {},
      supplier: {}
    },
    exchangeTranslateFlag: false
  },
  reducers: {
    setPageDataChannels(state, action) {
      state.infos.channels = action.payload
    },
    setPageDataFactory(state, action) {
      state.infos.factory = action.payload
    },
    setPageDataSupplier(state, action) {
      state.infos.supplier = action.payload
    },
    setExchangeTranslateFlag(state, action) {
      state.exchangeTranslateFlag = action.payload
    }
  }
})

const {
  actions,
  reducer
} = pageDatas;

export const {
  setPageDataChannels,
  setPageDataFactory,
  setPageDataSupplier,
  setExchangeTranslateFlag,
} = actions

export default reducer