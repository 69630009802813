import fetch from './index'
// 款式条件查询列表接口
export function getDesign(params): any {
  return fetch.post('/designs/lists', 
    params
  )
}

// 款式图片搜索列表接口
export function getDesignImage(data): any {
  return fetch.post('/designs/Imagesearch/lists', data)
}


// 新增款式接口
export function addDesign(params): any {
  return fetch.post('/designs', params)
}

// 编辑款式接口
export function editDesign(params): any {
  return fetch.put('/designs', params)
}

// 款式详情接口
export function detailDesign(id): any {
  return fetch.get(`/designs/${id}`)
}

// 删除款式接口
export function deleteDesign(id): any {
  return fetch.delete(`/designs/${id}`)
}

export function hideDesign(id): any {
  return fetch.put(`/designs/hide?id=${id}`)
}

// 系列列表接口
export function getAttribute(params): any {
  return fetch.get("/designs/series", {
    params
  })
}

// 关键词
export function getKeywords(params): any {
  return fetch.get("/designs/keywords", {
    params
  })
}

// 设计师列表
export function getDesigners(params): any {
  return fetch.get("/designs/designers", {
    params
  })
}

// 石资料- 宝石品类及对应的形状
export function getCategorys(): any {
  return fetch.get("/categories/1?sellerId=1")
}

// 是否有该产品款号
export function getCheckDesign(params): any {
  return fetch.get("/designs/designNo", {
    params
  })
}

export function getUpload(params):any {
  return fetch.post("/designs/Imagesearch", params)
  
}
// 批量导入款式
export function importBatchDesigns(params) {
  return fetch.post('/designs/input', params)
}

export function getCategorysNames(params) {
  return fetch.get('/category/features/values/eng/name', { params })
}
// 批量导出款式
export function exportBatchDesigns(params) {
  return fetch.post('/export/records/designs/lists', params)
}
// 新增建议价名称
export function addDesignPlatform(params) {
  return fetch.post('/designs/platform', params)
}
// 编辑建议价名称
export function editDesignPlatform(params) {
  return fetch.put('/designs/platform', params)
}
// 查询建议价名称
export function getDesignPlatform() {
  return fetch.get('/designs/platform')
}
// 删除建议价名称
export function delDesignPlatform(id) {
  return fetch.delete(`/designs/platform?platformId=${id}`)
}
// 新增工艺
export function addDesignCraft(params) {
  return fetch.post('/designs/craft', params)
}
// 编辑工艺
export function editDesignCraft(params) {
  return fetch.put('/designs/craft', params)
}
// 查询工艺
export function getDesignCraft() {
  return fetch.get('/designs/craft')
}
// 删除工艺
export function delDesignCraft(id) {
  return fetch.delete(`/designs/craft?craftId=${id}`)
}
// 批量编辑渠道
export function editBatchChannel(params) {
  return fetch.post('/designs/batch/update/channel', params)
}
// 批量编辑工厂
export function editBatchFactory(params) {
  return fetch.post('/designs/batch/update/factory', params)
}
// 批量编辑关键词
export function editBatchKeyword(params) {
  return fetch.post('/designs/batch/update/keywords', params)
}
// 批量编辑参考价
export function editBatchReferencePrice(params) {
  return fetch.post('/designs/batch/update/reference/price', params)
}

