import fetch from './index'

export function getWarehousesList(params): any {
  return fetch.get('/eps/warehouses/list', { params })
}
// 无权限控制仓库列表
export function getWarehousesListAll(params): any {
  return fetch.get('/eps/warehouses/list/all', { params })
}

export function delWarehouse(id) {
  return fetch.delete(`/eps/warehouses/${id}`)
}

export function addWarehouse(params) {
  return fetch.post('/eps/warehouses', params)
}

export function editWarehouse(params) {
  return fetch.put('/eps/warehouses', params)
}

export function lockWarehouse(id, type) {
  return fetch.put(`/eps/warehouses/locked/${id}?type=${type}`)
}
// 设置退货默认仓库
export function setDefaultWarehouse(id) {
  return fetch.put(`/eps/warehouses/defaultWarehouse?id=${id}`)
}
// 查询退货默认仓库
export function getDefaultWarehouse(): any {
  return fetch.get('/eps/warehouses/defaultWarehouse')
}

export function getWarehouseOfCommodity(params): any {
  return fetch.get('/eps/commodities/mini/warehouse', {
    params
  })
}

// 仓库柜组权限集
// 新增权限集
export function addWarehouseRule(params) {
  return fetch.post('/rule/warehouse', params)
}
// 删除权限集
export function delWarehouseRule(id) {
  return fetch.delete(`/rule/warehouse/${id}`)
}
// 编辑权限集
export function editWarehouseRule(params) {
  return fetch.put('/rule/warehouse', params)
}
// 查询权限集列表
export function getWarehouseRule(): any {
  return fetch.get('/rule/warehouse/list')
}
// 仓库柜组集合
export function getWarehouseTree(): any {
  return fetch.get('/eps/warehouses/tree')
}
// 仓库排序 
export function sortWarehouse(params) {
  return fetch.put('/eps/warehouse/sort', params)
}