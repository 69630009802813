import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { connectAdvanced } from 'react-redux'

import {
  getDesign as getList,
  getDesignImage as getImagelist,
  detailDesign as detailDesignList
} from '../services/fashion'

  
export const getDesign = createAsyncThunk<any, any, any>(
  'design/get',
  async (params, { getState }) => {
    const querydata:any = getState()
    return await getList({
      ...querydata?.fashion?.query,
      hideStatus: querydata?.fashion?.query?.hideStatus == 0 ? 0 : 1,
      pageSize: querydata?.fashion?.query?.pageSize || 50,
      nextCursor:  querydata?.fashion?.query?.nextCursor || 0
    })
  }
)

export const getDesignImage = createAsyncThunk<any, any, any>(
  'designs/get',
  async (params) => {
    return await getImagelist(params)
  }
)
export const detailDesign = createAsyncThunk<any, any, any>(
  'detail/get',
  async (data) => {
    return await detailDesignList(data)
  }
)


const fashion = createSlice({
  name: 'design',
  initialState: {
    fashiondata: [],
    query: {},
    stoneTable: {},
    detail: {},
    loading: false,
    dataInfo: []
  },
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
    getFashionQuery(state, action) {
      state.query = action.payload
    },
    getFashionStone(state, action) {
      state.stoneTable = action.payload
    },
    setDataInfo(state, action) {
      state.dataInfo = action.payload
    }
  },
  
  extraReducers: {
    [getDesign.fulfilled as any]: (state, action) => {
      state.fashiondata = action.payload
      state.loading = false
    },
    [getDesignImage.fulfilled as any]: (state, action) => {
      state.fashiondata = action.payload
      state.loading = false
    },
    [detailDesign.fulfilled as any]: (state, action) => {
      state.detail = action.payload
    }
  }
})

const {
  actions,
  reducer,
} = fashion;

export const {
  getFashionQuery,
  getFashionStone,
  setLoading,
  setDataInfo,
} = actions

export default reducer