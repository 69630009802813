import fetch from './index'

// 所有金价信息
export function getGoldPrice(params): any {
  return fetch.get('/gold/price/list', { params })
}
// 编辑金价
export function editGoldPrice(params) {
  return fetch.put('/gold/price', params)
}
// 查看历史
export function getGoldPriceHistory(params) {
  return fetch.get('/gold/price/record/list', { params })
}
// 批量改价
export function editBatchPrice(params) {
  return fetch.put('/gold/batch/price', params)
}
// 新增金价
export function addGoldPrice(params) {
  return fetch.post('/gold/price', params)
}
// 删除金价
export function delGoldPrice(id) {
  return fetch.delete(`/gold/price/${id}`)
}
// 查看汇率倍率
export function getGoldExchange(): any {
  return fetch.get('/gold/exchange')
}
// 保存汇率倍率
export function saveGoldExchange(params) {
  return fetch.put('/gold/exchange', params)
}
// 石报价
export function getStoneQuote(params) {
  return fetch.get('/eps/edata/stone/quote', { params })
}
// 石报价
export function postStoneQuote(params) {
  return fetch.post('/eps/edata/stone/quote', params)
}
// 石报价
export function putStoneQuote(params) {
  return fetch.put('/eps/edata/stone/quote', params)
}
// 石报价
export function deleteStoneQuote(id) {
  return fetch.delete(`/eps/edata/stone/quote/${id}`)
}
// 查询渠道成色倍率
export function getSuggestPriceMultiple(): any {
  return fetch.get('/factory/order/suggested/retail/price')
}
// 编辑渠道成色倍率
export function editSuggestPriceMultiple(params) {
  return fetch.put('/factory/order/suggested/retail/price', params)
}
// 历史记录
export function getStoneQuoteLog(params): any {
  return fetch.post(`/eps/edata/stone/quote/log`, params)
}
// 新增引流款
export function addAttractDesign(params): any {
  return fetch.post(`/eps/commodity/attract/design/add`, params)
}
// 编辑引流款
export function editAttractDesign(params): any {
  return fetch.post(`/eps/commodity/attract/design/update`, params)
}
// 删除引流款
export function delAttractDesign(params): any {
  return fetch.post(`/eps/commodity/attract/design/delete`, params)
}
// 查询引流款
export function getAttractDesign(params): any {
  return fetch.post(`/eps/commodity/attract/design/all`, params)
}