import fetch from './index'

export function getSeries(params): any {
  return fetch.get('/designs/series', { params })
}

export function delSeries(id) {
  return fetch.delete(`/designs/series/${id}`)
}

export function addSeries(params) {
  return fetch.post('/designs/series', params)
}

export function editSeries(params) {
  return fetch.put('/designs/series', params)
}