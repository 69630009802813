import fetch from './index'

export function getChannelsList(params): any {
  return fetch.get('/sale/channels/page/list', { params })
}

export function getChannelsListType2(params: any): any {
  return fetch.get('/sale/channels/page/list', { params: { ...params, type: 2 } })
}

export function delChannel(id) {
  return fetch.delete(`/sale/channels/${id}`)
}

export function addChannel(params) {
  return fetch.post('/sale/channels', params)
}

export function editChannel(params) {
  return fetch.put('/sale/channels', params)
}

export function getAllChannelsList(params): any {
  return fetch.get('/sale/channels/list', { params })
}
// 渠道排序
export function putChannelSort(params): any {
  return fetch.post('/sale/channels/sort', params)
}