import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getSupplierReturnDashboard as getSupplierReturnDashboardAPI
} from '../services/dashboards'

export const getSupplierReturnDashboard = createAsyncThunk<any, any, any>(
  'supplierReturnDashboard/list',
  async (params) => {
    const data = await getSupplierReturnDashboardAPI(params)
    return data || {}
  }
)

const supplierReturnDashboardSlice = createSlice({
  name: 'supplierReturnDashboard',
  initialState: {
    supplierReturnDashboard: {}
  },
  reducers: {
  },
  extraReducers: {
    [getSupplierReturnDashboard.fulfilled as any]: (state, action) => {
      state.supplierReturnDashboard = action.payload
    },
    [getSupplierReturnDashboard.rejected as any]: (state, action) => {
      state.supplierReturnDashboard = {}
    }
  }
})

const {
  reducer
} = supplierReturnDashboardSlice

export default reducer