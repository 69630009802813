import Big from 'big.js'
import { delSideTrim } from '@/utils/methods'
import fetch from './index'
// 库存列表
export function getCommoditiesList(params): any {
  return fetch.post('/eps/commodities/list', params).then((data: any) => {
    return {
      ...data,
      models: (data?.models || []).map(v => {
        return {
          ...v,
          ...v.product
        }
      })
    }
  })
}
// 编辑商品信息
export function editCommodityInfo(params) {
  return fetch.put('/eps/commodities', delSideTrim(params))
}
// 导入编辑list
export function editListCommodityInfo(params) {
  return fetch.post('/eps/commodities/batch/update', params)
}
// 批量编辑商品标签
export function editBatchCommodityTags(params) {
  return fetch.post('/eps/commodities/bulk/add/tag', params)
}
// 批量编辑商品标记
export function editBatchCommodityFlagStatus(params) {
  return fetch.post('/eps/commodities/bulk/update/flagStatus', params)
}
// 批量改价（两种是一个接口）
export function setCommodityPrice(params) {
  return fetch.put('/eps/commodities/price', params)
}
// 批量改备注
export function editBatchCommodityRemark(params) {
  return fetch.put('/eps/commodities/remark', params)
}
// 锁定解锁商品
export function setCommodityLock(ids, type) {
  return fetch.put(`/eps/commodities/locked?epsCommodityIds=${ids}&locked=${type}`)
}
// 导入改价表
export function importCommodityPrice(params) {
  return fetch.post('/eps/commodities/import/price', params)
}
// 导出
export function exportCommodityInfos(params) {
  return fetch.post('/export/records/eps/commodities', params)
}

export function getCommoditiesRoute(params): any {
  return fetch.get('/eps/commodities/record/list', { params })
}
// 商户小程序商品浏览量开关
export function getSellerBrowseApi(params): any {
  return fetch.get('/eps/applet/setting').then((res: any) => {
    return !!res.browse;
  })
}

// 技术支持/浏览量开关
export function postSellerBrowseApi(params): any {
  return fetch({
    url: '/eps/applet/setting/browse',
    method: 'POST',
    data: params
  })
}


// 商户小程序商品列表
export function getCommoditiesListByGroup(params): any {
  return fetch.get('/sellers/commodities/mini/list', {
    params,
  }).then((data: any) => {
    return {
      ...data,
      models: (data?.models || []).map(v => {
        return {
          ...v,
          canBuy: !!v.totalNum,
          imgUrl: v?.commodityImages?.[0]?.imgUrl,
          priceValue: new Big(v.price || 0).div(100).toJSON()
        }
      })
    }
  })
}
// 商户商品列表
export function getSellerCommoditiesList(params): any {
  return fetch.get('/sellers/commodities/list', {
    params,
  }).then((data: any) => {
    return {
      ...data,
      models: (data?.models || []).map(v => {
        return {
          ...v,
          canBuy: !!v.totalNum,
          imgUrl: v?.commodityImages?.[0]?.imgUrl,
          priceValue: new Big(v.price || 0).div(100).toJSON()
        }
      })
    }
  })
}

// 选品列表
export function getChooseCommodityList(params) {
  return fetch.post('/choose/commodity/list/background', params)
}
// 运营下架选品商品
export function putChooseCommodityDown(ids) {
  return fetch.put(`/choose/commodity/zb100/down?chooseCommodityIds=${ids}`)
}
// 商品拆分
export function postCommoditySplit(params) {
  return fetch.post('/commodity/processing/split', params)
}
// 商品组合
export function postCommodityMerge(params) {
  return fetch.post('/commodity/processing/merge', params)
}
// 商品名称自定义查询
export function getCommodityCustomName(commodityType) {
  return fetch.get(`/commodity/name/custom?commodityType=${commodityType}`)
}
// 商品名称自定义编辑
export function editCommodityCustomName(params) {
  return fetch.post('/commodity/name/custom', params)
}
// 商品名称自定义开关查询
export function getCommodityCustomSwitch(commodityType) {
  return fetch.get(`/commodity/name/custom/switch?commodityType=${commodityType}`)
}
// 商品名称自定义开关编辑
export function editCommodityCustomSwitch(params) {
  return fetch.put('/commodity/name/custom/switch', params)
}
// 批量编号查商品以便后端提示错误信息
export function getBatchNoCommodity(params): any {
  return fetch.post('/eps/commodities/list/No', params).then((data: any) => {
    return {
      ...data,
      models: (data?.models || []).map(v => {
        return {
          ...v,
          ...v.product
        }
      })
    }
  })
}
// 库存管理穿透状态的搜索
export function getCommoditiesListNg(params): any {
  return fetch.post('/eps/commodities/list/ng', params).then((data: any) => {
    return {
      ...data,
      models: (data?.models || []).map(v => {
        return {
          ...v,
          ...v.product
        }
      })
    }
  })
}

// 分页查询商品变更记录的数据
export function getCommodityChangeLog(params) {
  return fetch.post(`/eps/commodities/log/page`, params)
}

// 100下架
export function postLockedChoose(params) {
  return fetch.post(`/choose/commodity/ZB100/locked/choose`, params)
}