import fetch from './index'

// 登陆的员工信息
export function getUserInfo(): any {
  return fetch.get('/staffs/info')
}
// 员工登陆
export function login(params) {
  return fetch.post('/staffs/login', params)
}
// 员工退出登陆
// export function logout() {
//   return fetch.post('/staffs/logout')
// }