import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { connectAdvanced } from 'react-redux'

import {
  getsupperList as getList,
  getsupperDetail as getdetaillist
} from '../services/storagequery';

  
export const getsupperList = createAsyncThunk<any, any, any>(
  'storage/post',
  async (params) => {
    return await getList(params)
  }
)

export const getsupperDetail = createAsyncThunk<any, any, any>(
  'storage/get',
  async (params) => {
    return await getdetaillist(params)
  }
)

  
const storage = createSlice({
  name: 'storage',
  initialState: {
    storagedata: {},
    storagedetail: {},
    category: []
  },
  reducers: {
    setCategory(state, action) {
      state.category = action.payload
    },
    setDetailValue(state, action) {
      state.storagedetail = action.payload
    }
  },
  
  extraReducers: {
    [getsupperList.fulfilled as any]: (state, action) => {
      state.storagedata = action.payload
    },
    [getsupperDetail.fulfilled as any]: (state, action) => {
      state.storagedetail = action.payload
    },
  }
})

const {
  actions,
  reducer
} = storage;

export const {
  setCategory,
  setDetailValue
} = actions;

export default reducer