import React from 'react'
import classnames from 'classnames'

import Icon from '../Icon'
import styles from './index.less'
import { RefObject } from 'react'

class RecentVisitTab extends React.Component<any> {
  tab: RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);

    this.tab = React.createRef();
  }

  componentDidMount() {
    const {
      inMenu,
      onMount,
      pageKey,
    } = this.props

    if (!inMenu) {
      const {
        width
      } = this.tab.current.getBoundingClientRect()
      onMount(pageKey, width)
    }
  }

  componentWillUnmount() {
    const {
      inMenu,
      pageKey,
      onUnmount,
    } = this.props

    if (!inMenu) {
      onUnmount(pageKey)
    }
  }

  shouldComponentUpdate(nextProps) {
    return (nextProps.active !== this.props.active ||
      nextProps.name !== this.props.name ||
      nextProps.unclosable !== this.props.unclosable)
  }

  handleVisit = () => {
    const {
      link,
      onVisit,
    } = this.props

    onVisit(link);
  }

  handleClose = () => {
    const {
      pageKey,
      active,
      onClose,
    } = this.props

    onClose(pageKey, active)
  }

  render() {
    const {
      active,
      name,
      unclosable,
      inMenu,
    } = this.props
  
    return (
      <div ref={this.tab} className={classnames(styles.item, {
        [styles.active]: active,
        [styles.inMenu]: inMenu
      })}>
        <span onClick={this.handleVisit} className={styles.name}>{name}</span>
        {
          unclosable ? null : (
            <Icon onClick={this.handleClose} className={styles.close} type="close-circle" />
          )
        }
      </div>
    )
  }
}

export default RecentVisitTab
