import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getOutputDashboard as getOutputDashboardAPI
} from '../services/dashboards'

export const getOutputDashboard = createAsyncThunk<any, any, any>(
  'outputDashboard/list',
  async (params) => {
    const data = await getOutputDashboardAPI(params)
    return data || {}
  }
)

const outputDashboardSlice = createSlice({
  name: 'outputDashboard',
  initialState: {
    outputDashboard: {}
  },
  reducers: {
  },
  extraReducers: {
    [getOutputDashboard.fulfilled as any]: (state, action) => {
      state.outputDashboard = action.payload
    },
    [getOutputDashboard.rejected as any]: (state, action) => {
      state.outputDashboard = {}
    }
  }
})

const {
  reducer
} = outputDashboardSlice

export default reducer