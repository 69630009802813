import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getSeries as getSeriesAPI,
} from '../services/serieses'

export const getSeries = createAsyncThunk<any, any, any>(
  'series/list',
  async (params) => {
    const data = await getSeriesAPI(params)
    return data || []
  }
)

const seriesSlice = createSlice({
  name: 'series',
  initialState: {
    series: []
  },
  reducers: {
  },
  extraReducers: {
    [getSeries.fulfilled as any]: (state, action) => {
      state.series = action.payload
    },
    [getSeries.rejected as any]: (state, action) => {
      state.series = []
    },
  }
})

const {
  reducer,
} = seriesSlice;

export default reducer