import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { connectAdvanced } from 'react-redux'

import {
  getSupperlist as getList,
  getCommoditylist as getSubList,
  getSupperdetail as getdetaillist
} from '../services/inventorycheck'

  
export const getSupperlist = createAsyncThunk<any, any, any>(
  'inventory/post',
  async (params) => {
    return await getList(params)
  }
)

export const getCommoditylist = createAsyncThunk<any, any, any>(
  'inventory/post',
  async (params) => {
    return await getSubList(params)
  }
)

export const getSupperdetail = createAsyncThunk<any, any, any>(
  'inventory/get',
  async (params) => {
    return await getdetaillist(params)
  }
)

  
const inventory = createSlice({
  name: 'inventory',
  initialState: {
    inventorydata: {},
    inventorydetail: {}
  },
  reducers: {
    setDetailValue(state, action) {
      state.inventorydetail = action.payload
    }
  },
  
  extraReducers: {
    [getSupperlist.fulfilled as any]: (state, action) => {
      state.inventorydata = action.payload
    },
    [getCommoditylist.fulfilled as any]: (state, action) => {
      state.inventorydata = action.payload
    },
    [getSupperdetail.fulfilled as any]: (state, action) => {
      state.inventorydetail = action.payload
    },
  }
})

const {
  actions,
  reducer
} = inventory;


export const {
  setDetailValue
} = actions

export default reducer