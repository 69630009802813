import React, {
  useEffect,
  useState,
} from 'react'
import {
  Button,
  message
} from 'antd'

import {
  getCaptcha
} from '@/services/sms'

function CaptchaButton(props) {
  const {
    phoneNum,
    ...btnProps
  } = props
  const [second, setSecond] = useState(0)
  const [loading, setLoading] = useState(false)

  function handleClick() {
    // setLoading(true)
    // 获取验证码时验证手机号格式
    if ( /^1[3-9]\d{9}$/.test(phoneNum) ) {
      setLoading(true)
    } else {
      message.error('请检查输入的手机号码是否正确');
    }
  }

  useEffect(() => {
    if (!second) return

    const tid = setTimeout(() => {
      setSecond(second - 1)
    }, 1000)

    return () => clearTimeout(tid)
  }, [second])

  useEffect(() => {
    if (!loading) return
    getCaptcha({
      phoneNum,
      template: 'LOGIN_REGISTER_VERIFY'
    }).then(() => {
      setLoading(false)
      setSecond(60)
    }).catch(() => {
      setLoading(false)
    })
  }, [loading])

  return (
    <Button
      {...btnProps}
      loading={loading}
      disabled={!phoneNum || !!second}
      onClick={handleClick}
    >
      { second ? `${second}s` : '获取验证码'}
    </Button>
  )
}

export default CaptchaButton
