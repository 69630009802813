import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getCommoditiesListNg as getCommoditiesListAPI
} from '../services/commodities'

export const getCommoditiesList = createAsyncThunk<any, any, any>(
  'commodities/list',
  async (params) => {
    const data = await getCommoditiesListAPI(params)
    return data || []
  }
)

const commoditySlice = createSlice({
  name: 'commodity',
  initialState: {
    commodities: []
  },
  reducers: {
  },
  extraReducers: {
    [getCommoditiesList.fulfilled as any]: (state, action) => {
      state.commodities = action.payload
    },
    [getCommoditiesList.rejected as any]: (state, action) => {
      state.commodities = []
    }
  }
})

const {
  reducer
} = commoditySlice

export default reducer