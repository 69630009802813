import React from 'react'
import { Result } from 'antd';

import styles from './index.less'

class HelpErrorBoundary extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.container}>
          <Result
            title="网络出错，请重连"
            subTitle="很抱歉，您访问的页面出现了故障"
            status="error"
          />
        </div>
      )
    }

    return this.props.children
  }
}

export default HelpErrorBoundary