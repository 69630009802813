import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getGoldPrice as getGoldPriceAPI,
} from '../services/goldPrices'

export const getGoldPrice = createAsyncThunk<any, any, any>(
  'gold/price/list',
  async (params) => {
    const data = await getGoldPriceAPI(params)
    return data || []
  }
)

const goldPriceSlice = createSlice({
  name: 'gold/price',
  initialState: {
    goldPrice: []
  },
  reducers: {
  },
  extraReducers: {
    [getGoldPrice.fulfilled as any]: (state, action) => {
      state.goldPrice = action.payload
    },
    [getGoldPrice.rejected as any]: (state, action) => {
      state.goldPrice = []
    }
  }
})

const {
  reducer,
} = goldPriceSlice;

export default reducer