import fetch from './index'
// 标签列表
export function getTagsList(params): any {
  return fetch.get('/tags/list', { params })
}
// 不分页的标签列表
export function getTagsValues(params): any {
  return fetch.get('/tags/values', { params })
}
// 删除标签
export function delTag(id) {
  return fetch.delete(`/tags/${id}`)
}
// 新增标签
export function addTag(params) {
  return fetch.post('/tags', params)
}
// 编辑标签
export function editTag(params) {
  return fetch.put('/tags', params)
}

export function getMembersList(params) {
  return fetch.get('/sellers/members/setting', params)
}

export function editMember(params) {
  return fetch.put('/sellers/members/setting', params)
}

export function getSuperior(params) {
  return fetch.get('/distributors/list', params)
}

export function getMember() {
  return fetch.get('/sellers/members/trade/Info')
}
