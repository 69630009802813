import Big from 'big.js'

import fetch from './index'

export function getCoupons(params): any {
  return fetch.get('/coupons/sellers/batch/ids', {
    params,
  }).then((resp) => {
    return (resp || [] as any).map(v => {
      return {
        ...v,
        title: v.name,
        disabled: v.invalidFlag || v.status !== 1 || v.quantity == 0,
        price: new Big(v.couponFee).div(100).toJSON(),
        desc: v.couponContent,
      }
    })
  })
}
export function queryCouponById(params) {
  return fetch.get('coupons/sellers', {
    params,
  })
}

// 优惠券列表
export function getCouponsList(params) {
  return fetch.get('/coupons/sellers/infos', { params })
}

// 新增优惠券
export function createCoupon(params) {
  return fetch.post('coupons/sellers', params)
}

// 修改优惠券
export function updateCoupon(params) {
  return fetch.put('coupons/sellers', params)
}

export function updateCouponState(id, state) {
  return fetch.put(`coupons/sellers/${id}/${state}`)
}

export function createCounponQRCode(params) {
  return fetch.post(`coupons/sellers/code?id=${params.id}`)
}
