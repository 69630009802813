import fetch from './index'

export function getCustomersList(params): any {
  return fetch.post('/eps/customers/list', params)
}

export function addCustomer(params) {
  return fetch.post('/eps/customers', params)
}

export function editCustomer(params) {
  return fetch.put('/eps/customers', params)
}


export function deleteCustomer(id) {
  return fetch.delete(`/eps/customers?customerId=${id}`)
}

export function importCustomer(params) {
  return fetch.post(`/eps/customers/import`, params)
}
// 检查名称是否重复
export function checkCustomerName(params) {
  return fetch.get('/eps/customers/check/nickname', { params })
}
