import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getActivityList as getActivityListAPI,
  getActivityLists as getActivityListAPIS,
} from '../services/activity'

export const getActivityList = createAsyncThunk<any, any, any>(
  'activity/list',
  async (params) => {
    const data = await getActivityListAPI(params)
    return data || []
  }
)

export const getActivityLists = createAsyncThunk<any, any, any>(
  'activity/lists',
  async (params) => {
    const data = await getActivityListAPIS(params)
    return data || []
  }
)

const activityList = createSlice({
  name: 'activity',
  initialState: {
    list: {},
    lists: {}
  },
  reducers: {
  },
  extraReducers: {
    [getActivityList.fulfilled as any]: (state, action) => {
      state.list = action.payload
    },
    [getActivityLists.fulfilled as any]: (state, action) => {
      state.lists = action.payload
    },
  }
})

const {
  reducer,
} = activityList;

export default reducer