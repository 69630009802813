import { createSlice } from '@reduxjs/toolkit'
  
const pageParams = createSlice({
  name: 'pageParam',
  initialState: {
    editStoneEntryInfo: '',
    editPearlEntryInfo: '',
    editProductEntryInfo: '',
    editBasicEntryInfo: '',
    editGiftEntryInfo: '',
    rentStoneEntryInfo: '',
    rentPearlEntryInfo: '',
    rentProductEntryInfo: '',
    rentBasicEntryInfo: '',
    rentEntryNo: '',
    rentCommodityNo: '',
    rentAgainStoneEntryInfo: '',
    rentAgainPearlEntryInfo: '',
    rentAgainProductEntryInfo: '',
    rentAgainBasicEntryInfo: '',
    designEntryDetailDataInfo: '',
    designOutDetailDataInfo: '',
    rentOutputInfo: '',
    entryManageEntryNo: '',
    storageOutPutNo: '',
    warehouseInventoryNo: '',
    commodityTransferNo: '',
    supplierReturnNo: '',
    orderReturnNo: '',
    commodityRentOutNo: '',
    // syntheticProductInfo: '',
    editFactoryOrderInfo: '',
    againFactoryOrderInfo: '',
    designFactoryOrderInfo: '',
    entryFactoryOrderInfo: '',
    newsFactoryOrderNo: '',
    newsRepairCommodityNo: '',
    newsCommodityTransferNo: '',
    outputFactoryOrderInfo: '',
    designCommodityDataInfo: '',
    edataShopDataInfo: '',
    createStorageOutImportInfo: '',
    transCommodityManageNo: '',
    editRentOutInfo: '',
    aggregationDataInfo: '',
    aggregationOperateInfo: '',
    edataInternalStoreDataInfo: '',
    productDataInfo: '',
    addRepairTicketInfo: '',
    keywordAnalysisInfo: '',
    edataReviewDataInfo: '',
  },
  reducers: {
    setEditStoneEntryInfo(state, action) {
      state.editStoneEntryInfo = action.payload
    },
    setEditPearlEntryInfo(state, action) {
      state.editPearlEntryInfo = action.payload
    },
    setEditProductEntryInfo(state, action) {
      state.editProductEntryInfo = action.payload
    },
    setEditBasicEntryInfo(state, action) {
      state.editBasicEntryInfo = action.payload
    },
    setEditGiftEntryInfo(state, action) {
      state.editGiftEntryInfo = action.payload
    },
    setRentStoneEntryInfo(state, action) {
      state.rentStoneEntryInfo = action.payload
    },
    setRentPearlEntryInfo(state, action) {
      state.rentPearlEntryInfo = action.payload
    },
    setRentProductEntryInfo(state, action) {
      state.rentProductEntryInfo = action.payload
    },
    setRentBasicEntryInfo(state, action) {
      state.rentBasicEntryInfo = action.payload
    },
    setRentEntryNo(state, action) {
      state.rentEntryNo = action.payload
    },
    setRentCommodityNo(state, action) {
      state.rentCommodityNo = action.payload
    },
    setRentAgainStoneEntryInfo(state, action) {
      state.rentAgainStoneEntryInfo = action.payload
    },
    setRentAgainPearlEntryInfo(state, action) {
      state.rentAgainPearlEntryInfo = action.payload
    },
    setRentAgainProductEntryInfo(state, action) {
      state.rentAgainProductEntryInfo = action.payload
    },
    setRentAgainBasicEntryInfo(state, action) {
      state.rentAgainBasicEntryInfo = action.payload
    },
    setDesignEntryDetailDataInfo(state, action) {
      state.designEntryDetailDataInfo = action.payload
    },
    setDesignOutDetailDataInfo(state, action) {
      state.designOutDetailDataInfo = action.payload
    },
    setRentOutputInfo(state, action) {
      state.rentOutputInfo = action.payload
    },
    setEntryManageEntryNo(state, action) {
      state.entryManageEntryNo = action.payload
    },
    setStorageOutPutNo(state, action) {
      state.storageOutPutNo = action.payload
    },
    setWarehouseInventoryNo(state, action) {
      state.warehouseInventoryNo = action.payload
    },
    setCommodityTransferNo(state, action) {
      state.commodityTransferNo = action.payload
    },
    setSupplierReturnNo(state, action) {
      state.supplierReturnNo = action.payload
    },
    setOrderReturnNo(state, action) {
      state.orderReturnNo = action.payload
    },
    setCommodityRentOutNo(state, action) {
      state.commodityRentOutNo = action.payload
    },
    // setSyntheticProductInfo(state, action) {
    //   state.syntheticProductInfo = action.payload
    // },
    setEditFactoryOrderInfo(state, action) {
      state.editFactoryOrderInfo = action.payload
    },
    setAgainFactoryOrderInfo(state, action) {
      state.againFactoryOrderInfo = action.payload
    },
    setDesignFactoryOrderInfo(state, action) {
      state.designFactoryOrderInfo = action.payload
    },
    setEntryFactoryOrderInfo(state, action) {
      state.entryFactoryOrderInfo = action.payload
    },
    setNewsFactoryOrderNo(state, action) {
      state.newsFactoryOrderNo = action.payload
    },
    setNewsRepairCommodityNo(state, action) {
      state.newsRepairCommodityNo = action.payload
    },
    setNewsCommodityTransferNo(state, action) {
      state.newsCommodityTransferNo = action.payload
    },
    setOutputFactoryOrderInfo(state, action) {
      state.outputFactoryOrderInfo = action.payload
    },
    setDesignCommodityDataInfo(state, action) {
      state.designCommodityDataInfo = action.payload
    },
    setEdataShopDataInfo(state, action) {
      state.edataShopDataInfo = action.payload
    },
    setCreateStorageOutImportInfo(state, action) {
      state.createStorageOutImportInfo = action.payload
    },
    setTransCommodityManageNo(state, action) {
      state.transCommodityManageNo = action.payload
    },
    setEditRentOutInfo(state, action) {
      state.editRentOutInfo = action.payload
    },
    setAggregationDataInfo(state, action) {
      state.aggregationDataInfo = action.payload
    },
    setAggregationOperateInfo(state, action) {
      state.aggregationOperateInfo = action.payload
    },
    setEdataInternalStoreDataInfo(state, action) {
      state.edataInternalStoreDataInfo = action.payload
    },
    setProductDataInfo(state, action) {
      state.productDataInfo = action.payload
    },
    setAddRepairTicketInfo(state, action) {
      state.addRepairTicketInfo = action.payload
    },
    setKeywordAnalysisInfo(state, action) {
      state.keywordAnalysisInfo = action.payload
    },
    setEdataReviewDataInfo(state, action) {
      state.edataReviewDataInfo = action.payload
    },
  },
})

const {
  actions,
  reducer
} = pageParams;

export const {
  setEditStoneEntryInfo,
  setEditPearlEntryInfo,
  setEditProductEntryInfo,
  setEditBasicEntryInfo,
  setEditGiftEntryInfo,
  setRentStoneEntryInfo,
  setRentPearlEntryInfo,
  setRentProductEntryInfo,
  setRentBasicEntryInfo,
  setRentEntryNo,
  setRentCommodityNo,
  setRentAgainStoneEntryInfo,
  setRentAgainPearlEntryInfo,
  setRentAgainProductEntryInfo,
  setRentAgainBasicEntryInfo,
  setDesignEntryDetailDataInfo,
  setDesignOutDetailDataInfo,
  setRentOutputInfo,
  setEntryManageEntryNo,
  setStorageOutPutNo,
  setWarehouseInventoryNo,
  setCommodityTransferNo,
  setSupplierReturnNo,
  setOrderReturnNo,
  setCommodityRentOutNo,
  // setSyntheticProductInfo,
  setEditFactoryOrderInfo,
  setAgainFactoryOrderInfo,
  setDesignFactoryOrderInfo,
  setEntryFactoryOrderInfo,
  setNewsFactoryOrderNo,
  setNewsRepairCommodityNo,
  setNewsCommodityTransferNo,
  setOutputFactoryOrderInfo,
  setDesignCommodityDataInfo,
  setEdataShopDataInfo,
  setCreateStorageOutImportInfo,
  setTransCommodityManageNo,
  setEditRentOutInfo,
  setAggregationDataInfo,
  setAggregationOperateInfo,
  setEdataInternalStoreDataInfo,
  setProductDataInfo,
  setAddRepairTicketInfo,
  setKeywordAnalysisInfo,
  setEdataReviewDataInfo,
} = actions

export default reducer