import React, { useEffect } from 'react'
import {
  Router,
  Switch,
  Route,
} from 'react-router-dom'
import {
  Provider,
} from 'react-redux'
import {
  ConfigProvider, notification,
} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import EdataTotalData from '@/pages/EdataInternalStoreAggregationH5/main'
import Layout from './Layout'
import Login from './Login'
import store from './utils/store'
import history from './utils/history'
import {
  isChrome,
  isMobile,
} from './utils'

if (isMobile() && location.pathname !== '/edata_total_data') {
  notification.open({
    message: '温馨提示',
    duration: 5,
    description: (
      <section className="use_chrome_notice">
        <p>为了获得最佳使用体验，请在电脑上使用谷歌浏览器访问。</p>
      </section>
    )
  });
} else if (!isChrome()) {
  // notification.open({
  //   message: '温馨提示',
  //   duration: 10,
  //   description: (
  //     <section className="use_chrome_notice">
  //       <p>您的浏览器不是谷歌浏览器，无法获得最佳使用体验，建议使用谷歌浏览器。</p>
  //       <p>谷歌浏览器下载地址： <a href="https://www.google.cn/intl/zh-CN/chrome/" target="_blank">https://www.google.cn/intl/zh-CN/chrome/</a></p>
  //     </section>
  //   )
  // });
}

function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <ConfigProvider locale={zhCN}>
        <Provider store={store}>
          <Router history={history}>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              {/* <Route path="/edata_internal_store_aggregation_h5">
                <EdataTotalData />
              </Route> */}
              <Route path="/">
                <Layout />
              </Route>
            </Switch>
          </Router>
        </Provider>
      </ConfigProvider>
    </DndProvider>
  )
}

export default App