import Big from 'big.js'
import fetch from './index'
import {
  formatDuring
} from '@/utils'

// 获取商品类型列表
export function getParentCategories(params): any {
  return fetch.get('/categories', {
    params
  })
}

// 获取商品品类列表
export function getCategoriesList(params): any {
  return fetch.get('/categories/list', {params})
}

export function addCategory(params) {
  return fetch.post('/categories', params)
}

export function editCategory(params) {
  return fetch.put('/categories', params)
}

export function delCategory(id) {
  return fetch.delete(`/categories/${id}`)
}

// 校验品类是否已关联货品，编辑时用
export function checkCategory(id) {
  return fetch.get(`/categories/check?categoryId=${id}`)
}

// 获取推荐品类
export function getRecommendCats(params): any {
  return fetch.get('/categories/default', {
    params
  })
}

// 新增并复制某品类的属性及属性值
export function getCopyCategory(params): any {
  return fetch.get('/categories/copy', {params})
}

// 调整品类顺序
export function putSortCategory(params) {
  return fetch.put('/categories/sort', params)
}

// 获取报价列表
export function getGoldPriceList(params): any {
  return fetch.get('/eps/gold/price/list', {
    params
  })
}

// 调整黄金报价顺序
export function putSortGoldPrice(params) {
  return fetch.post('/eps/gold/price/sort', params)
}

export function editGoldPrice(params) {
  return fetch.put('/eps/gold/price', params)
}

export function setGoldPrice(params) {
  return fetch.get('/eps/gold/price/status', { params })
}

export function getCommoditiesPirceMiniList(params): any {
  return fetch.get('/eps/gold/price/mini/list', {
    params,
  }).then((data: any) => {
    return {
      ...data,
      models: (data?.models || []).map(v => {
        return {
          ...v,
          buyBackPrice: new Big(v.buyBackPrice || 0).div(100).toJSON(),
          maxPrice: new Big(v.maxPrice || 0).div(100).toJSON(),
          minPrice: new Big(v.minPrice || 0).div(100).toJSON(),
          sellPrice: new Big(v.sellPrice || 0).div(100).toJSON()
        }
      })
    }
  })
}

// 创建活动
export function updateSpecials(params) {
  return fetch.post('/seller/commodity/specials', params)
}

// 修改
export function editSpecials(params) {
  return fetch.put('/seller/commodity/specials', params)
}

// 删除
export function deleteSpecials(params) {
  return fetch.delete(`/seller/commodity/specials?id=${params.id}`)
}

// 列表
export function getSpecialsList(params) {
  return fetch.get(`/seller/commodity/specials/page`, { params })
}

// 详情
export function detailSpecialsList(params) {
  return fetch.get(`/seller/commodity/specials/ids`, { params })
}

export function getCommoditySpecialList(params): any {
  return fetch.get('/seller/commodity/specials/ids', {
    params,
  }).then((resp) => {
    const data =  (resp || [] as any).map(v => {
      return {
        ...v,
        labelPrice: new Big(v?.labelPrice).div(100).toJSON(),
        eventPrice: new Big(v?.eventPrice).div(100).toJSON(),
        startTimes: formatDuring(v?.startTime - v?.nowTime || 0) || "",
        endTimes: v.endTime > v?.nowTime ? formatDuring(v?.endTime - v?.nowTime || 0) : ""
      }
    })
    return data.filter((v: any) => v.nowTime <= v.endTime);
  })
}


// 详情
export function getSpecialsUser(params) {
  return fetch.get(`/seller/commodity/specials/user`, { params })
}

export function getShopAddressList(params) {
  return fetch.get(`/shop/address/list`, { params })
}

export function AddShopAddress(params) {
  return fetch.post(`/shop/address`,  params)
}

export function editShopAddress(params) {
  return fetch.put(`/shop/address`,  params)
}

export function deleteShopAddress(id) {
  return fetch.delete(`/shop/address?id=${id}`)
}

export function putSortAddress(params) {
  return fetch.post(`/shop/address/sort`, params)
}

export function handleHideAddress(id) {
  return fetch.put(`/shop/address/show?id=${id}`)
}










