import React, {
  useState,
} from 'react'
import {
  Popover,
  Button,
} from 'antd'

import styles from './index.less'

function DeleteWarning(props) {
  const {
    id,
    btns,
    tipOne,
    tipTwo = '',
    delBtn,
    trigger = 'click',
    placement = 'top'
  } = props

  const [visible, setVisible] = useState(false)
  const [closePreview, setClosePreview] = useState(false)
  const [selectChange, setSelectChange] = useState(false)

  function onClose() {
    setVisible(false)
  }

  function onVisibleChange(visible) {
    setTimeout(() => {
      if (closePreview && !visible) {
        setClosePreview(false)
      } else if (selectChange && !visible) {
        setSelectChange(false)
      } else {
        setVisible(visible)
      }
    }, 150)
  }

  const content = (
    <div className={styles.delWrap}>
      <div className={styles.title}>提示信息</div>
      <div className={styles.tip}>{tipOne}</div>
      {
        tipTwo ? 
        <div className={styles.tip}>{tipTwo}</div>
        : null
      }
      <div className={styles.btnWrap}>
        {
          btns.map((v, i) => (
            <Button
              key={i}
              className={styles.btn}
              type={v.type || 'default'}
              danger={v.danger ? true : false}
              onClick={v.close ? onClose : () => {v.onPress(id, onClose)}}
            >
              {v.text}
            </Button>
          ))
        }
      </div>
    </div>
  )

  return (
    <Popover
      trigger={trigger}
      content={content}
      visible={visible}
      onVisibleChange={onVisibleChange}
      arrowPointAtCenter
      autoAdjustOverflow={false}
      // getPopupContainer={(triggerNode:any) => triggerNode.parentNode}
      placement={placement}
    >
      {delBtn}
    </Popover>  
  )
}

export default DeleteWarning