import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getPowers as getPowersAPI,
} from '../services/powers'

export const getPowers = createAsyncThunk(
  'powers/infos',
  async () => {
    const data = await getPowersAPI()
    return data || []
  }
)

const powerSlice = createSlice({
  name: 'power',
  initialState: {
    powers: []
  },
  reducers: {
  },
  extraReducers: {
    [getPowers.fulfilled as any]: (state, action) => {
      state.powers = action.payload
    },
  }
})

const {
  reducer,
} = powerSlice;

export default reducer