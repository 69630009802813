import axios from 'axios'
import randomize from 'randomatic'

import {
  errorHandler,
  hrefs,
  sleep,
  globalLoading,
} from '@/utils'
import {
  get,
} from '@/utils/session'
import {
  addLoading,
  removeLoading,
} from '@/reducers/global'

const fingerprintTimeout = 1000 * 60 * 5;

const noLoadingFetchUrls = ['/user/msg/count/0', '/user/msg/count/1','/edata/internal/data/list/aggregation','/edata/internal/data/list']
const specialFetchUrls = ['/orders/returns/page/list', '/orders/returns/page/detail/list/new']


const fetch = axios.create({
  baseURL: hrefs,
  timeout: 30000
})

fetch.interceptors.request.use(async (config: any) => {
  config.uuid = randomize('aA0', 16)
  const session = get();


  session.forEach(([key, value]) => {
    config.headers[key] = value || ""
  })

  if ((globalLoading.show || specialFetchUrls.indexOf(config?.url) > -1) && noLoadingFetchUrls.indexOf(config?.url) == -1) {
    const {
      default: store,
    } = await import('@/utils/store')
    store.dispatch(addLoading(config.uuid));
  }
  // 检测是否安装插件
  let dom: any = document.getElementById('__ZHIMIAO_SECURITY_CHROME_EEXTENSION__');
  const extensionInstalled = dom?.value;
  if (!extensionInstalled) { return config }
  config.headers['X-Fingerprint2'] = extensionInstalled;
  return config


  // const extensionInstalled = (document.getElementById('__ZHIMIAO_SECURITY_CHROME_EXTENSION__') as HTMLInputElement)?.value;

  // if (!extensionInstalled) return config

  // const startTime = new Date().getTime();
  // const stroageKey = '__ZHIMIAO_SECURITY_FINGERPRINT_CACHE__'
  // const fingerprintCache: any = JSON.parse(localStorage.getItem(stroageKey));

  // const rst = await Promise.race([
  //   sleep(fingerprintTimeout).then(() => config),
  //   new Promise(resolveHandler => {
  //     if (fingerprintCache?.value && fingerprintCache?.time && (new Date().getTime() - fingerprintCache?.time < 5 * 60 * 1000)) {
  //       config.headers['X-Fingerprint'] = fingerprintCache?.value;
  //       resolveHandler(config);
  //     } else {
  //       try {
  //         chrome.runtime.sendMessage('mephgjhabkdamhpflfmikihooaflhmpf', {
  //           type: 'fingerprint',
  //         }, fingerprint => {
  //           config.headers['X-Fingerprint'] = fingerprint;
  //           localStorage.setItem(stroageKey, JSON.stringify({
  //             value: fingerprint,
  //             time: new Date().getTime(),
  //           }))
  //           resolveHandler(config);
  //         });
  //       } catch(e) {
  //         resolveHandler(config)
  //       }
  //     }
  //   })
  // ])

  // return rst
}, async error => {
  const {
    default: store,
  } = await import('@/utils/store')

  store.dispatch(removeLoading(null));

  throw error;
});

fetch.interceptors.response.use(async (response: any) => {
  const data = response?.data
  const code = data?.code
  const {
    default: store,
  } = await import('@/utils/store')

  store.dispatch(removeLoading(response?.config?.uuid));

  if (code !== '200') {
    errorHandler(data)
    throw data;
  }

  return data?.data;
}, async error => {
  const status = error?.response?.data?.data?.status

  const {
    default: store,
  } = await import('@/utils/store')

  store.dispatch(removeLoading(error?.config?.uuid));

  errorHandler(error, status)

  throw error
})

export default fetch