import React from 'react'
import {
  render,
} from 'react-dom'
import { defineCustomElements, applyPolyfills } from '@tarojs/components/loader'

import './style/index.less'
import App from './App'

import '@tarojs/components/dist/taro-components/taro-components.css'

applyPolyfills().then(function () {
  defineCustomElements(window)
})

window.onload = () => {
  // 安全插件注入后再渲染
  render(<App />, document.getElementById('root'))
}