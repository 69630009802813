import React from 'react'
import LayoutLeftSide from '@/components/LayoutLeftSide'
import LayoutHeader from '@/components/LayoutHeader'
import PageContainer from '@/components/PageContainer'
import styles from './index.less'

function Layout() {

  return (
    <div className={styles.container}>
      <LayoutLeftSide />
      <LayoutHeader />
      <PageContainer />
    </div>
  )
}

export default Layout