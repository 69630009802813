import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getChannelsList as getChannelsAPI
} from '../services/channels'

export const getChannelsList = createAsyncThunk<any, any, any>(
  'channels/list',
  async (params) => {
    const data = await getChannelsAPI(params)
    return data || []
  }
)

const channelSlice = createSlice({
  name: 'channel',
  initialState: {
    channels: []
  },
  reducers: {
  },
  extraReducers: {
    [getChannelsList.fulfilled as any]: (state, action) => {
      state.channels = action.payload
    },
    [getChannelsList.rejected as any]: (state, action) => {
      state.channels = []
    }
  }
})

const {
  reducer
} = channelSlice

export default reducer