const routes: any = [{
  title: '首页',
  path: '/',
  key: 'HOME',
  help: false,
  link: '/'
}, {
  title: '新增款式',
  path: '/style_addition',
  key: 'STYLE_ADDITION',
  help: true,
  link: '/'
}, {
  title: '编辑款式',
  path: '/style_editor/:id',
  key: 'STYLE_EDITOR',
  help: true,
  link: '/'
}, {
  title: '款式详情',
  path: '/style_details/:id/:time?',
  key: 'STYLE_DETAILS',
  help: true,
  link: '/'
}, {
  title: '柜组管理',
  path: '/cabinet_manage/:id/:warehousName',
  key: 'CABINET_MANAGE',
  help: true,
  link: '/'
}, {
  title: '属性值管理',
  path: '/feature_value_manage/:categoryId/:featureNameId/:parentName/:categoryName/:featureName/:featureEngName?',
  key: 'FEATURE_VALUE_MANAGE',
  help: true,
  link: '/'
}, {
  title: '库存盘点',
  path: '/warehouse_inventory/:id?/:epsWarehouseName?/:epsWarehouseId?/:epsCabinetName?/:epsCabinetId?',
  key: 'WAREHOUSE_INVENTORY',
  help: true,
}, {
  title: '创建订单',
  path: '/create_storage_out/:id?/:time?',
  key: 'CREATE_STORAGE_OUT',
  help: true,
}, {
  title: '订单查询',
  path: '/storage_out_query/:id?/:time?',
  key: 'STORAGE_OUT_QUERY',
  help: true,
}, {
  key: 'SECURITY_SETTING',
  help: true,
}, {
  title: '库存管理',
  path: '/inventory_manage_child/:id?/:time?',
  key: 'INVENTORY_MANAGE_CHILD',
  help: true,
}, {
  title: '入库单管理',
  path: '/entry_manage/:id?/:time?',
  key: 'ENTRY_MANAGE',
  help: true,
}, {
  title: '新增优惠券',
  path: '/seller_add_coupon',
  key: 'SELLER_ADD_COUPON',
  help: true,
  link: '/'
}, {
  title: '编辑优惠券',
  path: '/edit_coupon/:id',
  key: 'EDIT_COUPON'
}, {
  title: '属性值管理',
  path: '/platform_choose_feature_manage/:categoryId/:featureNameId/:parentName/:categoryName/:featureName',
  key: 'PLATFORM_CHOOSE_FEATURE_MANAGE',
  help: true,
  link: '/'
}, {
  key: 'SELLER_APPLETS_HOME_PAGE',
  path: '/seller_applets_home_page/:id?'
}, {
  title: '商品编辑',
  path: '/seller_commodity_edit/:id/:type/:role/:time',
  key: 'SELLER_COMMODITY_EDIT',
  help: true,
  link: '/'
}, {
  title: '创建选品',
  path: '/selection_commodity_add',
  key: 'SELECTION_COMMODITY_ADD',
  help: true,
  link: '/'
}, {
  title: '编辑选品',
  path: '/selection_commodity_edit/:id/:role/:type',
  key: 'SELECTION_COMMODITY_EDIT',
  help: true,
  link: '/'
}, {
  title: '销售退货',
  path: '/order_return/:orders?/:id?/:detailOrder?',
  key: 'ORDER_RETURN',
}, {
  title: '珠宝饰品',
  path: '/seller_gemstone_order/:id?',
  key: 'SELLER_GEMSTONE_ORDER',
}, {
  title: 'GIA',
  path: '/seller_gia_order/:id?',
  key: 'SELLER_GIA_ORDER',
}, {
  title: '选品订单',
  path: '/seller_choose_order_delivery/:id?',
  key: 'SELLER_CHOOSE_ORDER_DELIVERY',
}, {
  title: '商户选品单',
  path: '/platform_gem_order/:id?',
  key: 'PLATFORM_GEM_ORDER',
}, {
  title: '商户GIA单',
  path: '/platform_gia_order/:id?',
  key: 'PLATFORM_GIA_ORDER',
}, {
  title: '商品管理',
  path: '/seller_commodity_manage/:groupId?/:parentGroup?/:randomNo?',
  key: 'SELLER_COMMODITY_MANAGE'
}, {
  title: '选品商品',
  path: '/seller_outer_commodity/:groupId?/:parentGroup?/:randomNo?',
  key: 'SELLER_OUTER_COMMODITY',
}, {
  title: '新增限时特价',
  path: '/seller_commodity_special_add',
  key: 'SELLER_COMMODITY_SPECIAL_ADD',
}, {
  title: '编辑限时特价',
  path: '/seller_commodity_special_edit/:id?/:ids?',
  key: 'SELLER_COMMODITY_SPECIAL_EDIT',
}, {
  title: '分销详情',
  path: '/seller_distributor_detail',
  key: 'SELLER_DISTRIBUTOR_DETAIL',
}, {
  title: '打印模版详情',
  path: '/document_label_setting_details/:type?/:id?',
  key: 'DOCUMENT_LABEL_SETTING_DETAILS',
  help: true,
  link: '/'
}, {
  title: '添加活动',
  path: '/activity_menu_add/:id?',
  key: 'ACTIVITY_MENU_ADD',
}, {
  title: '修改活动',
  path: '/activity_menu_edit/:id?',
  key: 'ACTIVITY_MENU_EDIT',
}, {
  title: '裸石入库',
  path: '/stone_entry/:id?/:time?',
  key: 'STONE_ENTRY',
  help: true,
}, {
  title: '裸珠入库',
  path: '/pearl_entry/:id?/:time?',
  key: 'PEARL_ENTRY',
  help: true,
}, {
  title: '成品入库',
  path: '/product_entry/:id?/:time?',
  key: 'PRODUCT_ENTRY',
  help: true,
}, {
  title: '统包入库',
  path: '/basic_entry/:id?/:time?',
  key: 'BASIC_ENTRY',
  help: true,
}, {
  title: '赠品入库',
  path: '/gift_entry/:id?/:time?',
  key: 'GIFT_ENTRY',
  help: true,
}, {
  title: '借入单管理',
  path: '/rent_entry_select/:id?/:time?',
  key: 'RENT_ENTRY_SELECT',
  help: true,
}, {
  title: '裸石借入',
  path: '/rent_entry_stone/:time?',
  key: 'RENT_ENTRY_STONE',
  help: true,
}, {
  title: '裸珠借入',
  path: '/rent_entry_pearl/:time?',
  key: 'RENT_ENTRY_PEARL',
  help: true,
}, {
  title: '成品借入',
  path: '/rent_entry_product/:time?',
  key: 'RENT_ENTRY_PRODUCT',
  help: true,
}, {
  title: '统包借入',
  path: '/rent_entry_basic/:time?',
  key: 'RENT_ENTRY_BASIC',
  help: true,
}, {
  title: '库存盘点查询',
  path: '/warehouse_inventory_query/:id?/:time?',
  key: 'WAREHOUSE_INVENTORY_QUERY',
  help: true,
}, {
  title: '商品调拨查询',
  path: '/commodity_transfer_query/:id?/:time?',
  key: 'COMMODITY_TRANSFER_QUERY',
  help: true,
}, {
  title: '采购退货查询',
  path: '/supplier_return_query/:id?/:time?',
  key: 'SUPPLIER_RETURN_QUERY',
  help: true,
}, {
  title: '销售退货查询',
  path: '/order_return_query/:id?/:time?',
  key: 'ORDER_RETURN_QUERY',
  help: true,
}, {
  title: '库存借出',
  path: '/commodity_rent_out_mange/:id?/:time?',
  key: 'COMMODITY_RENT_OUT_MANGE',
  help: true,
}, {
  title: '借出单查询',
  path: '/commodity_rent_out_query/:id?/:time?',
  key: 'COMMODITY_RENT_OUT_QUERY',
  help: true,
}, {
  title: '工厂下单',
  path: '/factory_create_order/:id?/:time?',
  key: 'FACTORY_CREATE_ORDER',
  help: true,
}, {
  title: '工单进度',
  path: '/factory_schedule/:id?/:time?',
  key: 'FACTORY_SCHEDULE',
  help: true,
}, {
  title: '维修下单',
  path: '/add_repair_ticket/:id?/:time?',
  key: 'ADD_REPAIR_TICKET',
  help: true,
}, {
  title: '维修进度',
  path: '/factory_repair_ticket/:id?/:time?',
  key: 'FACTORY_REPAIR_TICKET',
  help: true,
}, {
  title: '商品名称自定义',
  path: '/commodity_name_custom',
  key: 'COMMODITY_NAME_CUSTOM',
  help: true,
}, {
  title: '工单设置',
  path: '/stone_name_custom',
  key: 'STONE_NAME_CUSTOM',
  help: true,
}, {
  title: '消息中心',
  path: '/news_center/:id?/:time?',
  key: 'NEWS_CENTER',
  help: true,
}, {
  title: '报价配置',
  path: '/exchange_manage/:id?/:time?',
  key: 'EXCHANGE_MANAGE',
  help: true,
}, {
  title: '报价计算器',
  path: '/gold_price_calc/:id?/:time?',
  key: 'GOLD_PRICE_CALC',
  help: true,
}, {
  title: 'Edata店铺数据',
  path: '/edata_shop_data/:id?/:time?',
  key: 'EDATA_SHOP_DATA',
  help: true,
}, {
  title: '批量创建订单',
  path: '/create_import_storage_out/:time?',
  key: 'CREATE_IMPORT_STORAGE_OUT',
  help: true,
}, {
  title: '销售发货退货报表',
  path: '/sales_delivery_return_statement',
  key: 'SALES_DELIVERY_RETURN_STATEMENT',
  help: true,
}, {
  title: '财务采购单报表',
  path: '/supplier_factory_detail_data',
  key: 'SUPPLIER_FACTORY_DETAIL_DATA',
  help: true,
}, {
  title: 'Edata商品数据',
  path: '/edata_item_data/:shopid?/:time?',
  key: 'EDATA_ITEM_DATA',
  help: true,
}, {
  title: 'Edata内部店铺邮箱模板详情',
  path: '/edata_sales_email_template_detail',
  key: 'EDATA_SALES_EMAIL_TEMPLATE_DETAIL',
  help: true,
}, {
  title: 'Edata内部店铺邮箱模板编辑详情',
  path: '/edata_sales_email_template_detailtwo/:xxid?/:xxsalesChannelId?',
  key: 'EDATA_SALES_EMAIL_TEMPLATE_DETAILTWO',
  help: true,
}, {
  title: 'Top词频',
  path: '/edata_word_top',
  key: 'EDATA_WORD_TOP',
  help: true,
}, {
  title: '店铺目标',
  path: '/store_goal',
  key: 'STORE_GOAL',
  help: true,
}, {
  title: 'Edata内部商品数据',
  path: '/inner_item_data/:shopid?/:time?',
  key: 'INNER_ITEM_DATA',
  help: true,
}, {
  title: '选品属性词分析',
  path: '/product_select_word',
  key: 'PRODUCT_SELECT_WORD',
  help: true,
}, {
  title: '店铺数据',
  path: '/edata_internal_store_data/:time?',
  key: 'EDATA_INTERNAL_STORE_DATA',
  help: true,
}, {
  title: '产品运营动作登记',
  path: '/product_data/:time?',
  key: 'PRODUCT_DATA',
  help: true,
}, {
  title: '店铺关键词分析',
  path: '/edata_shop_keyword_analysis/:time?',
  key: 'EDATA_SHOP_KEYWORD_ANALYSIS',
  help: true,
}, {
  title: 'Edata店铺评论',
  path: '/edata_review_data/:time?',
  key: 'EDATA_REVIEW_DATA',
  help: true,
}]

export default routes
