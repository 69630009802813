import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getUserMsgCount as getUserMsgCountAPI
} from '@/services/newscenter'

export const getUserAllMsgCount = createAsyncThunk<any, any, any>(
  'news/allCount',
  async () => {
    const data = await getUserMsgCountAPI(0)
    return data || 0
  }
)

export const getUserNoReadMsgCount = createAsyncThunk<any, any, any>(
  'news/noReadCount',
  async () => {
    const data = await getUserMsgCountAPI(1)
    return data || 0
  }
)

const newsSlice = createSlice({
  name: 'news',
  initialState: {
    allCount: 0,
    noReadCount: 0,
  },
  reducers: {
    
  },
  extraReducers: {
    [getUserAllMsgCount.fulfilled as any]: (state, action) => {
      state.allCount = action.payload
    },
    [getUserAllMsgCount.rejected as any]: (state, action) => {
      state.allCount = 0
    },
    [getUserNoReadMsgCount.fulfilled as any]: (state, action) => {
      state.noReadCount = action.payload
    },
    [getUserNoReadMsgCount.rejected as any]: (state, action) => {
      state.noReadCount = 0
    },
  }
})

const {
  reducer,
} = newsSlice;

export default reducer