import React, { useState, useEffect, useRef } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  MenuOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined
} from "@ant-design/icons";
import { Popconfirm, Button, Modal, message } from "antd";
import Icon from "../Icon";
import RecentVisit from "../RecentVisit";
import HelpHeader from "../HelpHeader";
import styles from "./index.less";
import DragField from "./exportModal/DragField";
import DragSort from "./exportModal/DragSort";
import useHideHelp from "../../hooks/useHideHelp";
import classNames from "classnames";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DropTarget } from "react-dnd";
import { getShortcutMenu, setShortcutMenu } from "@/services/menu";
import { openShortcutMenu } from "@/reducers/menu";
import { globalLoading } from '@/utils';

const { confirm } = Modal;

function LayoutHeader(props: any) {
  const { connectDropTarget } = props;
  const routes = useSelector<any, any[]>((state) => state?.route?.routes);
  const hideHelp = useHideHelp();
  const history = useHistory();
  const noReadNewsNum = useSelector<any, any[]>(
    (state) => state?.news?.noReadCount
  );
  const userRoles = useSelector<any, any>(
    (state) => state?.user?.userInfo?.roleTypes || []
  );
  const browserUrl = useSelector<any, any>(
    (state) => state?.browserUrl?.browserUrl || ""
  );
  const menusList = useSelector<any, any[]>((state) => state?.menu?.menus);

  const refValueBol = useRef(null);
  const dispatch = useDispatch();
  const isShow = useSelector<any, any[]>((state) => state?.menu?.isShow);
  const [menusListAfter, setmenusListAfter] = useState<any>([]);
  const [menus, setMenus] = useState<any>([]);
  const [menusNow, setMenusNow] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  function handleToNewsCenter() {
    history.push(`/news_center/${1}/${new Date().getTime()}`);
  }

  const openShortcut = () => {
    if (isShow) {
      dispatch(openShortcutMenu(false));
      cancel()
    } else {
      dispatch(openShortcutMenu(true));
    }
  };

  const getMenu = (menus, val) => {
    let obj;
    (menus || []).some((it) => {
      if (it.link && it.key === val) {
        obj = it;
        return true;
      } else if (it?.children && it?.children?.length) {
        obj = getMenu(it?.children, val);
      }
      return obj;
    });
    return obj;
  };

  const treeToOneArr = (arr) => {
    let data = JSON.parse(JSON.stringify(arr));
    if (data?.length) {
      return data.map((v) => {
        if (v.link) {
          return v;
        } else {
          return treeToOneArr(v?.children || []);
        }
      });
    }
    return [];
  };

  useEffect(() => {
    if (menusList && menusList?.length) {
      setmenusListAfter((JSON.parse(JSON.stringify(menusList)) || []).map(v => {
        if (v.link) {
          return v
        }
        v.children = treeToOneArr(v.children)?.flat(Infinity);
        return v
      }))
      globalLoading.prevent(() => {
        getShortcutMenu().then((res: any) => {
          let arr = [];
          if (res?.menus) {
            arr = res?.menus?.split(",");
          }
          let arrAfter = arr.map((v) => {
            return getMenu(menusList, v);
          });
          setMenus(arrAfter);
          setMenusNow(arrAfter);
        });
      })()
    }
  }, [menusList]);

  const save = () => {
    setShortcutMenu({
      menus: (menusNow || []).map((v) => v.key)?.join(","),
    }).then((res) => {
      setIsOpen(false);
      setMenus(menusNow);
      setMenus(menusNow);
      setIsEditing(false);
    });
  };

  const cancel = () => {
    setIsOpen(false);
    setMenusNow(menus);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.recentVisit}>
          <RecentVisit />
          <div className={styles.shortcutBtn}>
            <Button type="link" onClick={openShortcut}>
              <span>常用操作</span>
              {isShow ? <CaretUpOutlined /> : <CaretDownOutlined />}
            </Button>
          </div>
          {userRoles.indexOf(3) > -1 &&
            browserUrl.indexOf("/news_center") == -1 ? (
            <div className={styles.warnWrap} onClick={handleToNewsCenter}>
              <Icon className={styles.warnBell} type="warnClock" />
              {noReadNewsNum ? (
                <div className={styles.warnIcon}>{noReadNewsNum}</div>
              ) : null}
            </div>
          ) : null}
        </div>
        {routes.length ? (
          <Switch>
            {hideHelp}
            <Route path="*">
              <div className={styles.help}>
                <HelpHeader />
              </div>
            </Route>
          </Switch>
        ) : null}
      </div>

      {isShow ? (
        <DndProvider backend={HTML5Backend}>
          {connectDropTarget(
            <div className={styles.shortcutMenu}>
              {menusNow.map((val, idx) => {
                return (
                  <DragSort
                    refValueBol={refValueBol}
                    key={val?.key}
                    index={idx}
                    dropClassName={styles.menuItem}
                    RenderDom={() => <span onClick={() => {
                      if (!isEditing) {
                        history.push(val.link)
                      }
                    }}>
                      <span>{val?.title}</span>
                      {
                        isEditing ? <CloseCircleOutlined style={{ marginLeft: '4px' }} onClick={() => {
                          const newList = JSON.parse(JSON.stringify(menusNow));
                          newList.splice(idx, 1);
                          setMenusNow(newList);
                          // confirm({
                          //   title: `删除 ${menusNow[idx]?.title} 快捷菜单吗?`,
                          //   icon: <ExclamationCircleOutlined />,
                          //   zIndex: 3000,
                          //   onOk() {
                          //     const newList = JSON.parse(JSON.stringify(menusNow));
                          //     newList.splice(idx, 1);
                          //     setMenusNow(newList);
                          //   },
                          // });
                        }} /> : null
                      }
                    </span>}
                    onMove={(dragIndex, hoverIndex, obj) => {
                      if (isEditing) {
                        if (isOpen) {
                          if (dragIndex !== undefined) {
                            const dragItem = menusNow[dragIndex];
                            const newList = menusNow.filter((...[, i]) => i !== dragIndex);
                            newList.splice(hoverIndex, 0, dragItem);
                            setMenusNow(newList);
                          } else {
                            const newList = JSON.parse(JSON.stringify(menusNow));
                            if (newList.length < 10) {
                              newList.splice(hoverIndex, 0, obj);
                              setMenusNow(newList);
                            } else {
                              message.error("最多添加10个")
                            }
                          }
                        }
                      } else {
                        // message.error("请先点击【编辑】按钮，再进行操作")
                      }
                    }}
                    onDelete={(i) => {
                      // if (isOpen) {
                      //   confirm({
                      //     title: `删除 ${menusNow[i]?.title} 快捷菜单吗?`,
                      //     icon: <ExclamationCircleOutlined />,
                      //     zIndex: 3000,
                      //     onOk() {
                      //       const newList = JSON.parse(JSON.stringify(menusNow));
                      //       newList.splice(i, 1);
                      //       setMenusNow(newList);
                      //     },
                      //   });
                      // }
                    }}
                  />
                );
              })}

              <Popconfirm
                placement="bottomRight"
                visible={isOpen}
                title={() => {
                  return (
                    <>
                      <div className={styles.itemBox}>
                        {(menusListAfter || []).map((val, idx) => {
                          return (
                            <div key={idx} className={styles.item}>
                              <div className={styles.itemTitle}>
                                {val?.title}
                              </div>
                              <div className={styles.itemContent}>
                                {(val?.children || []).map(
                                  (valMenu, idxMenu) => {
                                    let bol = menusNow.some((v) => v.key === valMenu.key);
                                    return (
                                      <DragField
                                        list={menusNow}
                                        key={idxMenu}
                                        val={valMenu}
                                        dragClassName={classNames(styles.filedItem, bol ? styles.filedItemDisabled : "")}
                                        onDrop={obj => {
                                          if (isEditing) {
                                            if (!refValueBol.current) {
                                              const newList = JSON.parse(JSON.stringify(menusNow));
                                              if (newList.length < 10) {
                                                newList.push(obj);
                                                setMenusNow(newList);
                                              } else {
                                                message.error("最多添加10个")
                                              }
                                            }
                                            refValueBol.current = false;
                                          } else {
                                            // message.error("请先点击【编辑】按钮，再进行操作")
                                          }
                                        }}
                                        RenderDom={() => {
                                          return (
                                            <>
                                              {isEditing ? <MenuOutlined className={styles.filedItemIcon} /> : null}
                                              <span>{valMenu?.title}</span>
                                            </>
                                          );
                                        }}
                                      />
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className={styles.btns}>
                        <Button
                          type="primary"
                          size="small"
                          onClick={save}
                        >
                          保存
                        </Button>
                        <Button
                          size="small"
                          style={{ marginLeft: "10px" }}
                          onClick={() => setIsEditing(true)}
                        >
                          编辑
                        </Button>
                        <span style={{ marginLeft: "10px" }}>请先点击【编辑】按钮，再进行操作</span>
                      </div>
                    </>
                  );
                }}
                onConfirm={() => { }}
                okText="Yes"
                cancelText="No"
                overlayClassName={styles.tooltipModal}
              >
                {isOpen ? (
                  <CaretUpOutlined
                    style={{ padding: "5px 0" }}
                    onClick={() => {
                      setIsOpen(false);
                      setIsEditing(false);
                      setMenusNow(menus);
                    }}
                  />
                ) : (
                  <CaretDownOutlined
                    style={{ padding: "5px 0" }}
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  />
                )}
              </Popconfirm>
            </div>
          )}
        </DndProvider>
      ) : null}
    </>
  );
}

export default DropTarget(
  ["DragAddMenu"],
  {
    drop(props: any, monitor: any, component: any) {
      const item = monitor.getItem();
      item.onDrop(item.val);
    },
  },
  (connect) => {
    return {
      connectDropTarget: connect.dropTarget(),
    };
  }
)(LayoutHeader);
