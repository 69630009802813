import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Icon from '../Icon'
import styles from './index.less'
import classNames from 'classnames'

import DeleteWarning from '@/components/DeleteWarning'

import {
  getUserInfo,
  logout,
} from '@/reducers/user'

function LayoutUser() {
  const userInfo = useSelector<any, any>(state => state?.user?.userInfo)
  const dispatch = useDispatch()

  const {
    gender,
    roleName,
    staffName,
    departDesc,
    authCodes,
  } = userInfo

  useEffect(() => {
    dispatch(getUserInfo())
  }, [])

  function handleLogout() {
    dispatch(logout())
  }

  return (
    <div className={styles.container}>
      <div className={styles.roleWrap}>
        <DeleteWarning
            btns={[{
              text: '确认退出',
              type: 'primary',
              danger: 'danger',
              onPress: handleLogout                 
            }, {
              text: '取消',
              close: true,
            }]}
            tipOne="是否确认退出系统？"
            delBtn={<Icon className={styles.logout} type="logout" />}
            placement="bottomLeft"
          /> 
        {
          roleName ? (
            <span title={roleName} className={styles.role}>{roleName}</span>
          ) : null
        }
      </div>
      <div className={styles.avatarWrap}>
        {
          gender ? (
            <img className={styles.avatar} alt="avatar" src="https://img.zb100.com/erpify/girl.png" />
          ) : <img className={styles.avatar} alt="avatar" src="https://img.zb100.com/erpify/boy.png" />
        }
      </div>
      {
        staffName ? (
          <p className={classNames(styles.name, styles.data)}>{staffName}</p>
        ) : null
      }
      {
        departDesc ? (
          <p className={classNames(styles.depart, styles.data)}>{departDesc}</p>
        ) : null
      }
    </div>
  )
}

export default LayoutUser