import fetch from './index'

// 所有角色信息
export function getAllRoles(): any {
  return fetch.get('/roles/values')
}

// 角色分页列表
export function getRoles(params): any {
  return fetch.get('/roles/list', { params })
}

export function delRole(id) {
  return fetch.delete(`/roles/${id}`)
}

export function addRole(params) {
  return fetch.post('/roles', params)
}

export function editRole(params) {
  return fetch.put('/roles', params)
}

// 店铺邮箱管理
export function getEmailList(params) {
  return fetch.post('/edata/shop/email/list', params)
}

// 店铺邮箱管理
export function addEmail(params) {
  return fetch.post('/edata/shop/email', params)
}

// 店铺邮箱管理
export function editEmail(params) {
  return fetch.put('/edata/shop/email', params)
}

// 店铺邮箱管理
export function deleteEmail(id) {
  return fetch.delete(`/edata/shop/email/${id}`, {})
}

// 店铺邮箱管理
export function getEmailDataList(params) {
  return fetch.post('/edata/shop/email/data/list', params)
}

// 工单独立站查客户信息接口
export function getShopifyCustomerData(params) {
  return fetch.post('/edata/item/shopify/order', params)
}

// 店铺邮箱管理
export function getEmailDataReport(params) {
  return fetch.post('/export/records/email/data/report', params)
}

// 邮件预警查询
export function getWarnEmailList(params): any {
  return fetch.post('/edata/warn/email/page', params)
}

// 处理预警邮件
export function handleWarnEmailList(params): any {
  return fetch.put('/edata/warn/email', params)
}