import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getAllRoles as getAllRolesAPI,
} from '../services/roles'

export const getAllRoles = createAsyncThunk(
  'roles/values',
  async () => {
    const data = await getAllRolesAPI()
    return data || []
  }
)

const rolesSlice = createSlice({
  name: 'roles',
  initialState: {
    allRoles: []
  },
  reducers: {
  },
  extraReducers: {
    [getAllRoles.fulfilled as any]: (state, action) => {
      state.allRoles = action.payload
    },
  }
})

const {
  reducer,
} = rolesSlice;

export default reducer