import fetch from './index'

// 盘点单查询
export function getSupperlist(params): any {
  return fetch.get('/eps/inventories/list', {
    params
  })
}

// 商品查询
export function getCommoditylist(params): any {
  return fetch.get('/eps/inventories/commodities/list', {
    params
  })
}


// 盘点单详情
export function getSupperdetail(params): any {
  return fetch.get("/eps/inventories/details", {
    params
  })
}

// 删除盘点
export function setDeleteInventory(params): any {
  return fetch.delete("/eps/inventories/detail", {
    params
  })
}

// 取消盘点
export function setCancelcounting(params): any {
  return fetch.delete("/eps/inventories/cancel", {
    params
  })
}

// 库存盘点条件导出
export function getwarehouseReturnorder(params): any {
  return fetch.post('/export/records/warehouse/inventory',params)
}

// 库存盘点商品导出
export function getinventoryReturnorder(params): any {
  return fetch.post('/export/records/warehouse/inventory/commodity', params)
}

//  库存盘点详情导出
export function getSupperexport(params): any {
  return fetch.post(`/export/records/warehouse/inventory/detail`, params)
}

