import fetch from './index'

// 编辑积分
export function putAfterAwardScore(afterAwardScore, userId, remark) {
  return fetch.put(`/award/score/back/update?afterAwardScore=${afterAwardScore}&userId=${userId}&remark=${remark}`)
}
// 初始积分设置
export function postAwardScore(params) {
  return fetch.post('/award/score/config', params)
}
// 编辑积分设置
export function putAwardScore(params) {
  return fetch.put('/award/score/config', params)
}
// 查询积分设置
export function getAwardScore() {
  return fetch.get('/award/score/config')
}
// 查询积分日志
export function getAwardScoreDetail(params) {
  return fetch.get('/award/score/user/detail', { params })
}