import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit'

import {
  getWarehouseRule as getWarehouseRuleAPI
} from '../services/warehouses';

export const getWarehouseRule = createAsyncThunk(
  'warehouse/rule',
  async () => {
    const data = await getWarehouseRuleAPI()
    return data || []
  }
)

const warehouseRuleSlice = createSlice({
  name: 'warehouseRule',
  initialState: {
    warehouseRule: []
  },
  reducers: {
  },
  extraReducers: {
    [getWarehouseRule.fulfilled as any]: (state, action) => {
      state.warehouseRule = action.payload
    },
    [getWarehouseRule.rejected as any]: (state, action) => {
      state.warehouseRule = []
    }
  }
})

const {
  reducer
} = warehouseRuleSlice

export default reducer