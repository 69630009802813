import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getSellersList as getSellersListAPI,
  getPageFeatures as getPageFeaturesAPI,
  getCategoriesList as getCategoriesListAPI,
  getCategoryFeatures as getCategoryFeaturesAPI
} from '../services/operselection';

export const getSellersList = createAsyncThunk<any, any, any>(
  'seller/list',
  async (params) => {
    const data = await getSellersListAPI(params)
    return data || []
  }
)

export const getPageFeatures = createAsyncThunk<any, any, any>(
  'page/list',
  async (params) => {
    const data = await getPageFeaturesAPI(params)
    return data || []
  }
)

export const getCategoriesList = createAsyncThunk<any, any, any>(
  'category/list',
  async (params) => {
    const data = await getCategoriesListAPI(params)
    return {
      [params.parentCategoryId]: data || []
    }
  }
)
export const getCategoryFeatures = createAsyncThunk<any, any, any>(
  'categorys/list',
  async (params) => {
    const data = await getCategoryFeaturesAPI(params)
    return {
      [params.commonCatIds]: data || []
    }
  }
)


const Sellers = createSlice({
  name: 'seller',
  initialState: {
    sellerlist: {},
    pagelist: {},
    categories: {},
    features: {}
  },
  reducers: {
  },
  extraReducers: {
    [getSellersList.fulfilled as any]: (state, action) => {
      state.sellerlist = action.payload
    },
    [getPageFeatures.fulfilled as any]: (state, action) => {
      state.pagelist = action.payload
    },
    [getCategoriesList.fulfilled as any]: (state, action) => {
      state.categories = {
        ...state.categories,
        ...action.payload,
      }
    },
    [getCategoryFeatures.fulfilled as any]: (state, action) => {
      state.features = {
        ...state.features,
        ...action.payload,
      }
    }
  }
})

const {
  reducer,
} = Sellers;

export default reducer