import fetch from './index'

export function getList(params): any {
  return fetch.get('/eps/commodities/analysis', { params })
}

export function getDetailList(params) {
  return fetch.get(`/eps/commodities/analysis/records`, { params })
}

export function exportBillData(params): any {
  return fetch.post('/export/records/eps/commodities/analysis', params)
}
// 查询当日进出帐报表
export function getTodayAnalysisList(params): any {
  return fetch.get('/eps/commodities/analysis/today', { params })
}
// 查询当日进出帐报表运行任务
export function getTodayAnalysisTaskSubmit(): any {
  return fetch.put('/eps/commodities/analysis/task/submit')
}
// 查询当日进出帐报表运行状态
export function getTodayAnalysisTask(): any {
  return fetch.get('/eps/commodities/analysis/task')
}
// 单日数据导出
export function exportBillDetailData(params): any {
  return fetch.post('/export/records/detail/out/int', params)
}
