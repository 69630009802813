import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getPrintList } from '../services/printTemplate';

const NAMESPACE = "printTemplate";

const initialState = {
  list: []
};

export const getPrintListReducer = createAsyncThunk(
  `${NAMESPACE}/getPrintList`,
  //发出一个请求，这里用的是axios
  async (params: any) => await getPrintList(params),
)


const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    updateLendList(state, action) {
      state.list = action.payload;
    }
  },
  // 处理异步请求结果的reducer
  extraReducers: (builder) => {
    builder.addCase(getPrintListReducer.fulfilled, (state, action) => {
      // data为接口返回的data字段
      const data = action.payload;
      state.list = data;
    })
  },
});


export const { actions, reducer } = slice;

export default reducer;