import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { connectAdvanced } from 'react-redux'

import {
  getCouponsList as getCouponsListAPI,
} from '../services/coupon';


export const getCouponsList = createAsyncThunk<any, any, any>(
  'coupon/list',
  async (params) => {
    return await getCouponsListAPI(params)
  }
)
  
const coupons = createSlice({
  name: 'coupon',
  initialState: {
    couponList: {},
  },
  reducers: {
  },
  
  extraReducers: {
    [getCouponsList.fulfilled as any]: (state, action) => {
      state.couponList = action.payload
    }
  }
})

const {
  reducer
} = coupons;


export default reducer