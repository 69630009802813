import fetch from './index'

// 订单列表
export function getsupperList(params): any {
  return fetch.post('/storage/outputs/list', params)
}

// 订单子列表
export function getsupperDetail(params): any {
  return fetch.post("/storage/outputs/detail/list", params)
}

// 订单 主 导出
export function getmasterReturnorder(params): any {
  return fetch.post('/export/records/storage/outputs', params)
}

// 订单 子 导出
export function getsupperExport(params): any {
  return fetch.post('/export/records/storage/outputs/detail', params)
}

// 主订单编辑
export function setStorageEdit(params): any {
  return fetch.put('/storage/outputs', params)
}
// 子订单修改售价
export function setPriceEdit(params): any {
  return fetch.put('/storage/outputs/detail', params)
}

export function createOrder(params) {
  return fetch.post('/storage/outputs', params)
}

export function getMainOrder(params) {
  return fetch.get('/storage/outputs/info', {
    params,
  })
}
// 查询批量补货列表
export function getReplenishmentList(outputNo): any {
  return fetch.get(`/storage/outputs/batch/replenishment?outputNo=${outputNo}`)
}
// 批量补货
export function postReplenishmentList(params): any {
  return fetch.post('/storage/outputs/batch/replenishment', params)
}
// 批量修改成本
export function importEditDetailCost(params): any {
  return fetch.post('/storage/outputs/import/cost', params)
}
// 单个修改成本
export function editOutputDetailCost(params): any {
  return fetch.put('/storage/outputs/update/cost', params)
}

