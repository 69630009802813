import fetch from './index'

// 款式维度仪表盘
export function getDesignDashboard(params): any {
  return fetch.get('/analytics/design/dashboard', { params })
}
// 采购退货单仪表盘
export function getSupplierReturnDashboard(params): any {
  return fetch.post('/analytics/supplier/return/dashboard', params)
}
// 销售退货单仪表盘
export function getOrderReturnDashboard(params): any {
  return fetch.post('/analytics/order/return/dashboard', params)
}
// 订单维度仪表盘
export function getOutputDashboard(params): any {
  return fetch.post('/analytics/output/dashboard', params)
}

// 款式商品数据
export function getStyleData(params): any {
  return fetch.post('/analytics/designs/comprehensive/dashboard', params)
}

// 款式商品数据
export function getTrendChart(params): any {
  return fetch.get('/analytics/designs/comprehensive/polyline', {params})
}

export function getExport(params): any {
  return fetch.post('/export/records/designs/comprehensive/dashboard', params)
}






